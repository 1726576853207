import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobsByUser } from "../../../../features/jobs/jobSlice";
import { useNavigate } from "react-router-dom";

function Candidates() {
  const { jobData, jobLoading } = useSelector((state) => state.jobs);
  const { user  } = useSelector((state) => state.auth);
  const { clientEditId } = useSelector((state) => state.client);

  const {singleAdminUser,userEditId} =  useSelector((state)=>state.admin)
 
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function fetchJobsDetails() {
    const res = await dispatch(
      fetchJobsByUser({
        jobInstId: user?.usrInstId,
        jobCntId: "",
        jobCreatedBy:userEditId, 
      })
    );
  }

  async function fetchJobsDetailsClient() {
    const res = await dispatch(
      fetchJobsByUser({
        jobInstId: user?.usrInstId,
        jobCntId: clientEditId,
        jobCreatedBy:userEditId
      })
    );
  }

  const userAdvJobs =  jobData?.length>0 && jobData?.filter((item)=>item?.jobAdvertStatus === "APPROVED")

 

  useEffect(() => {
    if (clientEditId) {
      fetchJobsDetailsClient();
    } else {
      fetchJobsDetails();
    }
  }, [clientEditId]);
  return (
    <div className="min-h-[895px] bg-white border border-gray-200  mt-[1%] p-[39px]">
      {/* <div className="bg-blueDark h-[79px] flex items-center">
        <div className="text-white text-[22px] font-bold font-dmSans leading-[33px] px-[40.6px]">
          Candidates
        </div>
      </div> */}

      {/* <div className="grid grid-cols-2"> 
        <div className="mt-[61px] px-[40.6px] flex flex-col text-black text-[22px] font-dmSans leading-[33px] gap-y-[21px]">
          <div className="font-bold">Placement</div>

          <div className="flex gap-x-5  items-center">
            <div className="h-[54px] w-[73px] bg-[#F8F8F8] flex items-center justify-center">
              <div className="font-bold">4</div>
            </div>
            <div className="font-medium">Candidates interviewed</div>
          </div>

          <div className="flex gap-x-5  items-center">
            <div className="h-[54px] w-[73px] bg-[#F8F8F8] flex items-center justify-center">
              <div className="font-bold">3</div>
            </div>
            <div className="font-medium">Candidates received offer</div>
          </div>

          <div className="flex gap-x-5  items-center">
            <div className="h-[54px] w-[73px] bg-[#F8F8F8] flex items-center justify-center">
              <div className="font-bold">3</div>
            </div>
            <div className="font-medium">Candidate hired</div>
          </div>
        </div>
      </div> */}

      <div className="bg-blueDark h-[79px] flex items-center">
        <div className="text-white text-[22px] font-bold font-dmSans leading-[33px] px-[40.6px]">
          Jobs
        </div>
      </div>

      <div className="mt-[61px] px-[40.6px] flex flex-col text-black text-[22px] font-dmSans leading-[33px] gap-y-[21px] mb-[150px]">
        <div className="font-bold">Job Activity</div>

        <div className="flex gap-x-5  items-center">
          <div className="h-[54px] w-[73px] bg-[#F8F8F8] flex items-center justify-center">
            <div className="font-bold">{jobData?.length}</div>
          </div>
          <div className="font-medium">Jobs created</div>
        </div>

        <div className="flex gap-x-5  items-center">
          <div className="h-[54px] w-[73px] bg-[#F8F8F8] flex items-center justify-center">
            <div className="font-bold">{userAdvJobs?.length ?? 0}</div>
          </div>
          <div className="font-medium">Job advertised</div>
        </div>
      </div>
    </div>
  );
}

export default Candidates;
