import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSingleDistress } from "../../../features/runaway/runawaySlice";
import UserDesciption from "./UserDescription";
import EmploymentTermsDescription from "./EmploymentTermsDescription";
import EmployerDescription from "./EmployerDescription";
import { Breadcrumb } from "antd";

function DistressView() {
  const {singleDistress} = useSelector((state)=>state.runaway)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  async function fetchSingleDistressData() {
    dispatch(
      fetchSingleDistress({
        dstId: id,
      })
    );
  }
  useEffect(() => {
    fetchSingleDistressData();
  }, []);

  console.log("singleDistress",singleDistress)
  return (
    <div>
        <div className="mt-[2.5rem] px-10 font-dmSans text-[18px]">
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer "
                  onClick={() => navigate(-1)}
                >
                  Distress
                </span>
              ),
            },
            {
              title: (
                <span className="text-blueDark font-dmSans">Distress View</span>
              ),
            },
          ]}
        />
      </div>
      <UserDesciption />
      <div className="flex  justify-start gap-x-5">
        <EmployerDescription />
        <EmploymentTermsDescription />
      </div>
    </div>
  );
}

export default DistressView;
