import React, { useEffect, useState } from "react";
import backArrow from "../../../assets/Back.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, theme } from "antd";
import EmployeeDetails from "./basic-details/EmployeeDetails";
import EmployeeDetailsView from "./basic-details/EmployeeDetailsView";
import { fetchEmployees, setEditBAsic, setEditContract, setEditJob, setEmployeeEditId, setViewBAsic, setViewContract, setViewJob } from "../../../features/myEmployees/myEmployeesSlice";
import JobDetailsView from "./job-details/JobDetailsView";
import JobDetails from "./job-details/JobDetails";
import { formatImgPath } from "../../../utils";
import ContractDetailsView from "./contract-details/ContractDetailsView";
import ContractDetails from "./contract-details/ContractDetails";
import { fetchQualifications } from "../../../features/jobFilters/jobFilterSlice";

function AddMyEmployees() {
  const { token } = theme.useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleBack = async () => {
    await navigate("/employment-returns");
  };

  const { employeesData, employeeLoading,viewBAsic,editBAsic,
    viewJob,editJob,viewContract,editContract,singleEmployee,employeeEditId
 } = useSelector(
    (state) => state.employee
  );


  
  const [receivedEditId, setReceivedEditId] = useState(
    Object?.keys(singleEmployee)?.length > 0  ? singleEmployee?.emplId : employeeEditId
  );
 
  const singleEmployeeInfo = employeesData?.find(
    (item) => item?.emplId === receivedEditId
  );

  
  useEffect(() => {
    setReceivedEditId(Object?.keys(singleEmployee)?.length > 0  ? singleEmployee?.emplId : employeeEditId);
  }, [singleEmployee , employeeEditId]);

 
 
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "1px solid #147CBC",
  };

  const handleEditBasic = () => { 
    dispatch(setEmployeeEditId(receivedEditId))
    dispatch(setEditBAsic(true)); 
    dispatch(setViewBAsic(false)); 
  };

  const genExtraBasic = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={(event) => {
        event.stopPropagation();
        handleEditBasic();
      }}
    >
      <path
        d="M11 4.00023H4C3.46957 4.00023 2.96086 4.21094 2.58579 4.58601C2.21071 4.96109 2 5.46979 2 6.00023V20.0002C2 20.5307 2.21071 21.0394 2.58579 21.4144C2.96086 21.7895 3.46957 22.0002 4 22.0002H18C18.5304 22.0002 19.0391 21.7895 19.4142 21.4144C19.7893 21.0394 20 20.5307 20 20.0002V13.0002M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
        stroke="#101828"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

  );

  const handleEditJob = () => {
    dispatch(setEmployeeEditId(receivedEditId))
    dispatch(setEditJob(true)); 
    dispatch(setViewJob(false)); 
  };

  const genExtraJob = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={(event) => {
        event.stopPropagation();
        handleEditJob();
      }}
    >
      <path
        d="M11 4.00023H4C3.46957 4.00023 2.96086 4.21094 2.58579 4.58601C2.21071 4.96109 2 5.46979 2 6.00023V20.0002C2 20.5307 2.21071 21.0394 2.58579 21.4144C2.96086 21.7895 3.46957 22.0002 4 22.0002H18C18.5304 22.0002 19.0391 21.7895 19.4142 21.4144C19.7893 21.0394 20 20.5307 20 20.0002V13.0002M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
        stroke="#101828"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

  );

  const handleEditContract = () => {
    dispatch(setEmployeeEditId(receivedEditId))
    dispatch(setEditContract(true)); 
    dispatch(setViewContract(false)); 
  };

  const genExtraContract = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={(event) => {
        event.stopPropagation();
        handleEditContract();
      }}
    >
      <path
        d="M11 4.00023H4C3.46957 4.00023 2.96086 4.21094 2.58579 4.58601C2.21071 4.96109 2 5.46979 2 6.00023V20.0002C2 20.5307 2.21071 21.0394 2.58579 21.4144C2.96086 21.7895 3.46957 22.0002 4 22.0002H18C18.5304 22.0002 19.0391 21.7895 19.4142 21.4144C19.7893 21.0394 20 20.5307 20 20.0002V13.0002M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
        stroke="#101828"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

  );

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <div className="flex flex-row w-[100%] gap-2">
          {viewBAsic ? (
            <div className="h-[25px] w-[25px] rounded-full bg-blueDark flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_7908_42090)">
                  <path
                    d="M5.23238 9.4167L2.79052 6.97484L1.97656 7.78879L5.23238 11.0446L12.2091 4.06786L11.3952 3.25391L5.23238 9.4167Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7908_42090">
                    <rect width="13.9535" height="13.9535" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <circle
                cx="12.5"
                cy="12.5"
                r="12"
                fill="white"
                stroke="#147CBC"
              />
              <circle cx="12.7904" cy="12.7943" r="2.32558" fill="#147CBC" />
            </svg>
          )}

          <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
            Basic Information
          </div>
        </div>
      ),
      children: viewBAsic ? <EmployeeDetailsView /> : <EmployeeDetails />,
      extra:viewBAsic ? genExtraBasic() : "",
      showArrow:viewBAsic ? false : true,
      style: panelStyle,
    },

    {
        key: "2",
        label: (
          <div className="flex flex-row w-[100%] gap-2">
            {viewJob ? (
              <div className="h-[25px] w-[25px] rounded-full bg-blueDark flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clip-path="url(#clip0_7908_42090)">
                    <path
                      d="M5.23238 9.4167L2.79052 6.97484L1.97656 7.78879L5.23238 11.0446L12.2091 4.06786L11.3952 3.25391L5.23238 9.4167Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7908_42090">
                      <rect width="13.9535" height="13.9535" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <circle
                  cx="12.5"
                  cy="12.5"
                  r="12"
                  fill="white"
                  stroke="#147CBC"
                />
                <circle cx="12.7904" cy="12.7943" r="2.32558" fill="#147CBC" />
              </svg>
            )}
  
            <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
            Job details
            </div>
          </div>
        ),
        children: viewJob ? <JobDetailsView /> : <JobDetails />,
        extra:viewJob ? genExtraJob() : "",
        showArrow:viewJob ? false : true,
        style: panelStyle,
      },

      {
        key: "3",
        label: (
          <div className="flex flex-row w-[100%] gap-2">
            {viewContract ? (
              <div className="h-[25px] w-[25px] rounded-full bg-blueDark flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clip-path="url(#clip0_7908_42090)">
                    <path
                      d="M5.23238 9.4167L2.79052 6.97484L1.97656 7.78879L5.23238 11.0446L12.2091 4.06786L11.3952 3.25391L5.23238 9.4167Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7908_42090">
                      <rect width="13.9535" height="13.9535" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <circle
                  cx="12.5"
                  cy="12.5"
                  r="12"
                  fill="white"
                  stroke="#147CBC"
                />
                <circle cx="12.7904" cy="12.7943" r="2.32558" fill="#147CBC" />
              </svg>
            )}
  
            <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
            Contract details
            </div>
          </div>
        ),
        children: viewContract ? <ContractDetailsView /> : <ContractDetails />,
        extra:viewContract ? genExtraContract() : "",
        showArrow:viewContract ? false : true,
        style: panelStyle,
      },
  ];
   
  console.log("singleEmployeeInfo",singleEmployeeInfo)
 
  return (
    <>
      <div className="bg-white mr-3 mb-[10px] flex items-center justify-between h-[115px] px-[39px]">
        <div className="items-center flex text-center gap-x-5">
          <img className="h-[61px] w-[61px] rounded-full" src={singleEmployeeInfo ? formatImgPath(singleEmployeeInfo?.emplIcon) :'https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png' } alt="employee-profile"/> 
          <span className="text-black1 text-2xl font-bold font-dmSans leading-[54px]">
            {singleEmployeeInfo?.emplFullNames}
          </span>
        </div>

        <div className="flex-end items-center flex gap-[5rem]">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={handleBack}
          >
            <div
              style={{
                height: "35px",
                width: "35px",
                background: "#EDF8FF",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={backArrow}
                alt="back-arrow"
                style={{ height: "40px", width: "40px" }}
              />
            </div>

            <span className="text-black2 text-[22px] font-bold font-dmSans leading-relaxed">
              Go back
            </span>
          </div>
        </div>
      </div>

      <div className="bg-white mr-3 mb-[10px] flex items-center justify-between h-auto px-[39px]">
        <div className="flex flex-col w-[100%]">
          <div className="text-black text-[22px] font-bold font-dmSans leading-[33px] py-[51px]">
            Fill in the following details
          </div>
          <Collapse
            bordered={false}
            expandIconPosition="end"
            defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
            style={{
              background: '#fff',
            }}
            items={getItems(panelStyle)}
          />
        </div>
      </div>
    </>
  );
}

export default AddMyEmployees;
