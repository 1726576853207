import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import { Breadcrumb, Form, Input, InputNumber, Spin } from "antd";
import { saveFile } from "../../features/application/applicationSlice";
import toast from "react-hot-toast";
import uplodSvg from "../../assets/image 194.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatImgPath } from "../../utils";
import { createInstitution } from "../../features/auth/authSlice";
import { fetchAgencyData, saveAgency } from "../../features/agency/agencySlice";
import InsideHeader from "../../components/InsideHeader";

function AdminLogo() {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loadingAttach, setLoadingAttach] = useState(false);
  const { user, authLoading } = useSelector((state) => state.auth);
  const { agencyObj } = useSelector((state) => state.agency);

  const [profile, setProfile] = useState(
    agencyObj?.instIcon ? agencyObj?.instIcon : null
  );

  const handleImageSelect = async (event) => {
    setLoadingAttach(true);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    const res = await dispatch(saveFile(formData));

    if (res?.payload?.success) {
      setLoadingAttach(false);
      setProfile(res?.payload?.jsonData ?? "");
    } else {
      setLoadingAttach(false);
      toast.error("Please try uploading your image again");
      return;
    }

    const createAdminUsersRes = await dispatch(
      saveAgency({
        instId: user?.usrInstId,
        instIcon: res?.payload?.jsonData ?? "",
      })
    );

    if (createAdminUsersRes?.payload?.success) {
      toast.success("Profile updated successfully");
      fetchAgencyDetails();
    } else {
      toast.error("Error updating profile, please try again");
    }
  };

  async function fetchAgencyDetails() {
    dispatch(fetchAgencyData(user?.usrInstId));
  }

  useEffect(() => {
    fetchAgencyDetails();
  }, []);

  const onFinish = async (data) => {
    data.instId = agencyObj?.instId;

    const res = await dispatch(
      createInstitution({
        ...data,
      })
    );

    if (res?.payload?.success) {
      await toast.success("Details updated successfully");
      await fetchAgencyDetails();
      await navigate("/dashboard");
      await form.resetFields();
    } else {
      toast.error("Errror occured while saving, please try again");
    }
  };

  return (
    <>
      {agencyObj?.instStatus ===
      "PARTIAl_APPROVE_ACCOUNT_VERIFICATION" ? null : (
        <InsideHeader
          title="Administrator"
          subtitle="Manage your account"
          back={false}
        />
      )}

      <div className="bg-white mr-3 mb-[10px] min-h-full h-auto  px-10">
        <div className="mt-[2.5rem] font-dmSans text-[18px]">
          <Breadcrumb
            items={[
              {
                title: (
                  <span
                    className="font-dmSans cursor-pointer "
                    onClick={() => navigate("/admin-landing")}
                  >
                    Administrator
                  </span>
                ),
              },
              {
                title: (
                  <span className="text-blueDark font-dmSans">Account</span>
                ),
              },
            ]}
          />
        </div>

        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
		  className="mt-[1.5rem]"
          style={{
            maxWidth: "100%", 
          }}
          form={form}
          initialValues={{
            ...agencyObj,
          }}
        >
          <div className="grid grid-cols-1">
            <Form.Item
              name="instName"
              label="Agency name"
              rules={[
                {
                  required: true,
                  message: "Please add agency name",
                },
              ]}
            >
              <Input
                readOnly={
                  agencyObj?.instStatus !==
                  "PARTIAl_APPROVE_ACCOUNT_VERIFICATION"
                }
                className="input"
              />
            </Form.Item>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
            <Form.Item
              name="instRegCertNo"
              label="Business Reg No"
              rules={[
                {
                  required: true,
                  message: "Please add business reg no",
                },
              ]}
            >
              <Input readOnly className="input" />
            </Form.Item>
            <Form.Item
              name="instKraPin"
              label="Kra Pin"
              rules={[
                {
                  required: true,
                  message: "Please add kra pin",
                },
                {
                  pattern: /^[A-Z0-9]{11}$/,
                  message:
                    "Invalid Kra Pin format. A valid Kra Pin should be 11 alphanumeric characters.",
                },
              ]}
            >
              <Input readOnly className="input" />
            </Form.Item>

            <Form.Item
              name="instNationalId"
              label="National ID number"
              rules={[
                {
                  required: true,
                  message: "Please add ID number",
                },
              ]}
            >
              <Input readOnly className="input" />
            </Form.Item>

            <Form.Item
              name="instPostalAddress"
              label="Postal Address"
              rules={[
                {
                  required: true,
                  message: "Please add postal address",
                },
              ]}
            >
              <Input className="input" />
            </Form.Item>

            <Form.Item
              name="instPostalCode"
              label="Postal Code"
              rules={[
                {
                  required: true,
                  message: "Please add postal code",
                },
              ]}
            >
              <Input className="input" />
            </Form.Item>

            <Form.Item
              name="instPhysicalAddress"
              label="Office Location"
              rules={[
                {
                  required: true,
                  message: "Please add office location",
                },
              ]}
            >
              <Input className="input" />
            </Form.Item>

            <Form.Item
              name="instCellPhone"
              label="Office Office No"
              rules={[
                {
                  required: true,
                  message: "Please add office office no",
                },
              ]}
            >
              <Input className="input" />
            </Form.Item>
            <Form.Item
              name="instWorkTel"
              label="Office Phone No"
              rules={[
                {
                  required: true,
                  message: "Please add office phone no",
                },
              ]}
            >
              <InputNumber
                readOnly
                maxLength={10}
                minLength={9}
                className="input"
              />
            </Form.Item>

            <Form.Item
              name="instEmail"
              label="Official Email Address"
              rules={[
                {
                  required: true,
                  message: "Please add official email address",
                },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input type="email" className="input" />
            </Form.Item>
            <Form.Item
              name="instWebsite"
              label="Official Website"
              rules={[
                {
                  required: true,
                  message: "Please add official website",
                },
                {
                  type: "url",
                  message: "Please add a valid URL",
                },
              ]}
            >
              <Input className="input" />
            </Form.Item>
          </div>

          <div className="grid grid-cols-1">
            <Form.Item
              name="instNearNeaOffice"
              label="Nearest NEA Office"
              rules={[
                {
                  required: true,
                  message: "Please select nearest NEA office",
                },
              ]}
            >
              <Input readOnly className="input" />
            </Form.Item>
          </div>

          <div className="flex justify-start mt-5">
            <button
              className="w-[250px] save-btn"
              type="submit"
              disabled={authLoading ? true : false}
            >
              {authLoading ? <Spin /> : "Update Account"}
            </button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default AdminLogo;
