import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  demandJobData: [],
  demandJobLoading: false,
  approvedDemandJobData: [],
 
};

export const saveDemandLetterJob = createAsyncThunk(
  "demandJob/saveDemandLetterJob",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveDemandLetterJob.action`, data);
    return res.data?.jsonData;
  }
);

export const fetchDemandLetterJobs = createAsyncThunk(
  "demandJob/fetchDemandLetterJobs",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchDemandLetterJobs.action?dljDmlId=${data?.dljDmlId}`
    );
    return res.data?.data?.result;
  }
);

export const deleteJob = createAsyncThunk(
  "demandJob/deleteJob",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/deleteJob.action?jobId=${data?.jobId}`
    );
    return res.data;
  }
);

export const fetchApprovedDemandLetterJobs = createAsyncThunk(
  "approvedDemandJob/fetchDemandLetterJobs",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchDemandLetterJobs.action?dmlStatus=${data?.dmlStatus}&dljInstId=${data?.dljInstId}`
    );
    return res.data?.data?.result;
  }
);


export const demandJobSlice = createSlice({
  name: "demandJob",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    }, 
  },
  extraReducers: (builder) => {
    builder

      .addCase(saveDemandLetterJob.pending, (state) => {
        state.demandJobLoading = true;
      })
      .addCase(saveDemandLetterJob.fulfilled, (state, action) => {
        state.demandJobLoading = false;
      })
      .addCase(saveDemandLetterJob.rejected, (state) => {
        state.demandJobLoading = false;
      })

      .addCase(fetchDemandLetterJobs.pending, (state) => {
        state.demandJobLoading = true;
      })
      .addCase(fetchDemandLetterJobs.fulfilled, (state, action) => {
        state.demandJobLoading = false;
        state.demandJobData = action.payload;
      })
      .addCase(fetchDemandLetterJobs.rejected, (state) => {
        state.demandJobLoading = false;
      })

      .addCase(deleteJob.pending, (state) => {
        state.demandJobLoading = true;
      })
      .addCase(deleteJob.fulfilled, (state, action) => {
        state.demandJobLoading = false; 
      })
      .addCase(deleteJob.rejected, (state) => {
        state.demandJobLoading = false;
      })

      .addCase(fetchApprovedDemandLetterJobs.pending, (state) => {
        state.demandJobLoading = true;
      })
      .addCase(fetchApprovedDemandLetterJobs.fulfilled, (state, action) => {
        state.demandJobLoading = false;
        state.approvedDemandJobData = action.payload;
      })
      .addCase(fetchApprovedDemandLetterJobs.rejected, (state) => {
        state.demandJobLoading = false;
        state.approvedDemandJobData=[]
      })

  },
});

export default demandJobSlice.reducer;
export const {
} = demandJobSlice.actions;
