import ExperienceForm from "../profile_tab_forms/ExperienceForm";

 
export default function ExperienceTab({ userId }) {
	return (
		<>
			<div className='mt-[3.5rem] mb-[5.31rem]'>
				<ExperienceForm userId={userId} />
			</div>
		</>
	);
}
