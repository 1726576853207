import { Button, Card, Modal, Table, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import MaterialIcon from "material-icons-react";
import { useNavigate } from "react-router-dom"; 
import { useDispatch, useSelector } from "react-redux"; 
import toast from "react-hot-toast"; 
import { cashConverter, numberWithCommas } from "../../../utils";
import { fetchJobsByDemandLetter } from "../../../features/jobs/jobSlice";
 

function DemandJobView({id}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { jobDemandLetterData, jobLoading,jobsCount } = useSelector((state) => state.jobs);
 
  const columns = [
    {
      title: "Job Category",
      dataIndex: "jobCategory", 
    },
    {
      title: "Job Title",
      dataIndex: "jobName", 
    },
    {
      title: "Working Hours/day",
      dataIndex: "jobWorkingHours", 
      editable: true,
    },

    {
      title: "No. of Vacancies", 
      render:(item)=>{
        return(
          <div>{numberWithCommas(item?.jobVacancyCount)}</div>
        )
      }, 
    },

    {
      title: "Salary Expectation", 
      render:(item)=>{
        return(
          <div>{cashConverter(item?.jobGrossPay)}</div>
        )
      }, 
    },
    {
      title: "Sex Required",
      dataIndex: "jobGender", 
    },
    {
      title: "Qualification",
      dataIndex: "jobQualification", 
    },
    
  ];

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchJobsByDemandLetterData(page,size){
    dispatch(
      fetchJobsByDemandLetter({
        jobDmlId: id,
        jobStatus:'',
        limit: size ?? pageSize,
        start: page ?? pageIndex,
      })
    )
  }
 
  useEffect(() => {
    fetchJobsByDemandLetterData();
  }, []);

  return (
    <> 
      <Table
        rowSelection={true}
        size={"middle"}
        bordered={true}
        pagination={{
          position: ["bottomCenter"],
          current: pageIndex + 1,
          total: jobsCount,
          pageSize: pageSize,
          onChange: (page, size) => {
            setPageIndex(page - 1);
            setPageSize(size);  
              fetchJobsByDemandLetterData(page - 1, size); 
          },
          showSizeChanger: false,
          hideOnSinglePage: true,
        }}
        rowKey={(record) => record?.jobId}
        columns={tableColumns}
        dataSource={jobDemandLetterData}
        scroll={{
          x: 1300,
        }}
        loading={jobLoading}
      />
    </>
  );
}

export default DemandJobView;
