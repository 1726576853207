import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import group from '../../assets/Group.png';
import {
	fetchAgencyDetails,
	fetchDirectorAndManager,
	fetchManager,
} from '../../features/application/applicationSlice';
import { fetchAgencyData } from '../../features/agency/agencySlice';

export default function IncompleteDash() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { directors, manager, agency } = useSelector(
		(state) => state.application
	);

	const [directorsList, setdirectorsList] = useState(directors);
	const [managerObj, setmanagerObj] = useState(manager || {});
	const [agencyObj, setagencyObj] = useState(agency || {});

	const fetchDirectorInfo = async () => {
		await dispatch(fetchDirectorAndManager(user?.usrInstId));
	};

	async function handleManagerData() {
		await dispatch(fetchManager(user?.usrInstId));
	}


	useEffect(() => {
		if (user?.usrId) {
			fetchDirectorInfo();
			handleManagerData();
		}
	}, [user]);

	useEffect(() => {}, [directorsList, managerObj, agencyObj]);

	useEffect(() => {
		setdirectorsList(directors);
	}, [directors]);

	useEffect(() => {
		setmanagerObj(manager);
	}, [manager]);

	useEffect(() => {
		setagencyObj(agency);
	}, [agency]);

	return (
		<>
			<div
				style={{}}
				className='w-[100%] h-[100%] bg-white flex justify-center flex-col items-center gap-2'>
				<p className="[font-family:'DM_Sans-Bold',Helvetica] font-bold text-transparent text-[3.5rem] tracking-[0] leading-[74px] whitespace-nowrap">
					<span className='text-[#147cbc]'>Welcome</span>
					<span className='text-[#141414]'> {user?.usrFullNames}.</span>
				</p>
				<div>
					<p className="[font-family:'DM_Sans-Regular',Helvetica] font-normal text-[#333333] text-[1.5rem] tracking-[0] mb-5 whitespace-nowrap">
						Start your application to become a licensed NEA Employment Agent
					</p>
				</div>
				<div className='w-[245px] h-[234px]'>
					<img className='w-[247px] h-[234px] ' alt='Group' src={group} />
				</div>
				<Button
					onClick={() => navigate('/application')}
					className='bg-blueDark rounded-xl mt-10 h-12 text-white text-[1.5rem]'>
					{directorsList?.length || managerObj?.secId || agencyObj?.agcId
						? 'Continue your'
						: 'Start'}{' '}
					application
				</Button>
			</div>
		</>
	);
}
