import React from "react";
import DemandStepper from "./DemandStepper";

function DemandLetter() {
  return (
    <>
      <div className="mr-3 h-36  bg-white">
        <div className="flex h-36   items-center ml-[54px]">
          <span className="text-blackest text-2xl font-bold font-dmSans leading-[50.40px]">
            Demand Letters
          </span>
        </div>
      </div>
      
        <DemandStepper />
    </>
  );
}

export default DemandLetter;
