import React, { useCallback, useEffect, useState } from 'react';
import {
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import {
	fetchCurrentUser,
	fetchLoggedInUser,
	fetchUsers,
	handleLoginSession,
	handleLoginUser,
	handleToken,
	logout,
	updateUser,
} from './features/auth/authSlice';
import Home from './pages/home/Home';
import Details from './pages/signup/Details';
import Otp from './pages/signup/Otp';
import PasswordFields from './pages/signup/PasswordFields';
import Dashboard from './pages/dashboard/Dashboard';
import Application from './pages/application/Application';
import Login from './components/signin/Login';
import ResetPassword from './components/signin/ResetPassword';
import VerifyOtp from './components/signin/VerifyOtp';
import Newpassword from './components/signin/Newpassword';
import DemandLetter from './pages/demand-letter/DemandLetter';
import DemandLettersList from './pages/demand-letter/DemandLettersList';
import DemandLetterView from './pages/demand-letter/view/DemandLetterView';
import DrawerLetter from './pages/demand-letter/view/DrawerLetter';
import ClientAdd from './pages/clients/ClientAdd';
import ClientList from './pages/clients/ClientList';
import ClientTabs from './pages/clients/client-tabs/ClientTabs'; 
import JobList from './pages/jobs/job-adverts/job-list/JobList';
import TextEditor from './pages/jobs/job-adverts/job-description/TextEditor';
import ApplicantList from './pages/applicants/ApplicantList';
import ApplicantTabs from './pages/applicants/applicant-tabs/ApplicantTabs';
import JobTable from './pages/jobs/job-adverts/job-list/JobTable';
import ActivityList from './pages/activities/ActivityList';
import UsersTable from './pages/admin/UsersTable';
import UsersList from './pages/admin/UsersList';
import AdminLanding from './pages/admin/AdminLanding';
import AdminLogo from './pages/admin/AdminLogo';
import AddDemandLetter from './pages/demand-letter/AddDemandLetter';
import DemandLetterJobView from './pages/demand-letter/view/DemandLetterJobView';
import EditDemandLetter from './pages/demand-letter/EditDemandLetter';
import UsersView from './pages/admin/user-view/UsersView';
import MainLicensePage from './pages/license/MainLicensePage';
import EmploymentReturnsView from './pages/employment-returns/EmploymentReturnsView';
// import AddMyEmployees from './pages/employment-returns/my-employees/AddMyEmployees';
import { fetchAgencyData } from './features/agency/agencySlice';
import Profile from './pages/profile/Profile';
import DrawerModal from './pages/jobs/job-adverts/view/DrawerModal';
import PlacementView from './pages/employment-returns/placementView/PlacementView';
import Inbox from './pages/inbox/Inbox';
import ComposeMessage from './pages/inbox/inbox_files/ComposeMessage';
import { jwtDecode } from 'jwt-decode';
import { customToast } from './notifications';
import Loader from './Loader';
import AttestationList from './pages/attestation/AttestationList';
import ViewList from './pages/attestation/view/ViewList';
import ActivityInvites from './pages/activities/ActivityInvites';
import ActivityAdd from './pages/activities/ActivityAdd';
import PanelList from './pages/panel/PanelList';
import axios from 'axios';
import AuthVerify from './AuthVerify';
import axiosInstance from './instance';
import AddEmployee from './pages/employment-returns/my-employees/AddEmployee';
import EmployeesUploadList from './pages/employment-returns/my-employees/EmployeesUpload';
import EditEmployee from './pages/employment-returns/my-employees/EditEmployee';
import AddMyEmployees from './pages/employment-returns/my-employees_/AddMyEmployees';
import ClientUpload from './pages/clients/ClientUpload';
import { fetchNotifications } from './features/global/globalSlice';
import ActivityStep2 from './pages/activities/ActivityStep2';
import ActivityStep3 from './pages/activities/ActivityStep3';
import ActivityView from './pages/activities/ActivityView';
import JapTable from './pages/activities/JapTable';
import AccountClosedModal from './AccountClosedModal';
import Branches from './pages/admin/branches/Branches';
import EditBranch from './pages/admin/branches/branch_files/EditBranchMoal';
import { fetchMenuSidebar } from './features/admin/adminSlice';
import RunawayList from './pages/runaway/RunawayList';
import DistressTable from './pages/distress/DistressTable';
import DistressView from './pages/distress/view/DistressView';
import RunawayView from './pages/runaway/view/RunawayView';

let disableMenuStatus = [
	'SEND_TO_BACKGROUND_CHECK',
	'AWAITING_BACKGROUND_CHECK_RESULTS',
	'SEND_TO_ACCOUNT_INSPECTION',
	'SEND_TO_ACCOUNT_VETTING',
	'APPROVE_ACCOUNT_APPLICATION',
	'ACCOUNT_VERIFICATION',
	'APPROVE_ACCOUNT_CREATION',
	'UPDATED_ACCOUNT_CREATION',
	'REVOKE',
	'DECLINE_ACCOUNT_APPLICATION',
	'PARTIAL_APPROVE_ACCOUNT_APPLICATION',
	'DEFERMENT',
	'PAYMENT_PENDING_CONFIRMATION',
	'REJECT_RECEIPT',
	'ACCOUNT_VERIFICATION_ASSIGNED'
];

let licenseView = ['EXPIRED', 'RENEWING_LICENSE'];

function App() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { pathname } = useLocation();

	const { isLoggedIn, user, token } = useSelector((state) => state.auth);

	const [isUserLoggedIn, setisUserLoggedIn] = useState(isLoggedIn);
	
	const { agencyObj } = useSelector((state) => state.agency);

	const [load, setload] = useState(false);
	const [usrToken, setusrToken] = useState(searchParams.get('usrId') ?? token);

	axiosInstance.defaults.headers.common['Authorization'] = usrToken;

	let location = window.location.href;
	let excludedPaths = window.location.href.split('#').includes('/application');
	let excludedLicensePaths = window.location.href
		.split('#')
		.includes('/license');

	message.config({
		duration: 10,
		maxCount: 3,
	});

	async function handleLogout() {
		await dispatch(logout());
		await localStorage.clear();
		window.location.href = await 'https://kazi254.ke/#/';
	}
 
	async function handleInit() {
		if (usrToken?.length) {
			setload(true);
			const decoded = jwtDecode(usrToken);
			let decodeObj = JSON.parse(decoded.payload);

			await dispatch(handleToken(usrToken));
			await dispatch(fetchLoggedInUser());
			await dispatch(fetchAgencyData(decodeObj?.instId));
			await dispatch(handleLoginSession(true)); 
			await setload(false);
		} else {
			return;
		}
	}

	async function handleFetchInstitutionObj() {
		if (user?.usrInstId) {
			await dispatch(fetchAgencyData(user?.usrInstId));
		}
	}

	async function handleFetchNotifications() {
		if (user?.usrInstId && user?.usrId) {
			await dispatch(fetchNotifications(user));
		}
	}

	useEffect(() => {
		handleInit();
	}, []);

	useEffect(() => {
		handleFetchNotifications();
		handleFetchInstitutionObj()
	}, [pathname, user]);

	useEffect(() => {
		if (
			user?.usrNeaVerified !== true &&
			agencyObj?.instStatus !== 'PARTIAl_APPROVE_ACCOUNT_CREATION'
		) {
			// navigate('/dashboard');
		}

		if (excludedPaths || excludedLicensePaths) {
		} else {
			if (
				disableMenuStatus?.filter((item) => item === agencyObj?.instStatus)
					.length > 0
			) {
				navigate('/dashboard');
			}
		}
		if (
			licenseView?.filter((item) => item === agencyObj?.instStatus).length > 0
		) {
			navigate('/license');
		}
	}, [isUserLoggedIn, location]);


	useEffect(() => {
		setusrToken(token)
	}, [agencyObj, user, token]);

	useEffect(() => {
		setisUserLoggedIn(isLoggedIn);
	}, [isLoggedIn]);

	if (load) {
		return <Loader />;
	}

	if (isUserLoggedIn && agencyObj?.instStatus === 'CLOSE') {
		return (
			<DashboardLayout isSideBarEnabled={isLoggedIn}>
				<AccountClosedModal />
			</DashboardLayout>
		);
	}

	return (
		<div className='wrapper'>
			<DashboardLayout isSideBarEnabled={isLoggedIn}>
				<Routes>
					{/* <Route path='*' element={<Navigate to='/dashboard' replace />} /> */}
					<Route exact path='/dashboard' element={<Dashboard />} />
					<Route exact path='/application' element={<Application />} />
					<Route exact path='/demand-letter' element={<DemandLetter />} />
					<Route exact path='/letter-list' element={<DemandLettersList />} />
					<Route exact path='/letter-view/:id' element={<DemandLetterView />} />
						<Route exact path='/drawer' element={<DrawerLetter />} />
					<Route exact path='/client-add' element={<ClientAdd />} />
					<Route exact path='/client-list' element={<ClientList />} />
					<Route exact path='/client-upload' element={<ClientUpload />} />
					<Route exact path='/client-tabs' element={<ClientTabs />} /> 
					<Route exact path='/job-list' element={<JobList />} />
					<Route exact path='/job-view' element={<DrawerModal />} />
					<Route exact path='/applicants-list' element={<ApplicantList />} />
					<Route exact path='/text-editor' element={<TextEditor />} />
					<Route exact path='/applicant-tabs' element={<ApplicantTabs />} />
					<Route exact path='/job-table' element={<JobTable />} />
					<Route exact path='/activity-list' element={<ActivityList />} />
					<Route exact path='/activity-add' element={<ActivityAdd />} />

					<Route exact path='/activity-step2' element={<ActivityStep2 />} />
					<Route exact path='/activity-step3' element={<ActivityStep3 />} />
					<Route exact path='/jap-table' element={<JapTable />} />
					<Route exact path='/activity-view' element={<ActivityView />} />

					<Route exact path='/users-list' element={<UsersList />} />
					<Route exact path='/admin-landing' element={<AdminLanding />} />
					<Route exact path='/admin-profile' element={<AdminLogo />} />
					<Route exact path='/add-demand' element={<AddDemandLetter />} />
					<Route
						exact
						path='/demandletter-job-view/:id'
						element={<DemandLetterJobView />}
					/>
					<Route
						exact
						path='/demandletter-job-edit/:id'
						element={<EditDemandLetter />}
					/>
					<Route exact path='/users-view' element={<UsersView />} />
					<Route exact path='/license' element={<MainLicensePage />} />
					<Route
						exact
						path='/employment-returns'
						element={<EmploymentReturnsView />}
					/>
					<Route exact path='/employees/add' element={<AddEmployee />} />
					<Route
						exact
						path='/employees/edit/:name'
						element={<EditEmployee />}
					/>
					<Route exact path='/add-my-employees' element={<AddMyEmployees />} />
					<Route
						exact
						path='/employees/add/upload'
						element={<EmployeesUploadList />}
					/>
					<Route exact path='/profile' element={<Profile />} />
					<Route exact path='/placement-view' element={<PlacementView />} />
					<Route
						exact
						path='/notifications/compose'
						element={<ComposeMessage />}
					/>
					<Route exact path='/notifications/inbox' element={<Inbox />} />
					<Route exact path='/attestation-list' element={<AttestationList />} />
					<Route
						exact
						path='/attestation-view-list/:id'
						element={<ViewList />}
					/>
					<Route exact path='/activity-invites' element={<ActivityInvites />} />
					<Route exact path='/panel-list' element={<PanelList />} />

					<Route exact path='/branches' element={<Branches />} />
					<Route exact path='/branches/edit/:name' element={<EditBranch />} />
					<Route exact path='/runaway-list' element={<RunawayList />} />
					<Route exact path='/distress' element={<DistressTable />} />
					<Route exact path='/distress-view/:id' element={<DistressView />} />
					<Route exact path='/runaway-view/:id' element={<RunawayView />} />
					
				</Routes>
			</DashboardLayout>
			<AuthVerify logOut={handleLogout} usrToken={usrToken} />
		</div>
	);
}

export default App;
