import { useEffect, useState } from 'react';
import FileUploader from '../../../../components/FileUploader';
import { formatImgPath } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { handleLicenseStep } from '../../../../features/license/licenseSlice';
import { fetchHacInvoices } from '../../../../features/payment/paySlice';
import { Spin } from 'antd';
import Invoice from './Invoice';

const url = process.env.REACT_APP_API_BASE_URL;

export default function StepTwoInvoice() {
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { agencyObj } = useSelector((state) => state.agency);
	const { payLoading, newInvoice } = useSelector((state) => state.pay);

	const [loading, setloading] = useState(true);

	async function handleGetInvoice() {
		await dispatch(fetchHacInvoices(user?.usrInstId));
	}

	document.addEventListener('DOMContentLoaded', () => {
		console.log('LOADED');
		const iframe = document.getElementById('invoFrame');
		iframe.addEventListener('load', function () {
			console.log('Finish');
		});
	});

	useEffect(() => {}, [newInvoice, loading]);

	useEffect(() => {
		handleGetInvoice();
	}, []);

	return (
		<>
			<div className='flex flex-col justify-start h-full'>
				<span className='invoice-text'>Generated Invoice</span>

				{loading ? (
					<>
						<button
							className='bg-blueDark text-white w-[300px] p-3 flex justify-center mt-10'
							disabled
							type='button'>
							<Spin />
							<span className='ml-2'>Generating invoice... Please wait</span>
						</button>
					</>
				) : null}

				<iframe
					id='invoFrame'
					onLoad={() => setloading(false)}
					title='Generated invoice'
					width={'100%'}
					height={'700px'}
					src={`${url}/nea/directReport.action?INVO_ID=${newInvoice?.invoId}&INST_ID=${agencyObj?.instId}&P1_FORMAT=PDF&reportName=Invoice&usrId=${user?.usrId}`}></iframe>
			</div>
		</>
	);
}
