import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Form, Input, Spin } from "antd";
import clientTemplate from "../../assets/temp/Clients.xlsx";
import download from "../../assets/file_download-24px.svg";
import upload from "../../assets/file_upload-24px.svg";
import ClientExcelModal from "./ClientExcelModal";
import InsideHeader from "../../components/InsideHeader";
import toast from "react-hot-toast";
import { fetchClients } from "../../features/client/clientSlice";

const url = process.env.REACT_APP_API_BASE_URL;

export default function ClientUpload() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const { user } = useSelector((state) => state.auth);
  const { agencyObj } = useSelector((state) => state.agency);

  const [fileUrl, setfileUrl] = useState();
  const [loading, setloading] = useState(false);

  const [downloaded, setDownloaded] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    if (linkRef.current) {
      linkRef.current.click();
      setDownloaded(true);
    }
  };

  const [openExcelUpload, setopenExcelUpload] = useState(false);
  function handleCancelExcelUpload() {
    setopenExcelUpload(false);
  }

  useEffect(() => {}, [fileUrl]);

  return (
    <>
      <InsideHeader
        title={`${
          agencyObj?.licenseName == "LOCAL LICENSE" ? "Local" : "Foreign"
        } Employer`}
        subtitle={`Upload your ${
          agencyObj?.licenseName == "LOCAL LICENSE" ? "local" : "foreign"
        } employer`}
        back={false}
      />

      <div className="mt-[40px] px-10 font-dmSans text-[18px]">
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer "
                  onClick={() => navigate("/client-list")}
                >
                  {agencyObj?.licenseName == "LOCAL LICENSE"
                    ? "local"
                    : "foreign"}{" "}
                  employers
                </span>
              ),
            },
            {
              title: (
                <span className="text-blueDark font-dmSans">Upload file</span>
              ),
            },
          ]}
        />
      </div>

      <div className="px-10 mt-[51px]">
        <span className="text-black1  text-[24px] font-bold dash-title leading-[32.4px]">
          Import list of{" "}
          {agencyObj?.licenseName == "LOCAL LICENSE" ? "local" : "foreign"}{" "}
          employers (excel file)
        </span>

        <div
          className="mt-[31px] bg-white flex flex-col justify-center items-center border bottom-solid border-[#F5F7FF]
		          w-[912px] h-[306px] px-0 py-[60px]"
        >
          {downloaded ? (
            <>
              <div className="text-black1 text-[18px] font-normal font-dmSans leading-[24px]">
                You have downloaded the template
              </div>
              <div className="mt-[10px] text-[#616161] text-[16px] font-normal leading-[24px]">
                Fill in the template with the list of{" "}
                {agencyObj?.licenseName == "LOCAL LICENSE"
                  ? "local"
                  : "foreign"}{" "}
                employers and upload it when you are done
              </div>
              <div className="flex flex-col mt-[20px]">
                <a
                  className="cursor-pointer w-[250px] h-[50px] px-[24px] py-3 bg-blueDark rounded-[28px] justify-center items-center gap-1 inline-flex
			                  	text-white text-[16px] font-medium "
                  download
                  onClick={() => setopenExcelUpload(true)}
                >
                  <img src={upload} alt="upload-icon" />
                  Upload template
                </a>
                <a
                  className="mt-[10px] w-[250px] h-[50px] px-[24px] py-3 border-none text-blueDark rounded-[28px] justify-center items-center gap-1 inline-flex
				            text-[16px] font-medium "
                  href={clientTemplate}
                  download
                  onClick={() => setDownloaded(true)}
                >
                  <img src={download} alt="download-icon" />
                  Re-download template
                </a>
              </div>
            </>
          ) : (
            <>
              <div className="text-black1 text-[18px] font-normal font-dmSans leading-[24px]">
                Complete the following steps to import your list of{" "}
                {agencyObj?.licenseName == "LOCAL LICENSE"
                  ? "local"
                  : "foreign"}{" "}
                employers
              </div>

              <div className="mt-[10px] text-[#616161] text-[16px] font-normal leading-[24px]">
                Step 1. Download the excel template by clicking the button
                below.
                <br />
                Step 2. Fill in the template with the list of{" "}
                {agencyObj?.licenseName == "LOCAL LICENSE"
                  ? "local"
                  : "foreign"}{" "}
                employers
                <br />
                Step 3. Upload the filled in template.
              </div>

              <div className="flex flex-col mt-[20px] justify-center items-center">
                <a
                  ref={linkRef}
                  className="  w-[250px] h-[50px] px-[24px] py-3 bg-blueDark rounded-[28px] justify-center items-center gap-1 inline-flex
				                text-white text-[16px] font-medium "
                  href={clientTemplate}
                  download
                  onClick={(e) => e.stopPropagation()}
                >
                  <span
                    className="flex items-center gap-x-[4px]"
                    onClick={handleClick}
                  >
                    <img src={download} alt="download-icon" />
                    Download template
                  </span>
                </a>

                <div
                  className="mt-[10px] w-full h-[50px] px-[24px] py-3 border-none text-blueDark rounded-[28px] justify-center items-center gap-1 inline-flex
				      text-[16px] font-medium cursor-pointer"
                  onClick={() => setopenExcelUpload(true)}
                >
                  Already have template ? Proceed to upload
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <ClientExcelModal
        open={openExcelUpload}
        handleCancelModal={handleCancelExcelUpload}
      />
    </>
  );
}
