import { Breadcrumb, Button } from "antd";
import React, { useEffect, useState } from "react"; 
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ConfirmSubmitDemandModal from "../modal/ConfirmSubmitDemandModal";
import { checkEditPermission, formatImgPath, formatPath, previewPaper } from "../../../utils";
import DemandJobView from "../editable-table/DemandJobView";
import InsideHeader from "../../../components/InsideHeader";

function DemandLetterJobView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { demandLetters, demandLoading } = useSelector((state) => state.letter);
  const { menuSidebarData } = useSelector((state) => state.admin);

  const { clientEditId } = useSelector((state) => state.client);
  const [receivedId, setReceivedId] = useState(clientEditId);

  useEffect(() => {
    setReceivedId(clientEditId);
  }, [clientEditId]);

  const { id } = useParams();

  const letterView = demandLetters?.find((item) => item?.dmlId == id);

  const showModal = async () => {
    setIsModalOpen(true);
  };

  const handleEdit = () => {
    navigate(`/demandletter-job-edit/${id}`);
  };

  const handleCancel = () => {
    if (receivedId) {
      navigate("/client-tabs");
    } else {
      navigate("/letter-list");
    }
  };

  return (
    <>
      <InsideHeader
        title="Demand Letter"
        subtitle="View jobs under demand letter"
        back={false}
      />
      <div className="mt-[2.5rem] px-10 font-dmSans text-[18px]">
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer "
                  onClick={() => navigate("/letter-list")}
                >
                  Demand Letters
                </span>
              ),
            },
            {
              title: (
                <span className="text-blueDark font-dmSans">
                  Demand letter jobs
                </span>
              ),
            },
          ]}
        />
      </div>

      <div className="flex h-36 px-10 items-center  text-center gap-5">
        <div className="h-[99px] w-[99px] rounded-full  shadow-md flex items-center justify-center p-[10px]">
          <img
            src={
              letterView?.cntIcon
                ? formatImgPath(letterView?.cntIcon)
                : "https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png"
            }
            alt="profile-icon vb"
            className="object-contain w-[100%] h-full"
          />
        </div>
        <span className="text-blackest text-[26px] font-medium font-dmSans leading-9">
          {letterView?.cntName}
        </span>
      </div>

      <div className="bg-white  mr-3 px-[53px] min-h-[100vh]">
        <div className="flex justify-between ">
          <div className="flex flex-col gap-y-5">
            <div className="font-[700] text-[24px] text-black1 dash-title">
              Demand Letter Ref No: {letterView?.dmlName}
            </div>

            <div className="">
              <span className="font-[700] text-[24px] text-black1 dash-title">
                File:
              </span>
              <span
                className="text-blueDark text-[22px] font-normal font-dmSans underline leading-9 cursor-pointer"
                onClick={() => previewPaper(letterView?.dmlFile)}
              >
                {formatPath(letterView?.dmlFile)}
              </span>
            </div>

            <div className="font-[700] text-[24px] text-black1 dash-title">
              Jobs under demand letter
            </div>
          </div>

          <div className="flex gap-x-3">
            {letterView?.dmlStatus === "ACTIVE" ||
            letterView?.dmlStatus === "REJECTED" ? (
              <button
                className="w-[150px] save-btn !bg-white !text-blueDark border border-blueDark"
                onClick={handleEdit}
              >
                Edit 
              </button>
            ) : null}

            {letterView?.dmlStatus !== "PENDING APPROVAL" &&
              letterView?.dmlStatus !== "APPROVED" && checkEditPermission(menuSidebarData,"DEMAND_LETTER") && (
                <button className="w-[250px] save-btn" onClick={showModal}>
                  Submit for approval
                </button>
              )}
          </div>
        </div>

        <div className="mt-[29px]">
          <DemandJobView id={id} />
        </div>
      </div>

      <ConfirmSubmitDemandModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        prodd={letterView}
      />
    </>
  );
}

export default DemandLetterJobView;
