import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Modal, Button, Switch, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

const ConfirmationModal = ({
  isModalOpen,
  setIsModalOpen,
  handleSubmit,
  loading,
  content,
  setIsModalOpenReopen,
}) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    if (setIsModalOpenReopen) {
      setIsModalOpenReopen(true);
    }
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer activity activity-header activity-close"
        title="Confirm Submission"
        open={isModalOpen}
        onCancel={handleCancel}
        width={898}
      >
        <div className="flex flex-col mt-[18px]">
          <div className="text-black1 text-2xl font-bold font-dmSans leading-9">
            Confirm Submission
          </div>
          <div className="text-black1 text-[21px] font-normal font-dmSans leading-loose">
            {content}
          </div>
        </div>

        <div className="flex flex-col items-center mt-[150px] gap-y-5 mb-[66px]">
          <button
            className="w-[225px] save-btn"
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <Spin /> : "Submit"}
          </button>

          <button
            type="button"
            className="w-[225px] cancel-btn border border-darkBlue flex items-center justify-center"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
export default ConfirmationModal;
