import SkillsForm from "../profile_tab_forms/SkillsForm";

 
export default function SkillsTab({ userId }) {

	return (
		<>
			<div className='mt-[3.5rem] mb-[5.31rem]'>
				<SkillsForm userId={userId} />
			</div>
		</>
	);
}
