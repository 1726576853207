import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Modal, Button, Switch, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { fetchDistress, saveDistress } from "../features/runaway/runawaySlice";

const { TextArea } = Input;
const ConfirmationDistressModal = ({
  isModalOpen,
  setIsModalOpen, 
  loading,
  content,
  setIsModalOpenReopen,
  prodd,
  distressStatus
}) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    if (setIsModalOpenReopen) {
      setIsModalOpenReopen(true);
    }
  };
  const dispatch = useDispatch()
const [data,setdata] = useState({})

async function fetchDistressData() {
  await dispatch(
    fetchDistress({
      instId: user?.usrInstId,
      dstStatus:'agency',
    })
  );
}

  const handleSubmit = async (data) => {
    const res = await dispatch(
      saveDistress({
        dstId: prodd?.dstId,
        dstDesc: data?.dstDesc,
        dstStatus: distressStatus
      })
    );
    if (res?.payload?.success) {
     await toast.success(res?.payload?.messages?.message);
     await form.resetFields();
     await fetchDistressData()
     await setIsModalOpen(false); 
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

   
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer activity activity-header activity-close"
        title="Confirm Submission"
        open={isModalOpen}
        onCancel={handleCancel}
        width={898}
      >
        <div className="flex flex-col mt-[18px]">
          <div className="text-black1 text-2xl font-bold font-dmSans leading-9">
            Confirm Submission
          </div>
          <div className="text-black1 text-[21px] font-normal font-dmSans leading-loose">
            {content}
          </div>
        </div>
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={handleSubmit}
          className=""
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <Form.Item
            label="Give Reason"
            name="dstDesc"
            rules={[
              {
                required: true,
                message: "Please add reason",
              },
            ]}
          >
            <TextArea rows={4} className="input-textarea" />
          </Form.Item>

        <div className="flex flex-col items-center mt-[150px] gap-y-2 mb-[66px]">
        <button
              type="submit"
              key="submit"
              className="w-[225px] save-btn"
              disabled={loading}
            >
              {loading ? <Spin /> : "Submit"}
            </button>

          <button
            type="button"
            className="w-[225px] cancel-btn border border-blueDark flex items-center justify-center"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
        </Form>
      </Modal>
    </>
  );
};
export default ConfirmationDistressModal;
