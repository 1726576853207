import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../features/auth/authSlice";
import globalReducer from "../features/global/globalSlice";
import applicationReducer from "../features/application/applicationSlice";
import demandLetterReducer from "../features/demand/demandLetterSlice";
import clientReducer from "../features/client/clientSlice";
import jobReducer from "../features/jobs/jobSlice";
import skillReducer from "../features/skills/skillSlice";
import applicantReducer from "../features/applicant/applicantSlice";
import cvReducer from "../features/cv/cvSlice";
import applicantJobReducer from "../features/applicantJob/applicantJobSlice";
import recommedationReducer from "../features/recommedations-referals/recommedationsSlice";
import activityReducer from "../features/activities/activitySlice";
import adminReducer from "../features/admin/adminSlice";
import demandJobReducer from "../features/demandJob/demandJobSlice";
import licenseReducer from "../features/license/licenseSlice";
import institutionReducer from "../features/institution/institutionSlice";
import employeeReducer from "../features/myEmployees/myEmployeesSlice";
import agencyReducer from "../features/agency/agencySlice";
import payReducer from "../features/payment/paySlice";
import noteReducer from "../features/notes/noteSlice";
import jobFilterReducer from "../features/jobFilters/jobFilterSlice";
import inboxReducer from "../features/inbox/inboxSlice";
import attestationReducer from "../features/attestation/attestationSlice";
import panelReducer from "../features/panel/panelSlice";
import settingReducer from "../features/settings/settingSlice";
import dropDownReducer from "../features/dropDowns/dropDownSlice";
import candidateReducer from "../features/candidate/candidateSlice";
import docReducer from "../features/doc/docSlice";
import eduReducer from "../features/edu/eduSlice";
import expReducer from "../features/exp/expSlice";
import profileReducer from "../features/profile/profileSlice";
import personReducer from "../features/person/personSllice";
import runawayReducer from "../features/runaway/runawaySlice";


const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
  application: applicationReducer,
  letter: demandLetterReducer,
  client: clientReducer,
  jobs: jobReducer,
  skill: skillReducer,
  applicant: applicantReducer,
  cv: cvReducer,
  applicantJob: applicantJobReducer,
  recommedation: recommedationReducer,
  activity: activityReducer,
  admin: adminReducer,
  demandJob: demandJobReducer,
  license: licenseReducer,
  institution: institutionReducer,
  employee: employeeReducer,
  agency: agencyReducer,
  pay: payReducer,
  notes: noteReducer,
  jobFilter: jobFilterReducer,
  inbox: inboxReducer,
  attestation: attestationReducer,
  panel: panelReducer,
  setting: settingReducer,
  dropDown: dropDownReducer,
  candidate: candidateReducer,
  doc: docReducer,
  edu: eduReducer,
  exp: expReducer,
  profile: profileReducer,
  prs: personReducer,
  runaway:runawayReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
