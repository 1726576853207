import React, { useEffect } from "react";
import { Button, Card, Collapse, Tabs, theme } from "antd";
import "../../../clients/client.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApplicantDetailsView from "./applicant-details/ApplicantDetailsView";
import SeekerDetailsView from "./job-seeker-information/SeekerDetailsView";
import ContactInfoView from "./contact/ContactInfoView";
import DisabilityView from "./disability/DisabilityView";
 
import CoverLetter from "../CoverLetter";
import EducationTab from "./tabs/EducationTab";
import ExperienceTab from "./tabs/ExperienceTab";
import SkillsTab from "./tabs/SkillsTab";
import CertificateTab from "./tabs/CertificateTab";
import NextOfKinTab from "./tabs/NextOfKinTab";

const ProfileAccordion = () => {
  const { token } = theme.useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { applicantEditId, viewEducation } = useSelector(
    (state) => state.applicant
  );

  const { singleJap } = useSelector((state) => state.candidate);

  const profileTabs = [
		{
			label: <span className='ml-2'>Education</span>,
			key: '1',
			children: <EducationTab userId={singleJap?.japUsrId} />,
		},
		{
			label: <span className='ml-2'>Experience</span>,
			key: '2',
			children: <ExperienceTab userId={singleJap?.japUsrId} />,
		},
		{
			label: <span className='ml-2'>Skills</span>,
			key: '3',
			children: <SkillsTab userId={singleJap?.japUsrId} />,
		},
		{
			label: <span className='ml-2'>Statutory Documents</span>,
			key: '4',
			children: <CertificateTab userId={singleJap?.japUsrId} />,
		},
		{
			label: <span className='ml-2'>Next of Kin</span>,
			key: '5',
			children: <NextOfKinTab userId={singleJap?.japUsrId} />,
		},
	];

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "1px solid #147CBC",
  };

  const getItems = (panelStyle) => {
    const items = [
      {
        key: "1",
        label: (
          <div className="flex flex-row w-[100%] gap-2">
            <div className="h-[25px] w-[25px] rounded-full bg-blueDark flex items-center justify-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clipPath="url(#clip0_5309_50587)">
                    <path
                      d="M5.2325 9.4206L2.79064 6.97874L1.97668 7.7927L5.2325 11.0485L12.2092 4.07177L11.3953 3.25781L5.2325 9.4206Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5309_50587">
                      <rect width="13.9535" height="13.9535" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div>

            <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
              Basic Information
            </div>
          </div>
        ),
        children: <ApplicantDetailsView />,
        extra: "",
        showArrow: true,
        style: panelStyle,
      },
      {
        key: "2",
        label: (
          <div className="flex flex-row w-[100%] gap-2">
            <div className="h-[25px] w-[25px] rounded-full bg-blueDark flex items-center justify-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clipPath="url(#clip0_5309_50587)">
                    <path
                      d="M5.2325 9.4206L2.79064 6.97874L1.97668 7.7927L5.2325 11.0485L12.2092 4.07177L11.3953 3.25781L5.2325 9.4206Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5309_50587">
                      <rect width="13.9535" height="13.9535" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div>

            <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
              Job Seeker Information
            </div>
          </div>
        ),
        children: <SeekerDetailsView />,
        extra: "",
        showArrow: true,
        style: panelStyle,
      },
     
      {
        key: "5",
        label: (
          <div className="flex flex-row w-[100%] gap-2">
            {viewEducation ? (
              <div className="h-[25px] w-[25px] rounded-full bg-blueDark flex items-center justify-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_5309_50587)">
                      <path
                        d="M5.2325 9.4206L2.79064 6.97874L1.97668 7.7927L5.2325 11.0485L12.2092 4.07177L11.3953 3.25781L5.2325 9.4206Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5309_50587">
                        <rect width="13.9535" height="13.9535" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <circle
                  cx="12.5"
                  cy="12.5"
                  r="12"
                  fill="white"
                  stroke="#147CBC"
                />
                <circle cx="12.7904" cy="12.7943" r="2.32558" fill="#147CBC" />
              </svg>
            )}

            <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
              Summary
            </div>
          </div>
        ),
        children: <Tabs defaultActiveKey="1" items={profileTabs} />,
        extra: "",
        showArrow: true,
        style: panelStyle,
      },
      {
        key: "6",
        label: (
          <div className="flex flex-row w-[100%] gap-2">
            <div className="h-[25px] w-[25px] rounded-full bg-blueDark flex items-center justify-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clipPath="url(#clip0_5309_50587)">
                    <path
                      d="M5.2325 9.4206L2.79064 6.97874L1.97668 7.7927L5.2325 11.0485L12.2092 4.07177L11.3953 3.25781L5.2325 9.4206Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5309_50587">
                      <rect width="13.9535" height="13.9535" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div>

            <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
              Disability
            </div>
          </div>
        ),
        children: <DisabilityView />,
        extra: "",
        showArrow: true,
        style: panelStyle,
      },
    ];

    if (singleJap?.japCvlFile) {
      items.push({
        key: "7",
        label: (
          <div className="flex flex-row w-[100%] gap-2">
            <div className="h-[25px] w-[25px] rounded-full bg-blueDark flex items-center justify-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clipPath="url(#clip0_5309_50587)">
                    <path
                      d="M5.2325 9.4206L2.79064 6.97874L1.97668 7.7927L5.2325 11.0485L12.2092 4.07177L11.3953 3.25781L5.2325 9.4206Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5309_50587">
                      <rect width="13.9535" height="13.9535" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div>

            <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
              Cover Letter
            </div>
          </div>
        ),
        children: <CoverLetter />,
        extra: "",
        showArrow: true,
        style: panelStyle,
      });
    }

    return items;
  };

  return (
    <Card className="">
      <div className="flex flex-col w-[100%]  ">
        {!applicantEditId && (
          <div className="text-blackest text-[1rem] font-medium font-dmSans leading-[30px] mt-[30px] mb-[44px]">
            Please fill in the following details
          </div>
        )}

        <Collapse
          bordered={false}
          expandIconPosition="end"
          activeKey={[
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            singleJap?.japCvlFile ? "7" : null,
          ]}
          style={{
            background: token.colorBgContainer,
          }}
          items={getItems(panelStyle)}
          onChange={() => {}}
        />
      </div>
    </Card>
  );
};

export default ProfileAccordion;
