import AcademicTableForm from "../profile_tab_forms/AcademicForm";
import ProfessionalTableForm from "../profile_tab_forms/ProfessionalForm";

 
export default function EducationTab({ userId }) {

	return (
		<>
			<h2 className='heading_heading_5 mt-[3.5rem]'>Academic</h2>
			<AcademicTableForm userId={userId} viewOnly={true} />

			<h2 className='heading_heading_5 mt-[5.88rem]'>Training/ Professional</h2>
			<ProfessionalTableForm userId={userId} viewOnly={true} />
		</>
	);
}
