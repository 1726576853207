import NextOfKinForm from "../profile_tab_forms/NextOfKinForm";

 
export default function NextOfKinTab({userId}) {
	return (
		<>
			<div className='mt-[3.5rem] mb-[5.31rem]'>
				<NextOfKinForm userId={userId} />
			</div>
		</>
	);
}
