import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  usersData: [],
  usersLoading: false,
  refetchKey: "",
  userEditId: "",
  clientAdminView: false,
  singleAdminUser: {},
  menuData:[],
  menuLoading: false,
  menuSidebarData:[],
};
export const createAdminUsers = createAsyncThunk(
  "applicantSlice/createApplicantDetails",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveSubUser.action`, data);
    return res.data;
  }
);

export const fetchAdminUsers = createAsyncThunk(
  "applicantSlice/fetchApplicantsDetails",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchSubUsers.action?usrInstId=${data?.usrInstId}&usrRoleId=4`
    );
    return res?.data?.data?.result;
  }
);

export const fetchSingleAdminUser = createAsyncThunk(
  "applicantSlice/fetchSingleAdminUser",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchUsers.action?usrId=${data?.usrId}`
    );
    return res?.data?.data?.result;
  }
);

export const disableUser = createAsyncThunk(
  "applicantSlice/disableUser",
  async (data) => {
    const res = await axiosInstance.post(`${url}/emp/saveMyInstitution.action`, data);
    return res.data;
  }
);


export const deleteUser = createAsyncThunk(
  "applicantSlice/deleteUser",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/emp/deleteMyInstitution.action?myId=${data?.myId}`
    );
    return res.data;
  }
);

export const fetchMenuSetups = createAsyncThunk(
  "applicantSlice/fetchMenuSetups",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/menu/fetchMenuSetups.action?myId=${data?.myId}&mnsType=AGENCY`
    );
    return res?.data?.data?.result;
  }
);


export const saveMyMenuData = createAsyncThunk(
  "applicantSlice/saveMyMenuData",
  async (data) => {
    const res = await axiosInstance.post(`${url}/menu/saveMyMenu.action`,data); 
    return res.data;
  }
);

export const fetchMenuSidebar = createAsyncThunk(
  "applicantSlice/fetchMenuSidebar",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/menu/fetchMenuSetups.action?mymAccess=${data?.mymAccess}&myId=${data?.myId}`
    );
    return res?.data?.data?.result;
  }
);
 

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },
    setRefetch: (state) => {
      state.refetch = !state.refetch;
    },
    setUserEditId: (state, action) => {
      state.userEditId = action.payload;
    },
    setClientAdminView: (state, action) => {
      state.clientAdminView = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(createAdminUsers.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(createAdminUsers.fulfilled, (state, action) => {
        state.usersLoading = false;
      })
      .addCase(createAdminUsers.rejected, (state) => {
        state.usersLoading = false;
      })

      .addCase(fetchAdminUsers.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(fetchAdminUsers.fulfilled, (state, action) => {
        state.usersLoading = false;
        state.usersData = action.payload;
      })
      .addCase(fetchAdminUsers.rejected, (state) => {
        state.usersLoading = false;
      })

      .addCase(fetchSingleAdminUser.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(fetchSingleAdminUser.fulfilled, (state, action) => {
        state.usersLoading = false;
        state.singleAdminUser = action.payload[0];
      })
      .addCase(fetchSingleAdminUser.rejected, (state) => {
        state.usersLoading = false;
        state.singleAdminUser = {};
      })

      .addCase(disableUser.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(disableUser.fulfilled, (state, action) => {
        state.usersLoading = false;
      })
      .addCase(disableUser.rejected, (state) => {
        state.usersLoading = false;
      })

      .addCase(deleteUser.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.usersLoading = false;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.usersLoading = false;
      })
 
      .addCase(fetchMenuSetups.pending, (state) => {
        state.menuLoading = true;
      })
      .addCase(fetchMenuSetups.fulfilled, (state, action) => {
        state.menuLoading = false;
        state.menuData = action.payload;
      })
      .addCase(fetchMenuSetups.rejected, (state) => {
        state.menuLoading = false;
      })

      .addCase(saveMyMenuData.pending, (state) => {
        state.menuLoading = true;
      })
      .addCase(saveMyMenuData.fulfilled, (state, action) => {
        state.menuLoading = false; 
      })
      .addCase(saveMyMenuData.rejected, (state) => {
        state.menuLoading = false;
      })

      .addCase(fetchMenuSidebar.pending, (state) => {
        state.menuLoading = true;
      })
      .addCase(fetchMenuSidebar.fulfilled, (state, action) => {
        state.menuLoading = false;
        state.menuSidebarData = action.payload;
      })
      .addCase(fetchMenuSidebar.rejected, (state) => {
        state.menuLoading = false;
      })

      
  },
});

export default adminSlice.reducer;
export const {
  handleSidenavFullWidth,
  setRefetchKey,
  setUserEditId,
  setClientAdminView,
} = adminSlice.actions;
