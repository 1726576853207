import { Input, Form } from 'antd';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function BillingInfo() {
	const formRef = useRef(null);
	const [form] = Form.useForm();

	const { agencyObj } = useSelector((state) => state.agency);

	return (
		<>
			<h3 className='license-header'>Billing Info</h3>

			<div className='mt-10'>
				<Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					// onFinish={onFinish}
					style={{
						maxWidth: '100%',
					}}
					form={form}
					initialValues={{
						...agencyObj,
					}}
				>
					<Form.Item name='instName' label='Company Name'>
						<Input
							readOnly
							className='input'
						/>
					</Form.Item>
					<Form.Item name='instPhysicalAddress' label='Company Address'>
						<Input
							readOnly
							className='input'
						/>
					</Form.Item>
					<Form.Item name='instEmail' label='Email'>
						<Input
							readOnly
							className='input'
						/>
					</Form.Item>
					<Form.Item name='instWorkTel' label='Phone number'>
						<Input
							readOnly
							className='rounded-[8px] mt-2 h-[44px] border border-black'
						/>
					</Form.Item>
				</Form>
			</div>
		</>
	);
}
