import DocsForm from "../profile_tab_forms/DocsForm";

 
export default function CertificateTab({ userId }) {

	return (
		<>
			<>
			<div className='mt-[3.5rem] mb-[5.31rem]'>
				<DocsForm userId={userId} />
			</div>
		</>
		</>
	);
}
