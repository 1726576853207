import { Button, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import JobTable from "./JobTable";
import {
  cleanUpJobEditId,
  cleanupJob,
  fetchJobsByStatus,
  setRefetchJobTables,
  setRefetchKeyJobTables,
} from "../../../../features/jobs/jobSlice";
import ArchivedJobTable from "./ArchivedJobTable";
import RecentJobTable from "./RecentJobTable";
import ReadvertisedJobTable from "./ReadvertisedJobTable";
import CancelledJobTable from "./CancelledJobTable";
import InsideHeader from "../../../../components/InsideHeader";
import NoJobDataTable from "../../../../components/NoJobDataTable";
import AddJobModal from "../../job-add-modal/AddJobModal";
import { cleanUpEditId } from "../../../../features/client/clientSlice";

function JobList() {
  const dispatch = useDispatch();
  const [active, setactive] = useState("1");
  const { jobByStatusData } = useSelector((state) => state.jobs);

  const { user } = useSelector((state) => state.auth);
  const { clientEditId } = useSelector((state) => state.client);

  const { TabPane } = Tabs;

  const tabConfigurations = [
    {
      label: (
        <span className="flex items-center">
          <span className=" ">All Jobs</span>
        </span>
      ),
      key: "1",
      children: <JobTable />,
    },

    {
      label: (
        <span className="flex items-center">
          <span className="">Recently published</span>
        </span>
      ),
      key: "2",
      children: <RecentJobTable />,
    },

    {
      label: (
        <span className="flex items-center">
          <span className="">Archived jobs</span>
        </span>
      ),
      key: "3",
      children: <ArchivedJobTable />,
    },

    {
      label: (
        <span className="flex items-center">
          <span className="">Re-advertised jobs</span>
        </span>
      ),
      key: "4",
      children: <ReadvertisedJobTable />,
    },

    {
      label: (
        <span className="flex items-center">
          <span className="">Cancelled jobs</span>
        </span>
      ),
      key: "5",
      children: <CancelledJobTable />,
    },
  ];

  async function refetching(activeKey) {
    await dispatch(setRefetchJobTables());
    await dispatch(setRefetchKeyJobTables(activeKey));
    await setactive(activeKey);
  }

  useEffect(() => {}, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = async () => {
    await dispatch(cleanupJob());
    dispatch(cleanUpJobEditId());
    await dispatch(cleanUpEditId());
    setIsModalOpen(true);
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchJobsDetails() {
    const res = await dispatch(
      fetchJobsByStatus({
        jobInstId: user?.usrInstId,
        jobCntId: "",
        jobStatus: "MY_JOBS",
        limit: pageSize,
        start: pageIndex,
      })
    );
  }

  async function fetchJobsDetailsClient() {
    const res = await dispatch(
      fetchJobsByStatus({
        jobInstId: user?.usrInstId,
        jobCntId: clientEditId,
        jobStatus: "MY_JOBS",
        limit: pageSize,
        start: pageIndex,
      })
    );
  }

  useEffect(() => {
    if (clientEditId) {
      fetchJobsDetailsClient();
    } else {
      fetchJobsDetails();
    }
  }, [clientEditId]);

  useEffect(() => {
    if (clientEditId) {
      fetchJobsDetailsClient();
    } else {
      fetchJobsDetails();
    }
  }, []);

  return (
    <>
      <InsideHeader
        title="Job Adverts"
        subtitle="Advertise your job vacancies"
        back={false}
      />
       <div className="h-[111px]">
          <Tabs
            items={tabConfigurations}
            defaultActiveKey="1"
            activeKey={active}
            onChange={refetching}
            className="jobs-tab jobs-tab-wrap job-bar"
          />
        </div>
      {/* {jobByStatusData && jobByStatusData?.length > 0 ? (
        <div className="h-[111px]">
          <Tabs
            items={tabConfigurations}
            defaultActiveKey="1"
            activeKey={active}
            onChange={refetching}
            className="jobs-tab jobs-tab-wrap job-bar"
          />
        </div>
      ) : (
        <NoJobDataTable
          showButton={true}
          showModal={showModal}
          buttonTitle="Add job vacancy"
          noDataTitle="Create a job advert"
        />
      )} */}

      <AddJobModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        showModal={showModal}
      />
    </>
  );
}

export default JobList;
