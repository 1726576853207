import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Form, Input, Modal, Select, Spin, message } from "antd";
import "./demand-modal.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Dragger from "antd/es/upload/Dragger";
import { fetchDemandLetters, saveDemandLetter } from "../../../features/demand/demandLetterSlice";
 
const url = process.env.REACT_APP_API_BASE_URL;
const AddDemand = ({
  isModalOpen,
  setIsModalOpen, 
}) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const {user} =  useSelector((state)=>state.auth)

 
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const {demandLetterEditMode,demandLetterEditId,demandLoading} =  useSelector((state)=>state.letter)
  const { clientLoading,clientData,clientEditId } = useSelector((state) => state.client);

  const[clientDataList,setClientDataList] =  useState(clientData);
  const [receivedId,setReceivedId] =  useState(clientEditId);
  
  function closeModal() {
    toast.dismiss();
  }
  const [data, setdata] = useState({});
 

  const [fileUrl, setfileUrl] = useState('');
	const [fileName, setfileName] = useState('');

  const props = {
		name: 'file',
		multiple: false,
		accept: '.pdf',
		action: `${url}/nea/saveFile.action`,
		onChange(info) {
			const { status } = info.file;
			if (status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (status === 'done') {
				message.success(`${info.file.name} file uploaded successfully.`);
				if (info?.file?.response?.success) {
					setfileUrl(info?.file?.response?.jsonData);
					setfileName(info?.file?.name);
				} else {
					setfileUrl('');
					setfileName('');
				}
				console.log(info.file.response);
			} else if (status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files);
		},
	};

  function handleSelectChange(value) {
    setdata((prevData) => ({
      ...prevData,
      cntName: value,
    }));
  }
 
  const onFinish = async (values) => {
    values.cntName = data?.cntName ?? clientDataList?.find((item) => item?.cntId == receivedId)?.cntId
    if(!values?.cntName){
      toast.error("Select client")
    }else{
      const res = await dispatch(saveDemandLetter({
        dmlFile:fileUrl, 
        dmlUsrId:user.usrInstId,
        dmlCntId:values?.cntName,
        dmlId:demandLetterEditMode ? demandLetterEditId : null,
      }));
      if(res?.payload?.dmlId){
        toast.success(`${demandLetterEditMode ? "Demand letter updated successfully": "Demand letter addded successfully"}`)
        await dispatch(fetchDemandLetters({
          dmlUsrId: user.usrInstId,
          dmlCntId: receivedId,
        }));
       form.resetFields();
       setdata({})
        handleOk();
      }else{
        toast.error(`${demandLetterEditMode ?  "Failed to update demand letter, please try again": "Failed to add demand letter, please try again" }`)
      }
    }
 
  };

  useEffect(()=>{
    setClientDataList(clientData);
  },[clientData])

  useEffect(()=>{ 
  },[clientDataList]);
  
  useEffect(()=>{
    setReceivedId(clientEditId)
  },[clientEditId])
 
  
  return (
    <>
      <Modal
        className="modal-btn mod-height-demand mod-footer demand-body close-con-demand demand-title"
        title={demandLetterEditMode ? "Edit Demand Letter" : "Add Demand Letter"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        

        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="grid grid-cols-1">
           
              <div className="flex flex-col mb-4">
                <label htmlFor="">Choose client</label>
                <Select
                  style={{
                    width: "100%",
                    height: "44px",
                  }} 
                  options={
                    clientDataList &&
                    clientDataList?.map((item) => ({
                      value: item.cntId,
                      label: item.cntName,
                    }))
                  }
                  defaultValue={
                    receivedId?
                    clientDataList &&
                    clientDataList?.find((item) => item?.cntId == receivedId)?.cntId : undefined
                  }
                  
                  onChange={(value) => {
                    handleSelectChange(value);
                  }}
                  onDropdownVisibleChange={() => { 
                  }}
                />
                
              </div>

              <Form.Item
								name='attFile'
								label={demandLetterEditMode ? 'Change document' :'Upload Document' } 
								rules={[
									{
										required: true,
										message: 'Please upload a file',
									},
								]}
							>
								<Dragger {...props}>
									<div className='flex justify-center'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='29'
											height='29'
											viewBox='0 0 29 29'
											fill='none'
                      className="mt-[-8px]"
										>
											<path
												d='M25.375 18.125V22.9583C25.375 23.5993 25.1204 24.214 24.6672 24.6672C24.214 25.1204 23.5993 25.375 22.9583 25.375H6.04167C5.40073 25.375 4.78604 25.1204 4.33283 24.6672C3.87961 24.214 3.625 23.5993 3.625 22.9583V18.125'
												stroke='#147CBC'
												stroke-width='2'
												stroke-linecap='round'
												stroke-linejoin='round'
											/>
											<path
												d='M20.5423 9.66667L14.5007 3.625L8.45898 9.66667'
												stroke='#147CBC'
												stroke-width='2'
												stroke-linecap='round'
												stroke-linejoin='round'
											/>
											<path
												d='M14.5 3.625V18.125'
												stroke='#147CBC'
												stroke-width='2'
												stroke-linecap='round'
												stroke-linejoin='round'
											/>
										</svg>
										<p className='ant-upload-text !ml-2'>Upload pdf</p>
									</div>
								</Dragger>
							</Form.Item>
          </div>
          <div className="flex justify-between mt-[10rem] mb-[40px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center">
              <Button
                key="back"
                onClick={handleCancel}
                className="w-[138px] h-12 px-7 py-4 rounded-[56px] border-none justify-center items-center gap-2.5 inline-flex
          text-blueDark text-[1rem] font-medium font-dmSans leading-normal"
              >
                Cancel
              </Button>
              ,
              <button
                key="submit"
                type="submit"
                className="w-[239px] h-12 px-7 py-4 bg-blueDark rounded-[56px] shadow justify-center items-center gap-2.5 inline-flex
          text-blue-50 text-[1rem] font-medium font-dmSans leading-normal" 
              >
                {demandLoading ? <Spin /> : "Continue"}
              </button>
              ,
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default AddDemand;
