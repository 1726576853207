import React, { useEffect, useRef, useState } from "react";
import { Checkbox, Divider, Form, Modal, Spin } from "antd";
import "../clients/client.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { fetchMenuSetups, saveMyMenuData } from "../../features/admin/adminSlice";

const plainOptions = ["View", "Edit", "Delete"];

const PermissionModal = ({ isModalOpen, setIsModalOpen, prodd }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const CheckboxGroup = Checkbox.Group;
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const { usersLoading, menuLoading, menuData } = useSelector(
    (state) => state.admin
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ ...prodd });

  const [checkedList, setCheckedList] = useState({});

  useEffect(() => {
    setFormData(prodd);
}, [prodd]);

  const onChangeCheckBox = async(mnsId,mymId,list) => {
    await setCheckedList(prevState => ({
      ...prevState,
      [mnsId]: list  
    }));
    const mymAccess = list?.includes("View");
    const mymEdit = list?.includes("Edit");
    const mymDelete = list?.includes("Delete");
  
   const res = await dispatch(saveMyMenuData({
      mymId:mymId,
      mymMnsId: mnsId,
      mymEdit: mymEdit, 
      mymDelete: mymDelete,
      mymAccess: mymAccess,
      mymMyId: prodd?.myId
    }));  
    if(res?.payload?.success){
     await toast.success(res?.payload?.messages?.message);
     await fetchPermissionData()
    }else{
      toast.error(res.payload?.messages?.message)
    }
  };

  const onCheckAllChange = async (mnsId,mymId,e) => { 
   await setCheckedList(prevState => ({
      ...prevState,
      [mnsId]: e.target.checked ? plainOptions : []
    }));
    const res = await dispatch(saveMyMenuData({
      mymId:mymId,
      mymMnsId: mnsId,
      mymEdit: e.target.checked ? true :false, 
      mymDelete: e.target.checked ? true :false,
      mymAccess: e.target.checked ? true :false,
      mymMyId: prodd?.myId
    }));  
    if(res?.payload?.success){
     await toast.success(res?.payload?.messages?.message);
     await fetchPermissionData()
    }else{
      toast.error(res.payload?.messages?.message)
    }
  };

  const determineInitialCheckedList = () => {
    const initialCheckedState = {};

    menuData?.forEach((item) => {
      const itemCheckedList = [];
      if (item?.mymAccess) itemCheckedList.push("View");
      if (item?.mymEdit) itemCheckedList.push("Edit");
      if (item?.mymDelete) itemCheckedList.push("Delete");

      initialCheckedState[item.mnsId] = itemCheckedList;
    });

    return initialCheckedState;
  };

  const onFinish = async (data) => {
    // Your form submission logic
  };

  async function fetchPermissionData() {
    dispatch(fetchMenuSetups({
      myId: prodd?.myId
    }));
  }

  useEffect(() => {
    if (prodd?.myId) {
      fetchPermissionData();
    }
  }, [prodd?.myId]);

  useEffect(() => {
    if (menuData && menuData.length > 0) {
      const initialCheckedList = determineInitialCheckedList();
      setCheckedList(initialCheckedList);
    }
  }, [menuData]); 
  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer users activity-header activity-close"
        title="Permissions"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        width={800}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className="px-[15px] pb-5"
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="font-dmSans text-[1rem] font-[500] text-[#212121] mt-[1.62rem]">
            Select permissions to assign to this user
          </div> 
          {menuData && menuData.map((item) => {
            const checkAll = plainOptions.length === (checkedList[item.mnsId]?.length || 0);
            const indeterminate = (checkedList[item.mnsId]?.length || 0) > 0 && (checkedList[item.mnsId]?.length || 0) < plainOptions.length;

            return (
              <div key={item?.mymId} className="mt-[1.38rem] flex items-center w-full">
                <Checkbox
                  className="w-[38.5rem]"
                  indeterminate={indeterminate}
                  onChange={(e) => onCheckAllChange(item?.mnsId,item?.mymId,e)}
                  checked={checkAll}
                >
                  {item?.mnsName}
                </Checkbox>
                <CheckboxGroup
                  className="w-full flex items-center justify-between"
                  options={plainOptions}
                  value={checkedList[item?.mnsId] || []}
                  onChange={(list) => onChangeCheckBox(item?.mnsId,item?.mymId,list)}
                />
              </div>
            );
          })}
 
 
        </Form>
      </Modal>
    </>
  );
};

export default PermissionModal;
