import { Button, DatePicker, Form, Select, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchEmployees,
  saveEmployee,
  setEditContract,
  setViewContract,
} from "../../../../features/myEmployees/myEmployeesSlice";
import toast from "react-hot-toast";
import countryData from "../../../../countries.json";
import moment from "moment";
import { disabledDate } from "../../../../utils";

function ContractDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { user } = useSelector((state) => state.auth);
  const {
    employeesData,
    employeeLoading,
    singleEmployee,
    employeeEditId,
    editContract,
    editBasic,
    editJob
  } = useSelector((state) => state.employee);

  const [receivedEditId, setReceivedEditId] = useState(
    Object?.keys(singleEmployee)?.length > 0  ? singleEmployee?.emplId : employeeEditId
  );
  const singleEmployeeInfo = employeesData?.find(
    (item) => item?.emplId === receivedEditId
  );
  useEffect(() => {
    setReceivedEditId(Object?.keys(singleEmployee)?.length > 0  ? singleEmployee?.emplId : employeeEditId);
  }, [singleEmployee, employeeEditId]);

  const [formData, setFormData] = useState({ ...singleEmployeeInfo });

  const [data, setdata] = useState({});

  function handleSelectChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      emplCountryOfEmployment: value,
    }));
  }

  function onMonthChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      emplDepartureDate: value,
    }));
  }

  function onContractCommencmentChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      emplContractCommencement: value,
    }));
  }

  function onContractExpiryChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      emplContractExpiryDate: value,
    }));
  }

  const handleCancel = (e) => {
    if (employeeEditId && editContract) {
      dispatch(setEditContract(false));
      dispatch(setViewContract(true));
    }
  };

  async function fetchEmployeeDetails() {
    await dispatch(fetchEmployees({
      emplInstId:user?.usrInstId
    }));
  }

  const onFinish = async (data) => {
    if(!formData?.emplDepartureDate || !formData?.emplCountryOfEmployment || !formData?.emplContractCommencement || !formData?.emplContractExpiryDate){
      toast.error("fill all required fields")
      return;
    }
    const res = await dispatch(
      saveEmployee({
        emplDepartureDate: formData?.emplDepartureDate,
        emplCountryOfEmployment: formData?.emplCountryOfEmployment,
        emplContractCommencement: formData?.emplContractCommencement,
        emplContractExpiryDate: formData?.emplContractExpiryDate,
        emplId: receivedEditId,
      })
    );
    if (res?.payload?.emplId) {
      toast.success("Employees details saved successfully");
      dispatch(setViewContract(true));
      form.resetFields();
    } else {
      toast.error("Error saving employee, please try again");
    }
  };

  useEffect(() => {
    fetchEmployeeDetails();
  }, []);

  return (
    <div>
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
          marginTop: 20,
        }}
        form={form}
      >
        <div className="grid grid-cols-2 gap-x-6">
          <Form.Item
            label="Date of departure from Kenya" 
            className="mr-6 w-full"
          >
            <DatePicker 
              style={{
                width: "100%",
                height: "44px",
              }} 
              className="mr-3 border border-black"
              format={"DD-MM-YYYY"}
              name="emplDepartureDate"
              onChange={onMonthChange}  
              placeholder={
                formData?.emplDepartureDate
                  ? moment(formData?.emplDepartureDate)?.format("DD-MM-YYYY")
                  : "Select Date"
              }
              disabledDate={disabledDate}
            />
          </Form.Item>

          <Form.Item
            label="Country of Work"
            rules={[
              {
                required: true,
                message: "Please add country of work",
              },
            ]}
          >
            <Select
              required
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              style={{
                width: "100%",
                height: "44px",
              }}
              options={
                countryData?.length > 0 &&
                countryData?.map((edu) => ({
                  value: edu.name,
                  label: edu?.emoji + " " + " " + edu.name ,
                }))
              }
              onChange={(value) => {
                handleSelectChange(value);
              }}
              onDropdownVisibleChange={() => {
                //trigger something
              }}
              name="emplCountryOfEmployment"
              value={formData?.emplCountryOfEmployment}
            />
          </Form.Item>

          <Form.Item
            label="Contract Commencement"
            rules={[
              {
                required: true,
                message: "Contract commencement date is required",
              },
            ]}
            className="mr-6 w-full"
          >
            <DatePicker
              required
              style={{
                width: "100%",
                height: "44px",
              }} 
              className="mr-3 border border-black"
              format={"DD-MM-YYYY"}
              name="emplContractCommencement"
              onChange={onContractCommencmentChange} 
              placeholder={
                formData?.emplContractCommencement
                  ? moment(formData?.emplContractCommencement)?.format("DD-MM-YYYY")
                  : "Select Date"
              }
              disabledDate={disabledDate}
            />
          </Form.Item>

          <Form.Item
            label="Contract Expiry Date"
            rules={[
              {
                required: true,
                message: "Contract expiry date is required",
              },
            ]}
            className="mr-6 w-full"
          >
            <DatePicker
              required
              style={{
                width: "100%",
                height: "44px",
              }} 
              className="mr-3 border border-black !placeholder-[red]"
              format={"DD-MM-YYYY"}
              name="emplContractExpiryDate"
              onChange={onContractExpiryChange} 
              placeholder={
                formData?.emplContractExpiryDate
                  ? moment(formData?.emplContractExpiryDate)?.format("DD-MM-YYYY")
                  : "Select Date"
              }
              disabledDate={disabledDate}
            />
          </Form.Item>
        </div>

        <div className="flex items-center justify-between mt-[150px] mb-[79px]">
          <div className="flex items-start"></div>
          <div className="flex gap-5 items-center">
            {editContract && (
              <Button
                className="text-blueDark text-[1rem] font-medium font-dmSans leading-normal border-none
              w-[168px] h-12 px-[18px] py-3   justify-center items-center gap-2 inline-flex"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            )}

            <button
              className="text-white text-[1rem] font-medium font-dmSans leading-normal bg-blueDark
            w-[168px] h-[46px] px-[18px] py-3 rounded-[46px] border justify-center items-center gap-2 inline-flex"
              type="submit"
            >
              {employeeLoading  ? <Spin /> : "Save"}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default ContractDetails;
