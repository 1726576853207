import { Button, Form, Input, Select, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchClients,
  saveClient,
  setClientEditId,
  setEditBasic,
  setViewBasic,
} from "../../features/client/clientSlice";
import { showToastError, showToastSuccess } from "../../utils";
import toast from "react-hot-toast";
import { fetchSkillCategorys } from "../../features/jobs/jobSlice";
import { fetchEconomicActivitys } from "../../features/dropDowns/dropDownSlice";

function BasicInfo() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { clientLoading, clients, clientData, clientEditId } = useSelector(
    (state) => state.client
  );

  
  const { economicActivity } = useSelector((state) => state.dropDown);
  const { skillsData } = useSelector((state) => state.jobs);
  const { user } = useSelector((state) => state.auth);
  const [data, setdata] = useState({});

  const [receivedEditId, setReceivedEditId] = useState(
    Object?.keys(clients)?.length > 0 ? clients?.cntId : clientEditId
  );

  useEffect(() => {
    setReceivedEditId(
      Object?.keys(clients)?.length > 0 ? clients?.cntId : clientEditId
    );
  }, [clients, clientEditId]);

  const singleClient = clientData?.find(
    (item) => item?.cntId === receivedEditId
  );
  const [formData, setFormData] = useState({ ...singleClient });

  useEffect(() => {
    setFormData({ ...singleClient });
  }, [singleClient]);

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  function handleSelectIndustry(value) {
    setFormData((prevData) => ({
      ...prevData,
      cntIndustry: value,
    }));
  }

  const handleCancel = async () => {
    await dispatch(setEditBasic(false));
    await dispatch(setViewBasic(true));
  };

  const onFinish = async (data) => {
    const res = await dispatch(
      saveClient({
        cntId: formData?.cntId,
        cntCreatedBy: user?.usrId,
        cntInstId: user?.usrInstId,
        cntName: formData?.cntName,
        cntWebsite: formData?.cntWebsite,
        cntIndustry: formData?.cntIndustry,
        cntDescription: formData?.cntDescription,
      })
    );
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
      form.resetFields();
      await dispatch(setViewBasic(true));
      await dispatch(setEditBasic(false));
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  async function fetchClientDetails() {
    const res = await dispatch(
      fetchClients({
        cntInstId: user?.usrInstId,
      })
    );
  }

  async function fetchIndustryDetails() {
    await dispatch(fetchEconomicActivitys());
  }

  useEffect(() => {
    fetchClientDetails();
    fetchIndustryDetails();
  }, []);
 
  return (
    <div className="mt-[38px]">
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
        }}
        form={form}
      >
        <div className="grid grid-cols-2">
          <Form.Item
            label="Client name" 
            rules={[
              {
                required: true,
                message: "Please add client name",
              },
            ]}
          >
            <Input
              required
              name="cntName"
              value={formData.cntName}
              onChange={onChange}
              className="input"
            />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
          <Form.Item
            label="Website"
            rules={[
              {
                required: true,
                message: "Please add website",
              },
              {
                type: "url",
                message: "Please add a valid URL",
              },
            ]}
          >
            <Input
              required
              name="cntWebsite"
              value={formData.cntWebsite}
              onChange={onChange}
              className="input"
            />
          </Form.Item>

          <Form.Item
            label="Economic activity"
            rules={[
              {
                required: true,
                message: "Please select industry",
              },
            ]}
          >
            <Select
              required
              name="cntIndustry"
              value={formData?.cntIndustry}
              style={{
                width: "100%",
                height: "3.25rem",
              }}
              options={
                economicActivity?.length > 0 &&
                economicActivity?.map((item) => ({
                  value: item?.ecoName,
                  label: item?.ecoName,
                }))
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
              onChange={(value) => {
                handleSelectIndustry(value);
              }}
              onDropdownVisibleChange={() => {
                fetchIndustryDetails();
              }}
            />
          </Form.Item>
        </div>
        <Form.Item
          label="Client description"
          rules={[
            {
              required: true,
              message: "Please add client description",
            },
          ]}
        >
          <TextArea
            required
            name="cntDescription"
            value={formData.cntDescription}
            onChange={onChange}
            rows={4}
            className="rounded-[8px] h-[44px] w-full border border-black"
          />
        </Form.Item>

        <div className="flex items-center justify-between mt-[35px]">
          <div className="flex items-start"></div>
          <div className="flex  items-center">
            <button
              type="button"
              className="cancel-btn  w-[168px]"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              className="save-btn  w-[168px]"
              type="submit"
              disabled={clientLoading ? true : false}
            >
              {clientLoading ? <Spin /> : "Save"}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default BasicInfo;
