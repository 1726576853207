import React, { useEffect } from "react";
import { Button, Card, Collapse, Descriptions, theme } from "antd";
import "../../clients/client.css";
import BasicInfoView from "../../../components/client/BasicInfoView";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BasicInfoEdit from "../../../components/client/BasicInfoEdit";
import JobDetails from "./job-details/JobDetails";
import TermsEmployment from "./terms-of-employment/TermsEmployment";
import JobDetailsView from "./job-details/JobDetailsView";
import {
  fetchJobs,
  fetchSingleJob,
  setEditJobDescription,
  setEditJobDetails,
  setEditSkills,
  setEditTermsEmployment,
  setJobEditId,
  setViewSkills,
} from "../../../features/jobs/jobSlice";
import TermsEmploymentView from "./terms-of-employment/TermsEmploymentView";
import TextEditor from "./job-description/TextEditor";
import TextEditorView from "./job-description/TextEditorView";
import SkillStep from "./skills/SkillStep";
import RecruitmentProcess from "./recruitment-process/RecruitmentProcess";
import eclipse from "../../../assets/ellipse.svg";

const JobAdd = () => {
  const { token } = theme.useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    viewJobDetails,
    editJobDetails,
    viewTermsEmployment,
    editTermsEmployment,
    jobEditId,
    singleCreateJob,
    jobData,
    editJobDescription,
    viewJobDescription,
    viewModeTable,
    editSkills,
    viewSkills,
    singleJob,
    refetch,
  } = useSelector((state) => state.jobs);

  const { refetchKey } = useSelector((state) => state.cv);
  const { clientEditId } = useSelector((state) => state.client);
  const { user } = useSelector((state) => state.auth);

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "1px solid #147CBC",
  };

  const handleEditJobDescription = () => {
    dispatch(setEditJobDescription(true));
  };

  const handleEditJobDetails = () => {
    dispatch(setEditJobDetails(true));
  };

  const handleEditTermsEmployment = () => {
    dispatch(setEditTermsEmployment(true));
  };

  const handleEditSkills = () => {
    dispatch(setEditSkills(true));
    dispatch(setViewSkills(false));
  };
  const genExtraJobDescription = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={(event) => {
        event.stopPropagation();
        handleEditJobDescription();
      }}
    >
      <path
        d="M11 3.99632H4C3.46957 3.99632 2.96086 4.20703 2.58579 4.58211C2.21071 4.95718 2 5.46589 2 5.99632V19.9963C2 20.5268 2.21071 21.0355 2.58579 21.4105C2.96086 21.7856 3.46957 21.9963 4 21.9963H18C18.5304 21.9963 19.0391 21.7856 19.4142 21.4105C19.7893 21.0355 20 20.5268 20 19.9963V12.9963M18.5 2.49632C18.8978 2.0985 19.4374 1.875 20 1.875C20.5626 1.875 21.1022 2.0985 21.5 2.49632C21.8978 2.89415 22.1213 3.43371 22.1213 3.99632C22.1213 4.55893 21.8978 5.0985 21.5 5.49632L12 14.9963L8 15.9963L9 11.9963L18.5 2.49632Z"
        stroke="#101828"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  const genExtraJobDetails = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={(event) => {
        event.stopPropagation();
        handleEditJobDetails();
      }}
    >
      <path
        d="M11 3.99632H4C3.46957 3.99632 2.96086 4.20703 2.58579 4.58211C2.21071 4.95718 2 5.46589 2 5.99632V19.9963C2 20.5268 2.21071 21.0355 2.58579 21.4105C2.96086 21.7856 3.46957 21.9963 4 21.9963H18C18.5304 21.9963 19.0391 21.7856 19.4142 21.4105C19.7893 21.0355 20 20.5268 20 19.9963V12.9963M18.5 2.49632C18.8978 2.0985 19.4374 1.875 20 1.875C20.5626 1.875 21.1022 2.0985 21.5 2.49632C21.8978 2.89415 22.1213 3.43371 22.1213 3.99632C22.1213 4.55893 21.8978 5.0985 21.5 5.49632L12 14.9963L8 15.9963L9 11.9963L18.5 2.49632Z"
        stroke="#101828"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  const genExtraTermsEmployment = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={(event) => {
        event.stopPropagation();
        handleEditTermsEmployment();
      }}
    >
      <path
        d="M11 3.99632H4C3.46957 3.99632 2.96086 4.20703 2.58579 4.58211C2.21071 4.95718 2 5.46589 2 5.99632V19.9963C2 20.5268 2.21071 21.0355 2.58579 21.4105C2.96086 21.7856 3.46957 21.9963 4 21.9963H18C18.5304 21.9963 19.0391 21.7856 19.4142 21.4105C19.7893 21.0355 20 20.5268 20 19.9963V12.9963M18.5 2.49632C18.8978 2.0985 19.4374 1.875 20 1.875C20.5626 1.875 21.1022 2.0985 21.5 2.49632C21.8978 2.89415 22.1213 3.43371 22.1213 3.99632C22.1213 4.55893 21.8978 5.0985 21.5 5.49632L12 14.9963L8 15.9963L9 11.9963L18.5 2.49632Z"
        stroke="#101828"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const genExtraViewSkills = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={(event) => {
        event.stopPropagation();
        handleEditSkills();
      }}
    >
      <path
        d="M11 3.99632H4C3.46957 3.99632 2.96086 4.20703 2.58579 4.58211C2.21071 4.95718 2 5.46589 2 5.99632V19.9963C2 20.5268 2.21071 21.0355 2.58579 21.4105C2.96086 21.7856 3.46957 21.9963 4 21.9963H18C18.5304 21.9963 19.0391 21.7856 19.4142 21.4105C19.7893 21.0355 20 20.5268 20 19.9963V12.9963M18.5 2.49632C18.8978 2.0985 19.4374 1.875 20 1.875C20.5626 1.875 21.1022 2.0985 21.5 2.49632C21.8978 2.89415 22.1213 3.43371 22.1213 3.99632C22.1213 4.55893 21.8978 5.0985 21.5 5.49632L12 14.9963L8 15.9963L9 11.9963L18.5 2.49632Z"
        stroke="#101828"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <div className="flex items-center flex-row w-[100%] gap-2">
          {viewJobDescription ? (
            <img src={eclipse} alt="eclipse" className="mt-2" />
          ) : (
            <img src={eclipse} alt="eclipse" className="mt-2" />
          )}
          <div className="text-black21 text-[1rem] font-medium font-dmSans leading-tight">
            Job Description
          </div>
        </div>
      ),
      children: !viewJobDescription ? (
        <TextEditor />
      ) : editJobDescription ? (
        <TextEditor />
      ) : jobEditId || viewJobDescription ? (
        <TextEditorView />
      ) : null,
      extra:
        viewJobDescription &&
        (singleJob?.jobAdvertStatus === "ACTIVE" ||
          new Date(singleJob?.jobAdvertEndDate) < new Date())
          ? genExtraJobDescription()
          : "",
      //  extra: ((viewJobDescription || jobEditId) && (!viewModeTable))? genExtraJobDescription() : "",
      showArrow: viewJobDescription ? false : true,
      style: panelStyle,
    },

    {
      key: "2",
      label: (
        <div className="flex items-center flex-row w-[100%] gap-2">
          {viewJobDetails ? (
            <img src={eclipse} alt="eclipse" className="mt-2" />
          ) : (
            <img src={eclipse} alt="eclipse" className="mt-2" />
          )}

          <div className="text-black21 text-[1rem] font-medium font-dmSans leading-tight">
            Job Details
          </div>
        </div>
      ),

      children: !viewJobDetails ? (
        <JobDetails />
      ) : editJobDetails ? (
        <JobDetails />
      ) : viewJobDetails ? (
        <JobDetailsView />
      ) : null,
      extra:
        viewJobDetails &&
        (singleJob?.jobAdvertStatus === "ACTIVE" ||
          new Date(singleJob?.jobAdvertEndDate) < new Date())
          ? genExtraJobDetails()
          : "",
      showArrow: viewJobDetails ? false : true,
      style: panelStyle,
    },

    {
      key: "3",
      label: (
        <div className="flex flex-row w-[100%] gap-2">
          {viewTermsEmployment ? (
            <div className="flex items-center justify-center">
              <span>
                <img src={eclipse} alt="eclipse" className="mt-2" />
              </span>
            </div>
          ) : (
            <img src={eclipse} alt="eclipse" className="mt-2" />
          )}

          <div className="text-black21 text-[1rem] font-medium font-dmSans leading-tight">
            Terms of Employment
          </div>
        </div>
      ),

      children: !viewTermsEmployment ? (
        <TermsEmployment />
      ) : editTermsEmployment ? (
        <TermsEmployment />
      ) : viewTermsEmployment ? (
        <TermsEmploymentView />
      ) : null,
      extra:
        viewTermsEmployment &&
        (singleJob?.jobAdvertStatus === "ACTIVE" ||
          new Date(singleJob?.jobAdvertEndDate) < new Date())
          ? genExtraTermsEmployment()
          : "",
      //extra: viewTermsEmployment && !viewModeTable ? genExtraTermsEmployment() : "",
      showArrow: viewTermsEmployment ? false : true,
      style: panelStyle,
    },

    {
      key: "4",
      label: (
        <div className="flex flex-row w-[100%] gap-2">
          {viewSkills ? (
            <div className="flex items-center justify-center">
              <span>
                <img src={eclipse} alt="eclipse" className="mt-2" />
              </span>
            </div>
          ) : (
            <img src={eclipse} alt="eclipse" className="mt-2" />
          )}

          <div className="text-black21 text-[1rem] font-medium font-dmSans leading-tight">
            Skills
          </div>
        </div>
      ),

      children: !viewSkills ? (
        <SkillStep />
      ) : editSkills ? (
        <SkillStep />
      ) : jobEditId || viewSkills ? (
        <SkillStep />
      ) : null,
      extra:
        viewSkills &&
        (singleJob?.jobAdvertStatus === "ACTIVE" ||
          new Date(singleJob?.jobAdvertEndDate) < new Date())
          ? genExtraViewSkills()
          : "",
      showArrow: viewSkills ? false : true,
      style: panelStyle,
    },

    {
      key: "5",
      label: (
        <div className="flex flex-row w-[100%] gap-2">
          {viewJobDetails ? (
            <div className="flex items-center justify-center">
              <span>
                <img src={eclipse} alt="eclipse" className="mt-2" />
              </span>
            </div>
          ) : (
            <img src={eclipse} alt="eclipse" className="mt-2" />
          )}

          <div className="text-black21 text-[1rem] font-medium font-dmSans leading-tight">
            Recruitment Process
          </div>
        </div>
      ),

      children: <RecruitmentProcess singleJobItem={singleJob} />,
      showArrow: true,
      style: panelStyle,
    },
  ];

  async function fetchJobsData() {
    if (clientEditId) {
      dispatch(
        fetchJobs({
          jobInstId: user?.usrInstId,
          jobCntId: clientEditId,
        })
      );
    } else {
      dispatch(
        fetchSingleJob({
          jobId: jobEditId ? jobEditId : singleCreateJob?.jobId,
        })
      );
    }
  }

  useEffect(() => {
    if (refetchKey == 1) {
      fetchJobsData();
    }
  }, [refetch, refetchKey]);

  useEffect(() => {
    fetchJobsData();
  }, []);

  return (
    <Card className="px-[55px]">
      <div className="flex flex-col w-[100%]  ">
        {!jobEditId && (
          <div className="text-blackest text-[1rem] font-medium font-dmSans leading-[30px] mt-[25px] mb-[40px]">
            Fill in the job details below
          </div>
        )}

        <Collapse
          bordered={false}
          expandIconPosition="end"
          activeKey={["1", "2", "3", "4", "5"]}
          style={{
            background: token.colorBgContainer,
          }}
          items={getItems(panelStyle)}
        />
      </div>
    </Card>
  );
};

export default JobAdd;
