import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = { 
  attestationCandidatesLoading: false, 
  refetch: false,
  refetchKey:1, 

  refetchParent: false,
  refetchKeyParent:1, 


  jobApplicationAttestation: [],
  singleJobApplicationAttestation: [],
  attestationDocuments:[],
  attestationDocumentsLoading: false,
  stage:'awaitng',
  loading:false,
  attestationDocs:[],
  attestationOffices:[],
};
export const fetchJobApplicantAttestation = createAsyncThunk(
  "attestation/fetchJobApplicantAttestation",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobPlacements.action?japProgressStatus=${data?.japProgressStatus ?? ''}&japInstId=${data?.japInstId}&japStatus=${data?.japStatus ?? ''}&japRevise=${data?.japRevise ?? ''}&japAll=${data?.japAll ?? ''}`
    );
    return res.data?.data?.result;
  }
);

export const fetchJobApplicantAttestationById = createAsyncThunk(
  "attestation/fetchJobApplicantAttestationById",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobPlacements.action?japId=${data?.japId}`
    );
    return res.data?.data?.result;
  }
);

export const fetchAttestationDocuments = createAsyncThunk(
  "attestation/fetchAttestationDocuments",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/emp/fetchAttestationDocuments.action?attsdJapId=${data?.japId}`
    );
    return res.data?.data?.result;
  }
);

export const saveAttestationDocument = createAsyncThunk(
	'attestation/saveAttestationDocument',
	async (data) => {
		const res = await axiosInstance.post(
			`${url}/emp/saveAttestationDocument.action`,
			data
		);
		return res.data;
	}
);

export const saveForeignClearance = createAsyncThunk(
	'jobSlice/saveForeignClearance',
	async (data) => {
		const res = await axiosInstance.post(
			`${url}/nea/saveJobApplication.action`,
			data
		);
		return res.data;
	}
);

export const fetchAttestationDocSetups = createAsyncThunk(
	'dropDownSlice/fetchAttestationDocSetups',
	async () => {
		const res = await axios.get(
			`${url}/setup/fetchAttestationDocSetups.action`
		);
		return res.data.data.result;
	}
);

export const fetchAttestationOffices = createAsyncThunk(
	'dropDownSlice/fetchAttestationOffices',
	async () => {
		const res = await axios.get(`${url}/setup/fetchAttestationOffices.action`);
		return res.data.data.result;
	}
);

 

export const attestationSlice = createSlice({
  name: "attestation",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    }, 
    setRefetch:(state)=>{
      state.refetch = !state.refetch;
    },
    setRefetchKey: (state, action) => {
      state.refetchKey = action.payload;
    },

    setRefetchParent:(state)=>{
      state.refetchParent = !state.refetchParent;
    },
    setRefetchKeyParent: (state, action) => {
      state.refetchKeyParent = action.payload;
      state.refetchKey = initialState.refetchKey;
    },

    setStage: (state, action) => {
      state.stage = action.payload;
    },

    cleanAttestationTabs:(state) => {
      state.refetchKey = initialState.refetchKey
      state.refetchKeyParent = initialState.refetchKeyParent
      state.stage = initialState.stage;
    }
    
  },
  extraReducers: (builder) => {
    builder

    .addCase(fetchJobApplicantAttestation.pending, (state) => {
      state.attestationCandidatesLoading = true;
    })
    .addCase(fetchJobApplicantAttestation.fulfilled, (state, action) => {
      state.attestationCandidatesLoading = false;
      state.jobApplicationAttestation =  action?.payload
    })
    .addCase(fetchJobApplicantAttestation.rejected, (state) => {
      state.attestationCandidatesLoading = false;
      state.jobApplicationAttestation =  []
    })

    .addCase(fetchJobApplicantAttestationById.pending, (state) => {
      state.attestationCandidatesLoading = true;
    })
    .addCase(fetchJobApplicantAttestationById.fulfilled, (state, action) => {
      state.attestationCandidatesLoading = false;
      state.singleJobApplicationAttestation =  action?.payload?.[0]
    })
    .addCase(fetchJobApplicantAttestationById.rejected, (state) => {
      state.attestationCandidatesLoading = false;
      state.singleJobApplicationAttestation =  []
    })
    .addCase(fetchAttestationDocuments.pending, (state) => {
      state.attestationDocumentsLoading = true;
    })
    .addCase(fetchAttestationDocuments.fulfilled, (state, action) => {
      state.attestationDocumentsLoading = false;
      state.attestationDocuments =  action?.payload
    })
    .addCase(fetchAttestationDocuments.rejected, (state) => {
      state.attestationDocumentsLoading = false;
      state.attestationDocuments =  []
    })


    .addCase(saveAttestationDocument.pending, (state) => {
      state.attestationCandidatesLoading = true;
    })
    .addCase(saveAttestationDocument.fulfilled, (state, action) => {
      state.attestationCandidatesLoading = false; 
    })
    .addCase(saveAttestationDocument.rejected, (state) => {
      state.attestationCandidatesLoading = false; 
    })


    .addCase(saveForeignClearance.pending, (state) => {
      state.attestationCandidatesLoading = true;
    })
    .addCase(saveForeignClearance.fulfilled, (state, action) => {
      state.attestationCandidatesLoading = false; 
    })
    .addCase(saveForeignClearance.rejected, (state) => {
      state.attestationCandidatesLoading = false; 
    })


    .addCase(fetchAttestationDocSetups.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchAttestationDocSetups.fulfilled, (state, action) => {
      state.loading = false;
      state.attestationDocs =  action?.payload
    })
    .addCase(fetchAttestationDocSetups.rejected, (state) => {
      state.loading = false;
      state.attestationDocs =  []
    })


    .addCase(fetchAttestationOffices.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchAttestationOffices.fulfilled, (state, action) => {
      state.loading = false;
      state.attestationOffices =  action?.payload
    })
    .addCase(fetchAttestationOffices.rejected, (state) => {
      state.loading = false;
      state.attestationOffices =  []
    })
    
  },
});

export default attestationSlice.reducer;
export const {
  handleSidenavFullWidth, 
  setRefetchKey, 
  setRefetch,
  setRefetchKeyParent, 
  setRefetchParent,
  setStage,
  cleanAttestationTabs,
} = attestationSlice.actions;
