import { Collapse, Radio } from "antd";
import { useEffect, useState } from "react";
import mpesa from "../../../../assets/mpesa.png";
import ndovulogo from "../../../../assets/ndovulogo.png";
import cardslogo from "../../../../assets/cards-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentMethods } from "../../../../features/payment/paySlice";

export default function PaymentInfo({ handlePayOption }) {
  const dispatch = useDispatch();

  const { payMethods, payLoading } = useSelector((state) => state.pay);

  const [value, setValue] = useState(1);

  function onPaymentOptionChange(val) {
    let currentVal = Number(val.toString());
    setValue(currentVal);
    handlePayOption(currentVal);
  }

  async function handleFetchPaymentMethods() {
    await dispatch(fetchPaymentMethods());
  }

  const items = payLoading
    ? {
        key: "1",
        label: (
          <div
            style={{
              boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
            }}
            className="flex justify-between items-center w-full p-3 mb-3 h-[80px]"
          >
            <div className="flex items-center">
              <Radio value={1}></Radio>
              <span className="license-pay-text">Loading...</span>
            </div>
          </div>
        ),
        showArrow: false,
      }
    : payMethods?.map((item) => {
        return {
          key: `${item?.payId}`,
          label: (
            <div
              style={{
                boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
              }}
              className="flex justify-between items-center w-full p-3 mb-3 h-[80px]"
            >
              <div className="flex items-center">
                <Radio value={item?.payId}></Radio>
                <span className="license-pay-text">
                  {item?.payType} PAYMENT
                </span>
              </div>

              <img
                src={item?.payLogo}
                className="object-contain w-[131px] h-[61px]"
                alt={item?.payType}
              />
            </div>
          ),
          children: <p>Panel 1</p>,
          showArrow: false,
        };
      });

  useEffect(() => {
    handleFetchPaymentMethods();
  }, []);

  return (
    <>
      <h3 className="license-header mb-3">Choose payment method</h3>
      <p className="license-subtext">
        Select your preferred payment method below
      </p>

      <Radio.Group className="w-full" value={value}>
        <div className="mt-10 w-full flex flex-col">
          <Collapse
            className="pay-collapse"
            onChange={onPaymentOptionChange}
            bordered={false}
            accordion
            items={items}
          />
        </div>
      </Radio.Group>
    </>
  );
}
