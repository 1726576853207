import React, { useEffect, useState } from "react";
import backArrow from "../../../assets/Back.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, theme } from "antd";
import { formatImgPath } from "../../../utils";
import EmployeeDetailsView from "./EmployeeDetailsView";
import JobDetailsView from "./JobDetailsView";
import ContractDetailsView from "./ContractDetailsView";
import { fetchSinglePlacements } from "../../../features/myEmployees/myEmployeesSlice";

function PlacementView() {
  const { token } = theme.useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleBack = async () => {
    await navigate(-1);
  };

  const {
    viewBAsic,
    viewJob,
    viewContract,
    employeeEditId,
    singlePlacement,
    isLocal,
    employeePlacementObj
  } = useSelector((state) => state.employee);

  const { user } = useSelector((state) => state.auth);
   
  const [receivedEditId, setReceivedEditId] = useState(employeePlacementObj?.usrId);

  useEffect(() => {
    setReceivedEditId(employeePlacementObj?.usrId);
  }, [employeePlacementObj?.usrId]);

  useEffect(() => {
    dispatch(
      fetchSinglePlacements({
        jabIsLocal: isLocal,
        japInstId: user?.usrInstId,
        japUsrId: receivedEditId,
        japStatus:employeePlacementObj?.japStatus
      })
    );
  }, []);

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "1px solid #147CBC",
  };

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <div className="flex flex-row w-[100%] gap-2">
          {viewBAsic ? (
            <div className="h-[25px] w-[25px] rounded-full bg-blueDark flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_7908_42090)">
                  <path
                    d="M5.23238 9.4167L2.79052 6.97484L1.97656 7.78879L5.23238 11.0446L12.2091 4.06786L11.3952 3.25391L5.23238 9.4167Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7908_42090">
                    <rect width="13.9535" height="13.9535" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <circle
                cx="12.5"
                cy="12.5"
                r="12"
                fill="white"
                stroke="#147CBC"
              />
              <circle cx="12.7904" cy="12.7943" r="2.32558" fill="#147CBC" />
            </svg>
          )}

          <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
            Basic Information
          </div>
        </div>
      ),
      children: <EmployeeDetailsView />,
      extra: "",
      showArrow: true,
      style: panelStyle,
    },

    {
      key: "2",
      label: (
        <div className="flex flex-row w-[100%] gap-2">
          {viewJob ? (
            <div className="h-[25px] w-[25px] rounded-full bg-blueDark flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_7908_42090)">
                  <path
                    d="M5.23238 9.4167L2.79052 6.97484L1.97656 7.78879L5.23238 11.0446L12.2091 4.06786L11.3952 3.25391L5.23238 9.4167Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7908_42090">
                    <rect width="13.9535" height="13.9535" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <circle
                cx="12.5"
                cy="12.5"
                r="12"
                fill="white"
                stroke="#147CBC"
              />
              <circle cx="12.7904" cy="12.7943" r="2.32558" fill="#147CBC" />
            </svg>
          )}

          <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
            Job details
          </div>
        </div>
      ),
      children: <JobDetailsView />,
      extra: "",
      showArrow: true,
      style: panelStyle,
    },

    {
      key: "3",
      label: (
        <div className="flex flex-row w-[100%] gap-2">
          {viewContract ? (
            <div className="h-[25px] w-[25px] rounded-full bg-blueDark flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_7908_42090)">
                  <path
                    d="M5.23238 9.4167L2.79052 6.97484L1.97656 7.78879L5.23238 11.0446L12.2091 4.06786L11.3952 3.25391L5.23238 9.4167Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7908_42090">
                    <rect width="13.9535" height="13.9535" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <circle
                cx="12.5"
                cy="12.5"
                r="12"
                fill="white"
                stroke="#147CBC"
              />
              <circle cx="12.7904" cy="12.7943" r="2.32558" fill="#147CBC" />
            </svg>
          )}

          <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
            Contract details
          </div>
        </div>
      ),
      children: <ContractDetailsView />,
      extra: "",
      showArrow: true,
      style: panelStyle,
    },
  ];

  return (
    <>
      <div className="bg-white mr-3 mb-[10px] flex items-center justify-between h-[115px] px-[39px]">
        <div className="items-center flex text-center gap-x-5">
          <img
            className="h-[61px] w-[61px] rounded-full"
            src={
              singlePlacement
                ? formatImgPath(singlePlacement?.usrProfileImage)
                : "https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png"
            }
            alt="employee-profile"
          />
          <span className="text-black1 text-2xl font-bold font-dmSans leading-[54px]">
            {singlePlacement?.usrFirstName} {singlePlacement?.usrLastName}
          </span>
        </div>

        <div className="flex-end items-center flex gap-[5rem]">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={handleBack}
          >
            <div
              style={{
                height: "35px",
                width: "35px",
                background: "#EDF8FF",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={backArrow}
                alt="back-arrow"
                style={{ height: "40px", width: "40px" }}
              />
            </div>

            <span className="text-black2 text-[22px] font-bold font-dmSans leading-relaxed">
              Go back
            </span>
          </div>
        </div>
      </div>

      <div className="bg-white mr-3 mb-[10px] flex items-center justify-between h-auto px-[39px]">
        <div className="flex flex-col w-[100%]">
          <div className="text-black text-[22px] font-bold font-dmSans leading-[33px] py-[51px]">
            Fill in the following details
          </div>
          <Collapse
            bordered={false}
            expandIconPosition="end"
            defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
            style={{
              background: "#fff",
            }}
            items={getItems(panelStyle)}
          />
        </div>
      </div>
    </>
  );
}

export default PlacementView;
