import { useSelector } from 'react-redux';
import { formatImgPath } from '../../../utils';

export default function EmployerDescription() {
	const {singleDistress} = useSelector((state)=>state.runaway)

	return (
		<>
			<div className='bg-white px-[.3rem] lg:px-[2.5rem] py-[2rem] w-full'>
				<div className='flex justify-between items-center'>
					<span className='heading_heading_4'>Employer </span>
				</div>

				<div
					className='mt-[1.5rem]'
					style={{
						width: '100%',
						height: '0.0625rem',
						background: 'rgba(216, 216, 216, 0.54)',
					}}></div>

				<div className='mt-[2rem] grid grid-cols-1 gap-[1.5rem]'>
					<div className='flex items-center gap-[1.28rem]'>
						{singleDistress?.jobCntIcon ? (
							<img
							style={{
								boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.12)',
							}}
							loading='lazy'
							src={formatImgPath(singleDistress?.jobCntIcon)}
							alt='profile'
							className='w-[4.157rem] h-[4.157rem] rounded-full object-cover'
						/>
						) : (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='60'
								height='60'
								viewBox='0 0 60 60'
								fill='none'>
								<mask
									id='mask0_10956_7844'
									style={{ maskType: 'luminance' }}
									maskUnits='userSpaceOnUse'
									x='0'
									y='0'
									width='60'
									height='60'>
									<path
										d='M59.6042 0.296875H0.198242V59.7028H59.6042V0.296875Z'
										fill='white'
									/>
								</mask>
								<g mask='url(#mask0_10956_7844)'>
									<mask
										id='mask1_10956_7844'
										style={{ maskType: 'luminance' }}
										maskUnits='userSpaceOnUse'
										x='0'
										y='1'
										width='59'
										height='58'>
										<path
											d='M58.4639 1.02148H0.506836V58.9785H58.4639V1.02148Z'
											fill='white'
										/>
									</mask>
									<g mask='url(#mask1_10956_7844)'>
										<path
											fill-rule='evenodd'
											clip-rule='evenodd'
											d='M37.5352 17.9526C35.1524 16.3605 32.3511 15.5107 29.4854 15.5107V1.02148C35.2168 1.02148 40.8195 2.72105 45.585 5.90525C50.3505 9.08946 54.0647 13.6152 56.2581 18.9103C58.4513 24.2054 59.0252 30.0321 57.9071 35.6534C56.789 41.2747 54.029 46.4382 49.9762 50.4908C45.9236 54.5436 40.7601 57.3036 35.1388 58.4217C29.5175 59.5398 23.6908 58.9659 18.3957 56.7726C13.1006 54.5793 8.57481 50.8651 5.3906 46.0996C2.2064 41.3341 0.506836 35.7314 0.506836 30H14.9961C14.9961 32.8657 15.8459 35.667 17.438 38.0498C19.0301 40.4326 21.293 42.2897 23.9406 43.3864C26.5881 44.483 29.5015 44.7699 32.3121 44.2108C35.1227 43.6518 37.7044 42.2718 39.7309 40.2455C41.7572 38.219 43.1372 35.6373 43.6961 32.8267C44.2553 30.0161 43.9684 27.1027 42.8717 24.4552C41.775 21.8076 39.9179 19.5447 37.5352 17.9526Z'
											fill='#007DFC'
										/>
										<path
											fill-rule='evenodd'
											clip-rule='evenodd'
											d='M14.9961 1.02149C14.9961 2.92424 14.6213 4.80837 13.8932 6.56626C13.165 8.3242 12.0978 9.9215 10.7523 11.2669C9.40685 12.6124 7.80954 13.6796 6.05166 14.4078C4.29371 15.1359 2.40958 15.5107 0.506837 15.5107L0.506836 30C4.31234 30 8.08061 29.2505 11.5964 27.7941C15.1123 26.3379 18.3069 24.2033 20.9977 21.5123C23.6887 18.8215 25.8232 15.627 27.2795 12.1111C28.7359 8.59527 29.4854 4.82699 29.4854 1.02148L14.9961 1.02149Z'
											fill='#007DFC'
										/>
									</g>
								</g>
							</svg>
						)}
						<span className='label_label_1'>{singleDistress?.jobCntName ?? 'N/A'}</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Name</span>
						<span className='paragraph_paragraph_1 mt-[0.3rem] truncate'>
							{singleDistress?.jobCntName ?? 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Website</span>

						<span className='paragraph_paragraph_1 mt-[0.3rem] truncate'>
							{singleDistress?.cntWebsite ?? 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Industry</span>

						<span className='paragraph_paragraph_1 mt-[0.3rem] truncate'>
							{singleDistress?.cntIndustry ?? 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Location</span>

						<span className='paragraph_paragraph_1 mt-[0.3rem] truncate'>
						{singleDistress?.cntLocation ?? 'N/A'}, {singleDistress?.cntCountry ?? 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Address</span>

						<span className='paragraph_paragraph_1 mt-[0.3rem] truncate'>
							{singleDistress?.cntAddress ?? 'N/A'}
						</span>
					</div>
				</div>
			</div>
		</>
	);
}
