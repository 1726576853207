import { Form, Input, Radio, Select, Spin, Tag } from 'antd';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';

export default function WriteMessage({ handleCurrentView, loading }) {
	function handleBack() {
		handleCurrentView(0);
	}
	var modules = {
		toolbar: [
			[{ size: ['small', false, 'large', 'huge'] }],
			['bold', 'italic', 'underline', 'strike', 'blockquote'],
			[{ list: 'ordered' }, { list: 'bullet' }],
			['link', 'image'],
			[
				{ list: 'ordered' },
				{ list: 'bullet' },
				{ indent: '-1' },
				{ indent: '+1' },
				{ align: [] },
			],
			[
				{
					color: [
						'#000000',
						'#e60000',
						'#ff9900',
						'#ffff00',
						'#008a00',
						'#0066cc',
						'#9933ff',
						'#ffffff',
						'#facccc',
						'#ffebcc',
						'#ffffcc',
						'#cce8cc',
						'#cce0f5',
						'#ebd6ff',
						'#bbbbbb',
						'#f06666',
						'#ffc266',
						'#ffff66',
						'#66b966',
						'#66a3e0',
						'#c285ff',
						'#888888',
						'#a10000',
						'#b26b00',
						'#b2b200',
						'#006100',
						'#0047b2',
						'#6b24b2',
						'#444444',
						'#5c0000',
						'#663d00',
						'#666600',
						'#003700',
						'#002966',
						'#3d1466',
						'custom-color',
					],
				},
			],
		],
	};

	var formats = [
		'header',
		'height',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'color',
		'bullet',
		'indent',
		'link',
		'image',
		'align',
		'size',
	];

	return (
		<>
			<div className='flex justify-center flex-col items-center'>
				<div className='w-full'>
					<Form.Item
						className='w-full'
						name='inbSubject'
						label='Subject'
						rules={[
							{
								required: true,
								message: 'Subject of message is required',
							},
						]}
					>
						<Input className='input' />
					</Form.Item>
					<Form.Item
						className='w-full'
						name='inbBody'
						label='Body'
						rules={[
							{
								required: true,
								message: 'Please add body of message',
							},
						]}
					>
						<ReactQuill
							modules={modules}
							formats={formats}
							theme='snow'
							style={{ height: '220px', marginBottom: '5%' }}
						/>
					</Form.Item>
				</div>

				<div className='flex items-center justify-end mt-10 w-full mb-20'>
					<button
						disabled={loading}
						onClick={handleBack}
						className='border-2 border-blueDark rounded-[35px] text-blueDark w-[300px] p-3'
						type='button'
					>
						back
					</button>

					<button
						disabled={loading}
						className='bg-blueDark rounded-[35px] text-white w-[300px] p-3 ml-3'
						type='submit'
					>
						{loading ? <Spin /> : 'Send'}
					</button>
				</div>
			</div>
		</>
	);
}
