import { Table } from 'antd';
import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchKinContacts } from '../../../../../features/person/personSllice';


export default function NextOfKinForm({ userId }) {
	const dispatch = useDispatch();

	const { prsLoading, kins } = useSelector((state) => state.prs);

	const usrObj = {
		usrId: userId,
	};

	const defaultColumnsViewOnly = [
		{
			title: 'Full Name',
			dataIndex: 'kinName',
			key: 'kinName',
			editable: true,
		},
		{
			title: 'National Id',
			key: 'kinNationalId',
			dataIndex: 'kinNationalId',
			editable: true,
		},

		{
			title: 'Phone Number',
			dataIndex: 'kinMobileNumber',
			key: 'kinMobileNumber',
			editable: true,
		},
		{
			title: 'Relationship',
			dataIndex: 'kinRelationship',
			key: 'kinRelationship',
			editable: true,
		},
	];

	const columns = defaultColumnsViewOnly

	async function handleFetchKins() {
		await dispatch(fetchKinContacts(usrObj));
	}

	useEffect(() => {
		handleFetchKins();
	}, [userId]);

	useEffect(() => {
	}, [kins]);

	return (
		<>
			<div className='dash-inner-page mt-3 max-w-full w-full overflow-x-auto'>
				<section>
					<Table
						loading={prsLoading}
						className="no-header-bg-table no-hover"
						size={'middle'}
						bordered={false}
						columns={columns}
						dataSource={kins}
						scroll={{
							x: 1000,
						}}
						pagination={{
							defaultPageSize: 5,
							hideOnSinglePage: true,
							pageSizeOptions: [10, 20, 50, 100],
						}}
					/>
				</section>
			</div>
		</>
	);
}
