import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Modal, Select, Spin, Tag } from "antd";
import "../../../../clients/client.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { saveBranchAgency } from "../../../../../features/agency/agencySlice";
import PhoneInput from "react-phone-input-2";
import { SubCountyData } from "../../../../../SubCountyData";

const { TextArea } = Input;

const AddBranchModal = ({ open, handleCancel, handleFetchListData, prodd }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
 

  const { agencyObj, agnLoading } = useSelector((state) => state.agency);
  const { user, authLoading } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({ ...prodd });
  const [subCounty, setsubCounty] = useState([]);
  const [defaultValue, setdefaultValue] = useState(prodd?.ibrnRegion ? prodd?.ibrnRegion : "");
 

  function selectedCounty(value, formName) {
    const subCountyArray = SubCountyData?.find((city) => city?.name === value);
    setsubCounty(subCountyArray?.sub_counties);
    setdefaultValue(subCountyArray?.sub_counties?.[0]);
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  function selectedSubCounty(value, formName) {
    setdefaultValue(value);
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  const onFinish = async (data) => {
    if (!formData?.ibrnCounty) {
      toast.error("Please select a county");
      return;
    }
    if (!formData?.ibrnRegion) {
      toast.error("Please select sub-county");
      return;
    }
    if (!formData?.ibrnCellPhone) {
      toast.error("Please provide phone number");
      return;
    }
    
    const res = await dispatch(
      saveBranchAgency({
        ibrnId : prodd?.ibrnId ?  prodd?.ibrnId : null,
        ibrnInstId: user?.usrInstId,
        ibrnName: formData?.ibrnName,
        ibrnCounty: formData?.ibrnCounty,
        ibrnRegion: formData?.ibrnRegion,
        ibrnPhysicalAddress: formData?.ibrnPhysicalAddress,
        ibrnEmail: formData?.ibrnEmail,
        ibrnCellPhone: formData?.ibrnCellPhone,
      })
    );

    if (res?.payload?.success) {
      await handleFetchListData();
      await handleCancel();
      await toast.success("Branch Created successful");
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleNumberChangeAdminTel = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      ibrnCellPhone: String(e),
    }));
  };

  useEffect(() => {
    setFormData(prodd);
  }, [prodd]);

  useEffect(()=>{
    setdefaultValue(prodd?.ibrnRegion ? prodd?.ibrnRegion : "")
  },[prodd?.ibrnRegion ? prodd?.ibrnRegion : ""])

 
  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title="Add Branch"
        open={open}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <Form.Item
            label="Name of Branch"
            rules={[
              {
                required: true,
                message: "Field is required",
              },
            ]}
          >
            <Input
            required
              name="ibrnName"
              onChange={onChange}
              value={formData?.ibrnName}
              className="input"
            />
          </Form.Item>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Required field",
                },
              ]}
              label="Choose county of operation"
            >
              <Select
                required
                onChange={(value) => {
                  selectedCounty(value, "ibrnCounty");
                }}
                // onChange={selectedCounty}
                name="ibrnCounty"
                value={formData?.ibrnCounty}
                style={{
                  height: "52px",
                }}
                className=""
                placeholder="County"
                size="large"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.children?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
              >
                {SubCountyData?.map((city) => {
                  return (
                    <Select.Option key={city?.name} value={city?.name}>
                      {city?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Choose sub county of operation"
              rules={[
                {
                  required: true,
                  message: "Required field",
                },
              ]}
            >
              <Select
                name="ibrnRegion"
                style={{
                  height: "52px",
                }}
                // name="sub_county"
                onChange={(value) => {
                  selectedSubCounty(value, "ibrnRegion");
                }}
                // onChange={selectedSubCounty}
                value={defaultValue}
                disabled={subCounty?.length ? false : true}
                placeholder={"Sub-county"}
                size="large"
                className=""
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.children?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
              >
                {subCounty?.map((city) => {
                  return (
                    <Select.Option required key={city} value={city}>
                      {city}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>

          <Form.Item
            label="Physical Address"
            rules={[
              {
                required: true,
                message: "Field is required",
              },
            ]}
          >
            <Input
              required
              name="ibrnPhysicalAddress"
              onChange={onChange}
              value={formData?.ibrnPhysicalAddress}
              className="input"
            />
          </Form.Item>
          <Form.Item
            label="Email Address"
            rules={[
              {
                required: true,
                message: "Field is required",
              },
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input
              required
              name="ibrnEmail"
              onChange={onChange}
              value={formData?.ibrnEmail}
              className="input"
              type="email"
            />
          </Form.Item>
          <Form.Item
            label="Phone No."
            rules={[
              {
                required: true,
                message: "Field is required",
              },
            ]}
          >
            <PhoneInput
              required
              name="ibrnCellPhone"
              onChange={handleNumberChangeAdminTel}
              value={formData?.ibrnCellPhone || ""} 
              country="ke"
              onlyCountries={["ke"]}
              countryCodeEditable={false}
              className="input" 
            />
          </Form.Item>

          <div className="flex justify-between mt-10">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center gap-x-5">
              <button
                disabled={agnLoading}
                type="button"
                onClick={handleCancel}
                className="w-[200px] cancel-btn border border-blueDark flex items-center justify-center"
              >
                Cancel
              </button>

              <button
                key="submit"
                type="submit"
                className="w-[200px] save-btn"
                disabled={agnLoading}
              >
                {agnLoading ? <Spin /> : "Save"}
              </button>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default AddBranchModal;
