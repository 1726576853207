import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dragger from "antd/es/upload/Dragger";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Button, Card, Form, Spin, message } from "antd";
import upload from "../../../../assets/image 145.png";
import {
  fetchApplicantCvs,
  saveApplicantCv,
  setcvEditMode,
} from "../../../../features/cv/cvSlice";
import { formatImgPath } from "../../../../utils";
import { fetchJobApplications } from "../../../../features/applicantJob/applicantJobSlice";
import CoverLetter from "../CoverLetter";

const url = process.env.REACT_APP_API_BASE_URL;
function CvFile() {
  const [data, setdata] = useState({});
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { user } = useSelector((state) => state.auth);
  const { singleCreateApplicant, applicantLoading, applicantEditId } =
    useSelector((state) => state.applicant);
  const { cvsData, cvLoading, cvEditMode, refetch, cvViewMode, refetchKey } =
    useSelector((state) => state.cv);
  const { jobEditId } = useSelector((state) => state.jobs);
  const { applicantJobApplication } = useSelector(
    (state) => state.applicantJob
  );

  const [loading, setloading] = useState(true);

  const [applicantJobApplicationList, setApplicantJobApplicationList] =
    useState(applicantJobApplication[0]);

  useEffect(() => {
    setApplicantJobApplicationList(applicantJobApplication[0]);
  }, [applicantJobApplication[0]]);

  const [cvList, setCvList] = useState(cvsData);
  useEffect(() => {
    setCvList(cvsData);
  }, [cvsData]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fileUrl, setfileUrl] = useState("");
  const [fileName, setfileName] = useState("");
  const [fileLoading, setFileLoading] = useState(false);

  const props = {
    name: "file",
    multiple: false,
    accept:
      "application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    action: `${url}/nea/saveFile.action`,
    onChange(info) {
      setFileLoading(true);
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        setFileLoading(false);
        message.success(`${info.file.name} file attached successfully.`);
        if (info?.file?.response?.success) {
          setfileUrl(info?.file?.response?.jsonData);
          setfileName(info?.file?.name);
        } else {
          setfileUrl("");
          setfileName("");
        }
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      setFileLoading(false);
    },
  };

  const onFinish = async (data) => {
    const res = await dispatch(
      saveApplicantCv({
        cvUrl: fileUrl,
        cvFile: fileUrl,
        cvUsrId: singleCreateApplicant?.usrId
          ? singleCreateApplicant?.usrId
          : applicantEditId,
        cvId: cvList?.cvId ? cvList?.cvId : null,
      })
    );

    if (res?.payload?.cvId) {
      toast.success("CV uploaded successfully");
      setfileUrl("");
      fetchCv();
      dispatch(setcvEditMode(false));
      // dispatch(setcvViewMode(true));
    } else {
      toast.error("Error occured uploading cv, please try again");
    }
  };

  async function fetchCv() {
    await dispatch(
      fetchApplicantCvs({
        cvUsrId: singleCreateApplicant?.usrId
          ? singleCreateApplicant?.usrId
          : applicantEditId,
      })
    );
  }

  const [receivedId, setReceivedId] = useState(jobEditId);
  useEffect(() => {
    setReceivedId(jobEditId);
  }, [jobEditId]);

  const [applicantCvEditId, setApplicantCvEditId] = useState(applicantEditId);
  useEffect(() => {
    setApplicantCvEditId(applicantEditId);
  }, [applicantEditId]);

  async function fetchCandidateJobApplications() {
    const res = await dispatch(
      fetchJobApplications({
        japUsrId: applicantCvEditId,
      })
    );
  }

  // applicantEditId,  singleCreateApplicant,

  useEffect(() => {
    if (fileUrl) {
      onFinish();
    }
  }, [fileUrl]);

  useEffect(() => {
    if (refetchKey == 2) {
      if (jobEditId) {
        fetchCandidateJobApplications();
      } else {
        fetchCv();
      }
    }
  }, [refetch, refetchKey, jobEditId]);

  useEffect(() => {}, [cvList]);

  const { singleJap } = useSelector((state) => state.candidate);

  return (
    <>
      {jobEditId ? (
        <>
          <div className="mb-[30px]">
            <p className="con-title mb-4">1. Cv</p>
            {singleJap?.japCvFile ? (
              <div>
                <iframe
                  onLoad={() => setloading(false)}
                  title="Cv File"
                  width={"100%"}
                  height={"700px"}
                  src={formatImgPath(singleJap?.japCvFile)}
                ></iframe>

                {loading ? (
                  <>
                    <button
                      className="bg-blueDark text-white w-[300px] p-3 flex justify-center mt-10"
                      disabled
                      type="button"
                    >
                      <Spin />
                      <span className="ml-2">
                        Loading cv file... Please wait
                      </span>
                    </button>
                  </>
                ) : null}
              </div>
            ) : (
              <p className="dash-title">No CV available</p>
            )}
          </div>

          <div className="">
            <p className="con-title mb-4">2. Cover Letter</p>
            {singleJap?.japCvFile ? (
              <CoverLetter />
            ) : (
              <p className="dash-title">No Cover letter available</p>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="mr-2 h-[700px]  bg-white flex flex-col">
            <div className="flex justify-between items-center">
              <div className="text-black text-[22px] font-bold font-dmSans leading-[33px] px-[63px] py-[60px] flex-start">
                CV Details
              </div>

              {cvEditMode ? (
                <Button
                  className=" mr-5  !text-white bg-blueDark text-[1rem] font-medium font-dmSans leading-normal border-none
                     w-[168px] h-12 px-[18px] py-3  cursor-pointer  justify-center items-center gap-2 inline-flex"
                  onClick={() => dispatch(setcvEditMode(false))}
                >
                  Cancel
                </Button>
              ) : (
                <>
                  {cvList &&
                  typeof cvList === "object" &&
                  Object.keys(cvList)?.length > 0 &&
                  !jobEditId ? (
                    <div className="mr-5">
                      <button
                        className="text-blue-50 text-[19px] font-medium font-dmSans leading-normal 
                          w-[211px] h-14 px-7 py-4 bg-blueDark rounded-[56px] shadow justify-center items-center gap-2.5 inline-flex
                          relative"
                        onClick={() => {
                          dispatch(setcvEditMode(true));
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="#fff"
                        >
                          <path
                            d="M11 3.99632H4C3.46957 3.99632 2.96086 4.20703 2.58579 4.58211C2.21071 4.95718 2 5.46589 2 5.99632V19.9963C2 20.5268 2.21071 21.0355 2.58579 21.4105C2.96086 21.7856 3.46957 21.9963 4 21.9963H18C18.5304 21.9963 19.0391 21.7856 19.4142 21.4105C19.7893 21.0355 20 20.5268 20 19.9963V12.9963M18.5 2.49632C18.8978 2.0985 19.4374 1.875 20 1.875C20.5626 1.875 21.1022 2.0985 21.5 2.49632C21.8978 2.89415 22.1213 3.43371 22.1213 3.99632C22.1213 4.55893 21.8978 5.0985 21.5 5.49632L12 14.9963L8 15.9963L9 11.9963L18.5 2.49632Z"
                            stroke="#101828"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Update CV
                      </button>
                    </div>
                  ) : null}
                </>
              )}
            </div>

            <div className="flex flex-col text-center items-center justify-center">
              {cvList &&
              typeof cvList === "object" &&
              Object.keys(cvList)?.length > 0 &&
              !cvEditMode ? (
                <>
                  <iframe
                    src={formatImgPath(cvList?.cvFile)}
                    style={{
                      width: "100%",
                      minHeight: "100vh",
                      backgroundColor: "#FAFDFF",
                    }}
                    title="CV Preview"
                  />
                </>
              ) : (
                <>
                  <div className="w-[267.40px] text-[#BEBEBE] text-[22px] font-normal font-dmSans leading-[33px] mb-0">
                    {cvEditMode ? "Upload new cv" : "No CV uploaded yet"}
                  </div>

                  <Form
                    layout="vertical"
                    ref={formRef}
                    name="control-ref"
                    onFinish={onFinish}
                    className=""
                    style={{
                      maxWidth: "100%",
                    }}
                    form={form}
                  >
                    <div className="grid grid-cols-1">
                      <Form.Item
                        name="attFile"
                        rules={[
                          {
                            required: true,
                            message: "Please upload a file",
                          },
                        ]}
                      >
                        <Dragger className="attachment" {...props}>
                          <img src={upload} alt="picture" />
                        </Dragger>
                      </Form.Item>
                    </div>
                  </Form>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CvFile;
