import React, { useState } from "react";
import { Card, Form, Modal } from "antd";
import "./client.css";
import group from "../../assets/Group.svg";
import upload from "../../assets/upload csv.svg";
import ClientNameModal from "./ClientNameModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const url = process.env.REACT_APP_API_BASE_URL;
const ClientAddModal = ({ isModalOpen, setIsModalOpen, showModal }) => {
  const [form] = Form.useForm();
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();
  const { agencyObj } = useSelector((state) => state.agency);

  const [isModalOpenName, setIsModalOpenName] = useState(false);

  const showModalName = () => {
    setIsModalOpenName(true);
    handleOk();
  };

  const { clientLoading } = useSelector((state) => state.client);
  // const [clientLoading] = useState(true)

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        className="mod mod-height"
        title={`Add ${agencyObj?.licenseName == "LOCAL LICENSE" ? "local" : "foreign"} employers`}
        open={isModalOpen}
        onCancel={handleCancel}
        width={850}
      >
        <div className="grid grid-cols-2 gap-5 p-[41px]">
          <Card className="p-[70px cursor-pointer" onClick={showModalName}>
            <div className="text-center justify-center flex flex-col ">
              <img
                src={group}
                alt="group"
                className="mx-auto w-[169.002px] h-[161.471px]"
              />

              <span className="text-black text-[20px] font-[500] font-dmSans leading-loose mt-[32px]">
                {" "}
                Complete a Form{" "}
              </span>
            </div>
          </Card>
          <Card
            className="p-[70px cursor-pointer"
            // onClick={handleUploadEmployees}
            onClick={() => navigate("/client-upload")}
          >
            <div className="text-center justify-center flex flex-col ">
              <img
                src={upload}
                alt="upload"
                className="mx-auto w-[169.002px] h-[161.471px]"
              />

              <span className="text-black text-[20px] font-[500] font-dmSans leading-loose mt-[32px]">
                {" "}
                Import excel file{" "}
              </span>
            </div>
          </Card>
        </div>
      </Modal>
      <ClientNameModal
        isModalOpenName={isModalOpenName}
        setIsModalOpenName={setIsModalOpenName}
        showModalName={showModalName}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};
export default ClientAddModal;
