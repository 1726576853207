import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Form, Input, Modal, Select, Spin, message } from "antd";
import "../../clients/demand-modal/demand-modal.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  fetchApprovedDemandLetters,
  fetchDemandLetters,
} from "../../../features/demand/demandLetterSlice";
import {
  fetchJobs,
  fetchJobsByDemandLetter,
  saveJob,
  setRefetchKey,
} from "../../../features/jobs/jobSlice";
import { fetchClients } from "../../../features/client/clientSlice";
import { fetchApprovedDemandLetterJobs } from "../../../features/demandJob/demandJobSlice";
import { checkEditPermission } from "../../../utils";

const AddJobModal = ({ isModalOpen, setIsModalOpen }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { demandLetters, approvedDemandLetters } = useSelector(
    (state) => state.letter
  );
  const { clientLoading, clientData, clientEditId, refetchKey, refetch } =
    useSelector((state) => state.client);

  const { jobDemandLetterData, jobLoading, jobLoadingLetter } = useSelector(
    (state) => state.jobs
  );
  const { agencyObj } = useSelector((state) => state.agency);
  const { menuSidebarData } = useSelector((state) => state.admin);

  const [clientDataList, setClientDataList] = useState(clientData);
  const [receivedId, setReceivedId] = useState(clientEditId);
  const [demandLetterList, setDemandLetterList] = useState(demandLetters);
  const [approvedDemandLeterList, setApprovedDemandLetterList] = useState([]);


  const [jobClientName, setJobClientName] = useState();
  const [jobClient, setJobClient] = useState();

  function closeModal() {
    toast.dismiss();
  }
  const [data, setdata] = useState({});

  function handleSelectChange(value) {
    setdata((prevData) => ({
      ...prevData,
      jobDmlId: value,
    }));
  }
  function handleSelectChangeJobTitle(value) {
    setdata((prevData) => ({
      ...prevData,
      jobId: value,
    }));
  }

  function handleSelectChangeJob(value) {
    setdata((prevData) => ({
      ...prevData,
      objectName: value,
    }));
  }

  const [dmlIdNumber, setDmlIdNumber] = useState();

  const onFinish = async (data) => {
    const res = await dispatch(
      saveJob({
        // jobCntId: jobClient,
        //jobDmlId: data?.jobDmlId,
        jobId: data?.jobId,
        jobInstId: user?.usrInstId,
        jobCreatedBy: user?.usrId,
        jobStatus: "ACTIVE",
      })
    );
    if (res?.payload?.success) {
      toast.success("Job added successfully");
      await dispatch(
        fetchJobs({
          jobInstId: user?.usrInstId,
          jobCntId: "",
          // dmlUsrId: receivedId,
        })
      );
      // navigate("/job-tabs");
      navigate("/job-view");
      form.resetFields();
      handleOk();
      dispatch(setRefetchKey("1"));
    } else {
      toast.error("Failed to add job, please try again");
    }
  };

  useEffect(() => {
    setClientDataList(clientData);
  }, [clientData]);

  useEffect(() => {}, [clientDataList]);

  useEffect(() => {
    setReceivedId(clientEditId);
  }, [clientEditId]);

  useEffect(() => {
    setDemandLetterList(demandLetters);
  }, [demandLetters]);

  useEffect(() => {
    const singleItem = demandLetterList?.filter(
      (item) => item?.dmlStatus == "APPROVED"
    );
    setApprovedDemandLetterList(singleItem);
  }, [demandLetterList]);

  useEffect(() => {}, [approvedDemandLeterList]);

  async function fetchLetters() {
    const res = await dispatch(
      fetchDemandLetters({
        dmlUsrId: user?.usrInstId,
        dmlCntId: data?.cntName ? data?.cntName : receivedId,
      })
    );
  }

  async function fetchClientDetails() {
    const res = await dispatch(
      fetchClients({
        cntInstId: user?.usrInstId,
      })
    );
  }
  async function fetchDemandJobs() {
    const res = await dispatch(
      fetchApprovedDemandLetterJobs({
        dmlStatus: "APPROVED",
        dljInstId: user?.usrInstId,
      })
    );
  }

  async function fetchLetters() {
    const res = await dispatch(
      fetchApprovedDemandLetters({
        dmlUsrId: user?.usrInstId,
        dmlCntId: clientEditId ? clientEditId : "",
        dmlStatus: "APPROVED",
      })
    );
  }

  async function fetchJobsByDemandLetterList() {
    dispatch(
      fetchJobsByDemandLetter({
        jobDmlId: dmlIdNumber,
        jobStatus: "DRAFT",
      })
    );
  }

  useEffect(() => {
    if (clientEditId && refetchKey == 2) {
      fetchLetters();
    } else if (!clientEditId) {
      fetchLetters();
    }
  }, [clientEditId && refetchKey]);

  useEffect(() => {
    fetchClientDetails();
  }, []);

  useEffect(() => {
    fetchLetters();
  }, []);

  return (
    <>
      <Modal
        className="mod mod-height"
        title="Add Job"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <div className="text-black1 text-[22px] font-[400] font-dmSans leading-9 mb-[35px]">
          Fill in the following details to add a job vacancy
        </div>

        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="grid grid-cols-1">
            <Form.Item
              className="job-vacancy-modal"
              name="jobDmlId"
              label="Select Demand Letter"
              rules={[
                {
                  required: true,
                  message: "Please select demand letter",
                },
              ]}
            >
              <Select
                showSearch
                style={{
                  width: "100%",
                  height: "44px",
                }}
                options={
                  approvedDemandLetters &&
                  approvedDemandLetters?.map((item) => ({
                    value: item.dmlId,
                    label: item.dmlName,
                    clientId: item.dmlCntId,
                    clientName: item.cntName,
                  }))
                }
                onChange={(value, item) => {
                  handleSelectChange(value);
                  setDmlIdNumber(value);
                  setJobClient(item?.clientId);
                  setJobClientName(item?.clientName);
                }}
                onDropdownVisibleChange={() => {
                  fetchLetters();
                  if (approvedDemandLetters?.length === 0) {
                    toast.success("Lets start by creating a demand letter");
                    navigate("/letter-list");
                  }
                }}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label?.toLocaleLowerCase() ?? "").includes(
                    input?.toLocaleLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>

            {dmlIdNumber && (
              <Form.Item
                className="job-vacancy-modal"
                name="jobId"
                label="Select Job Title"
                rules={[
                  {
                    required: true,
                    message: "Please select job title",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  options={
                    jobDemandLetterData &&
                    jobDemandLetterData?.map((item) => ({
                      value: item.jobId,
                      label: item.jobName,
                    }))
                  }
                  onChange={(value, item) => {
                    handleSelectChangeJobTitle(value);
                  }}
                  onDropdownVisibleChange={() => {
                    fetchJobsByDemandLetterList();
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                />
              </Form.Item>
            )}

            <div className="flex flex-col mb-5">
              <label htmlFor="">
                {agencyObj?.licenseName == "LOCAL LICENSE"
                  ? "Local"
                  : "Foreign"}{" "}
                employer
              </label>
              <Input readOnly value={jobClientName} className="input" />
            </div>
          </div>
          <div className="flex justify-between mt-[10rem] mb-[40px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center gap-x-2">
              <button
                type="button"
                onClick={handleCancel}
                className="w-[200px] cancel-btn border border-blueDark flex items-center justify-center"
              >
                Cancel
              </button>

              {checkEditPermission(menuSidebarData, "JOB_ADVERTS") && (
                <button
                  key="submit"
                  type="submit"
                  className="w-[200px] save-btn"
                >
                  {jobLoading ? <Spin /> : "Continue"}
                </button>
              )}
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default AddJobModal;
