import React, { useEffect, useState } from 'react';
import LicenseCard from './LicenseCard';
import { Radio, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAgencyLicense } from '../../../features/license/licenseSlice';
import ScrollTo from '../../../ScrollTo';
import { fetchAgencyData, saveAgency } from '../../../features/agency/agencySlice';

const LicenseDetails = () => {
	const dispatch = useDispatch();

	const { agentLicence, licenseLoading } = useSelector(
		(state) => state.license,
	);
	const { user } = useSelector((state) => state.auth);
	const { agencyObj } = useSelector((state) => state.agency);

	const [licenseList, setlicenseList] = useState(agentLicence);
	const [selectedValue, setSelectedValue] = useState(agencyObj?.instLicId);

	const bgColorClass = (bgColor) => {
		return {
			backgroundColor: bgColor,
		};
	};

	async function handleSelectLicense(val) {
		setSelectedValue(val);
		let instCopy = {
			instId: agencyObj?.instId,
			instLicId: Number(val)			
		}
		await dispatch(saveAgency(instCopy));
		await handleFetchInstitutionObj();
	}

	async function handleFetchAgencyLicense() {
		await dispatch(fetchAgencyLicense());
	}

	async function handleFetchInstitutionObj() {
		await dispatch(fetchAgencyData(user?.usrInstId));
	}

	useEffect(() => {}, [licenseList]);

	useEffect(() => {
		setlicenseList(agentLicence);
	}, [agentLicence]);

	useEffect(() => {
		handleFetchAgencyLicense();
	}, []);

	return (
		<div className='flex flex-col w-[100%] my-14'>
			<ScrollTo />
			<h3 className='appl-head'>License</h3>
			<div className='flex flex-row justify-between mt-2'>
				<div className='font-medium'>Select a license model below</div>
			</div>

			{licenseLoading ? (
				<div className='flex justify-center items-center'>
					<Spin />
				</div>
			) : (
				<Radio.Group
					className='grid grid-cols-3 px-10 mt-10 gap-10'
					name='radiogroup'
					value={selectedValue}
					size='large'
					onChange={(e) => handleSelectLicense(e.target.value)}
				>
					{licenseList?.map((item) => {
						return (
							<>
								<LicenseCard
									style={bgColorClass(item?.licDesc)}
									licenseType={item?.licType}
									price={item?.licAmount}
									selectedButton={item?.licId}
								/>
							</>
						);
					})}
				</Radio.Group>
			)}
		</div>
	);
};

export default LicenseDetails;
