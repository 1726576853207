import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Link } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { Form, Radio, Space, Switch, Table, Descriptions } from 'antd';

const columns = [
	{
		title: 'Name',
		dataIndex: 'name',
		render: (item) => (
			<>
				<div className='flex items-center w-full'>
					<img
						className='w-7 h-7 rounded-full'
						src={'https://cdn-icons-png.flaticon.com/512/3607/3607444.png'}
						alt='Avatar'
					/>
					<span className='table-name mx-3'>Abzedizo Tetman</span>
					<button className='p-0 mx-4 flex items-end' type='button'>
						<MaterialIcon color='#000' icon='visibility' />
					</button>
					<button className='p-0 m-0 flex items-end' type='button'>
						<MaterialIcon color='#000' icon='more_vert' />
					</button>
				</div>
			</>
		),
	},
	{
		title: 'Age',
		dataIndex: 'age',
	},
	{
		title: 'Address',
		dataIndex: 'address',
	},
	{
		title: 'Action',
		key: 'action',
		render: () => (
			<div className='flex flex-col'>
				<span className=''>Do some'</span>
			</div>
		),
	},
];

const reffered = [
	{
		title: 'Name',
		dataIndex: 'name',
		render: (item) => (
			<>
				<div className='flex items-center w-full'>
					<img
						className='w-7 h-7 rounded-full'
						src={'https://cdn-icons-png.flaticon.com/512/3607/3607444.png'}
						alt='Avatar'
					/>
					<span className='table-name mx-3'>Abzedizo Tetman</span>
					<button className='p-0 mx-4 flex items-end' type='button'>
						<MaterialIcon color='#000' icon='visibility' />
					</button>
					<button className='p-0 m-0 flex items-end' type='button'>
						<MaterialIcon color='#000' icon='more_vert' />
					</button>
				</div>
			</>
		),
	},
	{
		title: 'Age',
		dataIndex: 'age',
	},
	{
		title: 'Address',
		dataIndex: 'address',
	},
	{
		title: 'Action',
		key: 'action',
		render: () => (
			<div className='flex flex-col'>
				<span className=''>Do some'</span>
			</div>
		),
	},
];

const candidatesTable = [
	{
		title: 'Sourcing',
		dataIndex: 'age',
		width: '50%',
		render: (item) => (
			<>
				<div className='flex items-center'>
					<span className='py-3 px-4 text-black font-bold bg-[#F8F8F8] text-[17px]'>
						4
					</span>
					<span className='ml-4'>{item}</span>
				</div>
			</>
		),
	},
	{
		title: 'Placement',
		dataIndex: 'address',
		width: '50%',
		render: (item) => (
			<>
				<div className='flex items-center'>
					<span className='py-3 px-4 text-black font-bold bg-[#F8F8F8]'>4</span>
					<span className='ml-4'>{item}</span>
				</div>
			</>
		),
	},
];

const data = [];
for (let i = 1; i <= 10; i++) {
	data.push({
		key: i,
		name: 'John Brown',
		age: Number(`${i}2`),
		address: `New York No. ${i} Lake Park`,
	});
}

export default function NeaTables() {
	const items = [
		{
			key: '1',
			label: <span className='profile-basic-info font-[500]'>Full Name</span>,
			children: <span className='profile-basic-info font-[400]'>Tetman</span>,
		},
		{
			key: '2',
			label: (
				<span className='profile-basic-info font-[500]'>Passport No.</span>
			),
			children: <span className='profile-basic-info font-[400]'>A2408627</span>,
		},
		{
			key: '3',
			label: <span className='profile-basic-info font-[500]'>Email</span>,
			children: (
				<span className='profile-basic-info font-[400]'>abzed@me.com</span>
			),
		},
		{
			key: '4',
			label: <span className='profile-basic-info font-[500]'>Nationality</span>,
			children: <span className='profile-basic-info font-[400]'>Kenyan</span>,
		},
		{
			key: '5',
			label: <span className='profile-basic-info font-[500]'>County</span>,
			children: <span className='profile-basic-info font-[400]'>Nairobi</span>,
		},
		{
			key: '6',
			label: (
				<span className='profile-basic-info font-[500]'>Registration Type</span>
			),
			children: (
				<span className='profile-basic-info font-[400]'>Job seeker</span>
			),
		},
		{
			key: '7',
			label: <span className='profile-basic-info font-[500]'>ID Number</span>,
			children: <span className='profile-basic-info font-[400]'>30762782</span>,
		},
		{
			key: '8',
			label: <span className='profile-basic-info font-[500]'>Mobile No.</span>,
			children: (
				<span className='profile-basic-info font-[400]'>+254872636828</span>
			),
		},
		{
			key: '9',
			label: (
				<span className='profile-basic-info font-[500]'>Date of birth</span>
			),
			children: (
				<span className='profile-basic-info font-[400]'>13/02/1990</span>
			),
		},
		{
			key: '10',
			label: (
				<span className='profile-basic-info font-[500]'>Marital status</span>
			),
			children: (
				<span className='profile-basic-info font-[400]'>Searching</span>
			),
		},
		{
			key: '11',
			label: <span className='profile-basic-info font-[500]'>Ethnicity</span>,
			children: <span className='profile-basic-info font-[400]'>Kamba</span>,
		},
		{
			key: '12',
			label: <span className='profile-basic-info font-[500]'>Gender</span>,
			children: <span className='profile-basic-info font-[400]'>Male</span>,
		},
	];

	const data = [];
	for (let i = 1; i <= 4; i++) {
		data.push({
			key: i,
			name: 'John Brown',
			age: Number(`${i}2`),
			address: `New York No. ${i} Lake Park`,
			description: `My name is John Brown, I am ${i}2 years old, living in New York No. ${i} Lake Park.`,
		});
	}

	const tableColumns = columns.map((item) => ({
		...item,
	}));

	return (
		<>
			<div className='mb-10'>
				<h1 className='text-blueDark font-bold text-5xl underline mb-3'>
					TABLES
				</h1>
				<div className='w-[40%]'>
					<Descriptions
						colon={false}
						size={'middle'}
						column={2}
						title='Basic Information'
						layout='vertical'
						items={items}
					/>
				</div>

				<div className='my-7'></div>

				<Descriptions
					colon={false}
					size={'middle'}
					column={6}
					title='Basic Information'
					layout='vertical'
					items={items}
				/>

				<div className='my-7'></div>

				<Table
					rowSelection={true}
					size={'middle'}
					bordered={true}
					pagination={{
						position: ['bottomCenter'],
					}}
					columns={tableColumns}
					dataSource={data}
					scroll={'scroll'}
				/>

				<div className='w-full'>
					<div className='w-full bg-blueDark h-[59px] flex items-center'>
						<span className='text-white pl-8 font-bold text-[18px] font-dmSans'>
							Candidates
						</span>
					</div>
					<div className='p-[41px]'>
						<Table
							className='no-header-bg-table'
							size={'middle'}
							bordered={false}
							columns={candidatesTable}
							dataSource={data}
							scroll={'scroll'}
						/>
					</div>
				</div>

				<div className='w-full'>
					<div className='w-full bg-blueish h-[59px] flex items-center'>
						<span className='text-black pl-8 font-bold text-[18px] font-dmSans'>
							Client Details
						</span>
					</div>
					<div className='flex flex-col p-[40px] '>
						<div className='grid grid-cols-2 mb-[20px]'>
							<span>Name</span>
							<span>Abzedizo Tetman</span>
						</div>
						<div className='grid grid-cols-2 mb-[20px]'>
							<span>Website</span>
							<div className='flex items-center'>
								<MaterialIcon color='#147CBC' size={20} icon='library_add' />
								<span className='text-[#147CBC] ml-1'>Add</span>
							</div>
						</div>
						<div className='grid grid-cols-2 mb-[20px]'>
							<span>Documents</span>
							<div className='flex items-center'>
								<MaterialIcon color='#147CBC' size={20} icon='library_add' />
								<span className='text-[#147CBC] ml-1'>Add</span>
							</div>
						</div>
					</div>
				</div>

				<div className='w-full'>
					<div className='w-full bg-blueish h-[59px] flex items-center'>
						<span className='text-black pl-8 font-bold text-[18px] font-dmSans'>
							Contacts
						</span>
					</div>
					<div className='flex flex-col items-center justify-center p-[40px] '>
						<span className='text-black text-[16px] font-[400]'>
							No contacts added yet
						</span>
						<div className='mt-[36px]'>
							<button
								className='bg-blueDark rounded-[35px] text-white w-[176px] p-1 flex items-center justify-center'
								type='submit'
							>
								<MaterialIcon color='#fff' size={20} icon='add' />
								<span className='ml-1'>Add</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
