import { Table, Skeleton, Button, Dropdown, Input } from "antd";
import React, { useEffect, useState } from "react";
import MaterialIcon from "material-icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanMyEmployees, 
  setEmployeePlacementObj,
  setIsLocal,
  setViewBAsic,
  setViewContract,
  setViewJob,
} from "../../features/myEmployees/myEmployeesSlice";
import emptyContainer from "../../assets/Container.png";
import morevert from "../../assets/morevert.svg";
import InsideHeader from "../../components/InsideHeader";
import ConfirmationDistressModal from "../../components/ConfirmationDistressModal ";
import { fetchDistress } from "../../features/runaway/runawaySlice";


function DistressTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const { user } = useSelector((state) => state.auth);
  const { distressData,loading,distressCount } = useSelector((state) => state.runaway);
 console.log("distressData",distressData)
  const [searchText, setSearchText] = useState("");
  const [prodd, setProdd] = useState();

  

  const handleView = async (item) => {
    navigate(`/distress-view/${prodd?.dstId}`);
  };

  const [distressStatus,setDistressStatus] =  useState()

  const [isModalOpenConfirmation, setIsModalOpenConfirmation] = useState(false);
  const showModalConfirmation = async () => {
    setIsModalOpenConfirmation(true);
  };

  const handleResolved =  async (item) => {
    await setDistressStatus("RESOLVED")
    await showModalConfirmation()
  }

  const [isModalOpenEscalate, setIsModalOpenEscalate] = useState(false);
  const showModalEscalate = async () => {
    setIsModalOpenEscalate(true);
  };

  const handleEscalate =  async (item) => {
    await setDistressStatus("ESCALATE_TO_NEA")
    await showModalEscalate() 
  }


  async function cleanEmployeeDetails() {
    dispatch(cleanMyEmployees());
  }

  useEffect(() => {
    cleanEmployeeDetails();
  }, []);

  const columns = [
    {
      title: "First Name",
      render: (item) => (
        <>
          <div className="flex justify-between">
            <div className="flex items-center"> 
              <span className="table-name mx-3">
              {item?.dstFirstName} {item?.dstLastName} 
                </span>
            </div>
            <div
              className="flex items-center"
              onClick={() => setProdd(item)}
            >
              <button className="p-0 m-0 flex items-end" type="button">
              <Dropdown
                overlayStyle={{
                  width: "250px",
                }}
                trigger={"click"}
                menu={{ items: settingItems }}
                placement="bottom"
              >
                <img src={morevert} alt="morevert" />
              </Dropdown>
            </button>
              
            </div>
          </div>
        </>
      ), 
    }, 
    {
      title: "National ID",
      dataIndex: "dstNationalId", 
    }, 
    {
      title: "Phone Number",
      dataIndex: "dstPhone", 
    },
    {
      title: "Problem",
      dataIndex: "dstProblem", 
    },
    {
      title: "Type",
      dataIndex: "dstType", 
    },
    
    {
      title: "Status",
      render:(item)=>{
        return(
          <div>{item === "SENT_TO_AGENCY" ? 'SENT TO AGENCY' : item === "RESOLVED" ? "RESOLVED" : item === "ESCALATE_TO_NEA" ? 'ESCALATED TO NEA' : item === "ESCALATE_TO_EXTERNAL_BODY" ? "ESCALATED TO EXTERNAL BODY" : null }</div>
        )
      },
      dataIndex: "dstStatus", 
    },
    {
      title: "Position",
      dataIndex: "jobPosition", 
    },
    {
      title: "Job Country",
      dataIndex: "jobCountry", 
    }, 
    {
      title: "Reason",
      dataIndex: "dstDesc", 
    }, 
    
    {
      title: "Employer Name",
      dataIndex: "cntName", 
    },
    {
      title: "Employer Email",
      dataIndex: "cntEmail", 
    },
    {
      title: "Employer Phone Number",
      dataIndex: "cntOfficeMobile", 
    },
    
  ];

  const settingItems = [ 
    {
      key: "0",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={handleResolved}
        >
          Resolved
        </div>
      ),
    },
    {
      key: "1",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={handleEscalate}
        >
          Escalate
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={handleView}
        >
          View
        </div>
      ),
    },
  ];

  const filteredData = searchText
    ? distressData?.filter((item) =>
        item?.dstFirstName?.toLowerCase().includes(searchText.toLowerCase())
      )
    : distressData;

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

 console.log("distressData",distressData)

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchDistressData(page,size) {
    await dispatch(
      fetchDistress({
        instId: user?.usrInstId,
        dstStatus:'agency',
        limit: size ?? pageSize,
        start: page ?? pageIndex,
      })
    );
  }
 
  useEffect(() => {
      fetchDistressData();
  }, []);

 
  return (
    <>
     <InsideHeader
        title="Distress"
        subtitle="Manage your employees distress here"
        back={false}
      />

      <div className="px-10 mt-[2.19rem]">
        {distressData &&
        distressData?.length > 0 ? (
          <>
           <h3 className="font-[700] text-[24px] text-black1 dash-title">
              List of distress
            </h3>
            <div className="flex items-center justify-between w-full mt-[1.94rem]">
              <Input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search employee name or keyword"
                className="text-[16px] font-[400] flex-row-reverse"
                prefix={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.2508 3.75C7.50609 3.75 4.47041 6.93997 4.47041 10.875C4.47041 14.81 7.50609 18 11.2508 18C14.9955 18 18.0312 14.81 18.0312 10.875C18.0312 6.93997 14.9955 3.75 11.2508 3.75ZM3.04297 10.875C3.04297 6.11154 6.71773 2.25 11.2508 2.25C15.7838 2.25 19.4586 6.11154 19.4586 10.875C19.4586 15.6385 15.7838 19.5 11.2508 19.5C6.71773 19.5 3.04297 15.6385 3.04297 10.875Z"
                      fill="#333333"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.045 15.913C16.3237 15.6201 16.7756 15.6201 17.0543 15.913L21.3902 20.4693C21.6689 20.7622 21.6689 21.237 21.3902 21.5299C21.1115 21.8228 20.6596 21.8228 20.3809 21.5299L16.045 16.9737C15.7663 16.6808 15.7663 16.2059 16.045 15.913Z"
                      fill="#333333"
                    />
                  </svg>
                }
              /> 
            </div>

            <div className="mt-[20px]">
              {loading ? (
                <Skeleton />
              ) : (
                <Table
                  rowSelection={false}
                  size={"middle"}
                  bordered={true}
                  pagination={{
                    position: ["bottomCenter"],
                    current: pageIndex + 1,
                    total: distressCount,
                    pageSize: pageSize,
                    onChange: (page, size) => {
                      setPageIndex(page - 1);
                      setPageSize(size);  
                      fetchDistressData(page - 1, size); 
                    },
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                  }}
                  rowKey={(record) => record?.dstId}
                  columns={tableColumns}
                  dataSource={filteredData}
                  scroll={{
                    x: "max-content",
                    // x:800
                  }}
                />
              )}
            </div>
          </>
        ) : (
          <div className=" h-[885px] bg-white flex  flex-col">
          <div
            className=" bg-white full h-[471.33px] rounded-[15px] border border-solid
             border-[#F5F7FF] py-[60px] flex flex-col justify-center items-center cursor-pointer"
          >
            <div>
              <img
                src={emptyContainer}
                alt="group-pic"
                className="object-contain h-[291.33px] w-[292px]"
              />
              <div className="empty-desc flex items-center justify-center">
                No distress reported yet  
              </div>
            </div>
          </div>
        </div>
        )}
      </div>

      <ConfirmationDistressModal
        isModalOpen={isModalOpenConfirmation}
        setIsModalOpen={setIsModalOpenConfirmation} 
        loading={loading}
        content={`Are you sure you have resolved the distress of ${prodd?.dstFirstName}  ${prodd?.dstLastName}`}
        distressStatus={distressStatus}
        prodd={prodd}
      /> 
       <ConfirmationDistressModal
        isModalOpen={isModalOpenEscalate}
        setIsModalOpen={setIsModalOpenEscalate}
        loading={loading}
        content={`Are you sure you want to escalate ${prodd?.dstFirstName}  ${prodd?.dstLastName} distress`}
        distressStatus={distressStatus}
        prodd={prodd}
      /> 
    </>
 
  );
}

export default DistressTable;
