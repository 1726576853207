import React, { useRef, useState } from "react";
import { Button, Card, Form, Input, Modal, Spin } from "antd";
import "../../clients/client.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MaterialIcon from "material-icons-react";
import uplodSvg from "../../../assets/uploadPic.png";
import toast from "react-hot-toast";
import { saveFile } from "../../../features/application/applicationSlice";
import { saveClient } from "../../../features/client/clientSlice";
import { formatImgPath } from "../../../utils";
import { createApplicantsDetails } from "../../../features/applicant/applicantSlice";
import { setRefetchKey } from "../../../features/cv/cvSlice";

const ApplicantNameModal = ({
  isModalOpenName,
  setIsModalOpenName,
  showModal,
}) => {
  const handleOk = () => {
    setIsModalOpenName(false);
  };
  const handleCancel = () => {
    setIsModalOpenName(false);
    showModal();
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { applicantLoading } = useSelector((state) => state.applicant);

  function closeModal() {
    toast.dismiss();
  }

  const [loadingAttach, setLoadingAttach] = useState(false);
  const [profile, setProfile] = useState(null);
  const handleImageSelect = async (event) => {
    setLoadingAttach(true);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    const res = await dispatch(saveFile(formData));
    if (res?.payload?.success) {
      setLoadingAttach(false);
      // setProfile(formatImgPath(res?.payload?.jsonData ?? ""));
      setProfile(res?.payload?.jsonData ?? "");
    } else {
      setLoadingAttach(false);

      toast.error("Please try upload your image again");
    }
  };

  const [data, setdata] = useState({});
  const [randomFourDigitNumber, setRandomFourDigitNumber] = useState(null);

  const generateRandomNumber = () => {
    const randomNumber = Math.floor(1000 + Math.random() * 9000);  
    setRandomFourDigitNumber(randomNumber);
    return randomNumber; 
  };

  const onFinish = async (data) => {
    if (!profile) {
      toast.error("Attach applicant photo");
    } else {
      const usrNationalId = generateRandomNumber(); 
      const res = await dispatch(
        createApplicantsDetails({ 
          usrFullNames: data?.usrFullNames,
          usrInstId: user?.usrInstId,
          usrIcon: profile,
          usrNationalId:usrNationalId
        })
      ); 
      if (res?.payload?.usrId) {
        toast.success("Applicant info saved successfull");
        form.resetFields();
        await dispatch(setRefetchKey("1"));
        navigate("/applicant-tabs");
        // handleOk();
      } else {
        toast.error("Check your details");
      }
    }
  };
 

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title="Create candidate"
        open={isModalOpenName}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <div className="w-[844px] text-black1 text-[22px] font-medium font-dmSans leading-9 mb-[35px]">
          Creating a candidate will allow you to fill in their details, upload a
          CV to their profile, and add them to jobs.
        </div>

        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="items-center flex mb-[23px]">
            {profile === null ? (
              <label className="flex flex-row items-center justify-start w-[20%]">
                <input
                  name="secIcon"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e)=>handleImageSelect(e)}
                />
                <img
                  className="z-10 w-[100px] h-[100px] rounded-full"
                  alt="tab1"
                  src={uplodSvg} 
                  style={{ cursor: "pointer" }}
                />
              </label>
            ) : 
            loadingAttach ? (
              <Spin  className="spin-modal"/>
            ):
            (
              <label className="flex flex-row items-center justify-start gap-2  w-[20%]">
                <input
                  name="secIcon"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageSelect}
                />
                <div className="rounded-full  overflow-hidden border-4 border-blueDark">
                  <img
                    src={formatImgPath(profile)}
                    alt="director"
                    className="w-28 h-28 cursor-pointer object-cover"
                  />
                </div>
              </label>
            )}

            <span className="w-full">
              <Form.Item
                name="usrFullNames"
                label="Candidate name"
                rules={[
                  {
                    required: true,
                    message: "Please add candidate name",
                  },
                ]}
              >
                <Input className="input" />
              </Form.Item>
            </span>
          </div>
          <div className="flex justify-between">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center">
              <Button
                key="back"
                onClick={handleCancel}
                className="w-[138px] h-12 px-7 py-4 rounded-[56px] border-none justify-center items-center gap-2.5 inline-flex
          text-blueDark text-[1rem] font-medium font-dmSans leading-normal"
              >
                Cancel
              </Button>
              ,
              <button
                key="submit"
                type="submit"
                className="w-[239px] h-12 px-7 py-4 bg-blueDark rounded-[56px] shadow justify-center items-center gap-2.5 inline-flex
          text-blue-50 text-[1rem] font-medium font-dmSans leading-normal"
                disabled={loadingAttach || applicantLoading}
              >
                {applicantLoading || loadingAttach ? <Spin /> : "Continue"}
              </button>
              ,
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default ApplicantNameModal;
