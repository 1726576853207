import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import backArrow from "../../assets/Back.svg";
import InsideHeader from "../../components/InsideHeader";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/admin-landing");
  };
  const location = useLocation();
  const newLocation = location.pathname.split("/")[1];

  return (
    <>
      <InsideHeader title="Attestation" back={false} />
    </>
  );
}

export default Header;
