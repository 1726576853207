import React, { useContext, useEffect, useRef, useState } from "react";
import {  Form, Input, Select, Spin, Table, TimePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecruitmentProcessMenus } from "../../features/settings/settingSlice";
import {
  convertDurationMilliseconds,
  convertGapMilliseconds,
  convertToPercentage,
  dateForHumans, 
  formatImgPath,
  transformToPastTense,
} from "../../utils";
import { fetchJobApplicationsApplicant } from "../../features/applicantJob/applicantJobSlice";

import { useNavigate } from "react-router-dom";
import {
  cleanActivitySessionDataAuto,
  cleanActivitySessionDataBlock,
  cleanInterviewBlockTimes,
  fetchSingleJobActivity,
  saveInterViewDetail,
} from "../../features/activities/activitySlice";
import ConfirmationModal from "../../components/ConfirmationModal";
import toast from "react-hot-toast";
import confirmationIcon from "../../assets/confirmationActivity.svg";
import InsideHeader from "../../components/InsideHeader";
import { saveNotification } from "../../features/global/globalSlice";
import moment from "moment";
import dayjs from "dayjs";

const EditableContext = React.createContext(null);

const format = "HH:mm";

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const dispatch = useDispatch();
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    const { japInterviewStartTime, japInterviewEndTime } =
      record;

    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
      japInterviewStartTime: japInterviewStartTime
        ? dayjs(japInterviewStartTime)
        : null,
      japInterviewEndTime: japInterviewEndTime
        ? dayjs(japInterviewEndTime)
        : null,
    
    });
  };

  const save = async (start, end) => {
    try {
      const values = await form.validateFields();
      if (start || end) {
        values["japInterviewStartTime"] = start;
        values["japInterviewEndTime"] = end;
      } 
      console.log("values", values);

      // toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const { 
    activitySessionData, 
    interviewBlockTimes, 
  } = useSelector((state) => state.activity);

  const [startTimeManual, setStartTimeManual] = useState(null);
  const [endTimeManual, setEndTimeManual] = useState(null);

  const handleStartTimeChange = (time) => {
    setStartTimeManual(time);
    save();
  };

  const handleEndTimeChange = (time) => {
    setEndTimeManual(time);
    save();
  };


  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false); 
  const handleOk1 = () => {
    setOpen1(false);
  };
  const handleClick1 = () => {
    setOpen1(true);
  };
  const handleOk2= () => {
    setOpen2(false);
  };
  const handleClick2= () => {
    setOpen2(true);
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        className='table-form-item'
        name={dataIndex}
        rules={[
          {
            required: false,
            message: `${title} is required.`,
          },
        ]}
      >
        {dataIndex === "japInterviewStartTime" ? (
          <TimePicker
            placeholder="Start time"
            style={{ width: "100%", height: "44px" }}
            format="HH:mm"
            value={startTimeManual}
            onChange={handleStartTimeChange}
            onClick={handleClick1}
            open={open1}
            onOk={handleOk1}
          />
        ) : dataIndex === "japInterviewEndTime" ? (
          <TimePicker
            placeholder="End time"
            style={{ width: "100%", height: "44px" }}
            format="HH:mm"
            value={endTimeManual}
            onChange={handleEndTimeChange}
            onClick={handleClick2}
            open={open2}
            onOk={handleOk2}
          />
        ) :  
         interviewBlockTimes === "Block" ? (
          <Select
            style={{
              width: "100%",
              height: "44px",
              backgroundColor: "#E7F1FF",
            }}
            placeholder="Select session"
            options={
              activitySessionData?.length > 0 &&
              activitySessionData?.map((item) => ({
                value: item?.jaaTitle,
                label: item?.jaaTitle,
              }))
            }
            onChange={(value, label) => {
              const selectedSession = activitySessionData?.find(
                (item) => item?.jaaTitle === value
              );
              save(selectedSession.jaaStartTime, selectedSession.jaaEndTime);
            }}
            ref={inputRef}
            disabled={record.japProgressStatus === "INTERVIEW"}
            // onPressEnter={save}
            // onBlur={save}
          />
        ) : (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap editable-table-arrow"
        style={{
          // paddingRight: 24,
          height: "44px",
          alignItems: "center",
          borderRadius: "2px",
          // border: "1px solid #212121",
          boxShadow: "none",
          width: "100%",
          display: "flex",
          background: "#E7F1FF",
        }}
        onClick={toggleEdit}
      >
        {interviewBlockTimes === "Block" && (
          <span
            className={`flex justify-center items-center ${
              record.japProgressStatus === "INTERVIEW"
                ? "cursor-not-allowed"
                : "cursor-pointer"
            }`}
          >
            {record.japProgressStatus === "INTERVIEW"
              ? "Already in interview"
              : "Select session"}
          </span>
        )}

        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
const JapTable = () => {
  const { user } = useSelector((state) => state.auth);
  const { recruitmentProcess } = useSelector((state) => state.setting);
  const {
    activityLoading,
    activitySessionData,
    interviewBlockTimes,
    activitySessionDataAuto,
    singleActivity,
    activityEditId,
    interviewLoading,
    singleActivityData,
    editMode,
  } = useSelector((state) => state.activity);
  const { agencyObj } = useSelector((state) => state.agency);

  const { jobApplicationPerJob, jobApplicationLoading } = useSelector(
    (state) => state.applicantJob
  );
  const { panelDetails } = useSelector((state) => state.panel);

  const [formData, setFormData] = useState(editMode ? "All" : "Shortlist");
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSearch = (value) => {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePrevious = () => {
    navigate(-1);
  };

  const [dataSource, setDataSource] = useState(jobApplicationPerJob);
  const [count, setCount] = useState(2);

  const hasSessionData = interviewBlockTimes === "Block";
  const hasShortlistedByData = jobApplicationPerJob?.some(
    (item) => item?.japProgressStatus == "SHORTLIST" && formData === "SHORTLIST"
  );
  const hasShortlistedDateData = jobApplicationPerJob?.some(
    (item) => item?.japProgressStatus == "SHORTLIST" && formData === "SHORTLIST"
  );
  const hasTime = interviewBlockTimes === "Manual";
 

  const defaultColumns = [
    {
      title: "Applicant Name",
      render: (item) => (
        <>
          <div className="flex justify-between">
            <div className="flex items-center justify-start">
              <img
                className="w-9 h-9 rounded-full object-cover"
                src={
                  item?.usrProfileImage
                    ? formatImgPath(item?.usrProfileImage)
                    : "https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png"
                }
                alt="Avatar"
              />
              <span className="table-name mx-3">{item?.japFullName}</span>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Job Match",
      render: (item) => {
        return (
          <div className="w-[81px] py-[4px] px-[12px] bg-[#02A548] text-white rounded-[3px] flex items-center justify-center">
            {item?.japMatchingScore ? convertToPercentage(item?.japMatchingScore) :  0} %
          </div>
        );
      },
    },
    {
      title: "Shortlisted By",
      render: (item) => {
        return <div>{item?.japUpdatedByName}</div>;
      },
    },

    {
      title: "Date Shortlisted",
      render: (item) => {
        return <div>{dateForHumans(item)}</div>;
      },
      dataIndex: "japUpdatedDate",
    },

     
    ...(hasTime
      ? [
          {
            title: "Start time",
            render: (item) => {
              return (
                <div>
                  {item ? moment(item).format("HH : mm") : "Select start time"}
                </div>
              );
            },
            dataIndex: "japInterviewStartTime",
            editable: true,
          },
          {
            title: "End time",
            render: (item) => {
              return (
                <div>
                  {item ? moment(item).format("HH : mm") : "Select end time"}
                </div>
              );
            },
            dataIndex: "japInterviewEndTime",
            editable: true,
          },
        ]
      : []),

    ...(hasSessionData
      ? [
          {
            title: "Session",
            dataIndex: "japInterviewSession",
            editable: true,
          },
        ]
      : []),
  ];
  const [rowSelectedRowBlock, setRowSelectedRowBlock] = useState([]);

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.japId === item.japId);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
 
    setRowSelectedRowBlock((prevSelectedRows) => {
      const alreadySelected = prevSelectedRows?.some(
        (selectedRow) => selectedRow?.japId === row?.japId
      );
      if (alreadySelected) {
        return prevSelectedRows?.map((selectedRow) =>
          selectedRow?.japId === row?.japId ? row : selectedRow
        );
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const [receivedId, setreceivedId] = useState(
    Object.keys(singleActivity)?.length > 0
      ? singleActivity?.jaaId
      : activityEditId?.jaaId
  );
 

  const handleSaveBlock = async () => { 
    let hasError = false;

    for (const item of rowSelectedRowBlock || []) {
      if (!item?.japInterviewStartTime) {
        toast.error("Select session again");
        hasError = true;
        break;
      }
      if (!item?.japInterviewEndTime) {
        toast.error("Select session again");
        hasError = true;
        break;
      }
      if (!item?.japInterviewSession) {
        toast.error("Select session again");
        hasError = true;
        break;
      }

      await dispatch(
        saveInterViewDetail({
          japJaaId: receivedId,
          japIds: item?.japId,
          japInterviewStartTime: item?.japInterviewStartTime,
          japInterviewEndTime: item?.japInterviewEndTime,
          japProgressStatus: "INTERVIEW",
          japInstId: user?.usrInstId,
          japInterviewSession: "Block",
        })
      );
    }

    if (!hasError) {
      await rowSelectedRowBlock?.forEach((item) => {
        const notObj = {
          ntfnInstId: user?.usrInstId,
          ntfnSenderId: user?.usrId,
          ntfnReceiverId: item?.japUsrId,
          ntfnBody: `You have been invited for an interview by ${agencyObj?.instName}`,
        }; 
      });
      await navigate("/activity-list");
      await dispatch(cleanActivitySessionDataBlock());
      await dispatch(cleanActivitySessionDataAuto());
      await dispatch(cleanInterviewBlockTimes());
      await setIsModalOpenConfirmation(false);
    }
  };

  const handleSaveManual = async () => {
    let hasError = false;

    for (const item of rowSelectedRowBlock || []) {
      if (!item?.japInterviewStartTime) {
        toast.error("Fill in start time");
        hasError = true;
        break;
      }
      if (!item?.japInterviewEndTime) {
        toast.error("Fill in end time");
        hasError = true;
        break;
      }

      await dispatch(
        saveInterViewDetail({
          japJaaId: receivedId,
          japIds: item?.japId,
          japInterviewStartTime: item?.japInterviewStartTime?.$d,
          japInterviewEndTime: item?.japInterviewEndTime?.$d,
          japProgressStatus: "INTERVIEW",
          japInstId: user?.usrInstId,
          japInterviewSession: "Manual",
        })
      );
    }

    if (!hasError) {
      await rowSelectedRowBlock?.forEach((item) => {
        const notObj = {
          ntfnInstId: user?.usrInstId,
          ntfnSenderId: user?.usrId,
          ntfnReceiverId: item?.japUsrId,
          ntfnBody: `You have been invited for an interview by ${agencyObj?.instName}`,
        }; 
      });
      await navigate("/activity-list");
      await dispatch(cleanActivitySessionDataBlock());
      await dispatch(cleanActivitySessionDataAuto());
      await dispatch(cleanInterviewBlockTimes());
      await setIsModalOpenConfirmation(false);
    }
  };
 

  async function fetchCandidateJobApplications() {
    const res = await dispatch(
      fetchJobApplicationsApplicant({
        japJobId: activityEditId?.jaaJobId ?? singleActivity?.jaaJobId,
        japInstId: user?.usrInstId,
        japProgressStatus: formData,
      })
    );
  }

  async function fetchRecruitmentProcessMenusDetails() {
    await dispatch(
      fetchRecruitmentProcessMenus({
        rpmJobId: 300,
      })
    );
  }

  const [rowSelected, setRowSelected] = useState([]);
  const [rowSelectedRow, setRowSelectedRow] = useState([]);

  const onSelectChange = async (keys, rows) => {
    setRowSelected(keys);
    setRowSelectedRow(rows);
  };

  const rowSelection = {
    onChange: onSelectChange,
    selectedRowKeys: rowSelected,
    getCheckboxProps: (record) => ({
      disabled: record.japProgressStatus === "INTERVIEW",
    }),
  };

  const newDuration = convertDurationMilliseconds(
    activitySessionDataAuto?.durationAuto?.$d
  );
  const newGapTime = convertGapMilliseconds(
    activitySessionDataAuto?.gapTimeAuto?.$d
  );

  const [isModalOpenConfirmation, setIsModalOpenConfirmation] = useState(false);

  const handleOpenConfirmation = async (data) => {
    if (interviewBlockTimes === "Auto" && rowSelected?.length === 0) {
      toast.error("Select atleast one candidate");
      return;
    }
    if (interviewBlockTimes === "Block" && rowSelectedRowBlock?.length === 0) {
      toast.error("Select session for atleast one candidate");
      return;
    }
    if (interviewBlockTimes === "Manual" && rowSelectedRowBlock?.length === 0) {
      toast.error("Select start and end times for atleast one candidate");
      return;
    }

    setIsModalOpenConfirmation(true);
  };

  const handleSaveAuto = async () => {
    if (!activitySessionDataAuto?.durationAuto) {
      toast.error("Select duration in the previous step");
      return;
    }
    if (!activitySessionDataAuto?.gapTimeAuto) {
      toast.error("Select gaptime in the previous step");
      return;
    }
    if (!activitySessionDataAuto?.startTimeAuto) {
      toast.error("Select start time in the previous step");
      return;
    }
    const res = await dispatch(
      saveInterViewDetail({
        japJaaId: receivedId,
        japIds: rowSelected?.join(","),
        japInterviewStartTime: activitySessionDataAuto?.startTimeAuto?.$d,
        japDuration: newDuration,
        japInterviewGap: newGapTime,
        japProgressStatus: "INTERVIEW",
        japInstId: user?.usrInstId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await rowSelectedRow?.forEach((item) => {
        const notObj = {
          ntfnInstId: user?.usrInstId,
          ntfnSenderId: user?.usrId,
          ntfnReceiverId: item?.japUsrId,
          ntfnBody: `You have been invited for an interview by ${agencyObj?.instName}`,
        }; 
      });
      await navigate("/activity-list");
      await dispatch(cleanActivitySessionDataAuto());
      await dispatch(cleanActivitySessionDataBlock());
      await dispatch(cleanInterviewBlockTimes());
      await setIsModalOpenConfirmation(false);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const panel =
    panelDetails &&
    panelDetails?.find((item) => item?.paId == singleActivityData?.jaaPaId);

  const content = `Send out invite to '${singleActivityData?.jaaTitle}'`;
  const subContentAuto = `Send out invites to ${panel?.paName} and ${rowSelected?.length} applicants?`;
  const subContentBlockManual = `Send out invites to ${panel?.paName} and ${rowSelectedRowBlock?.length} applicants?`;

  async function fetchSingleActivity() {
    const res = await await dispatch(
      fetchSingleJobActivity({
        jaaId: receivedId,
      })
    );
  }

  useEffect(() => {
    fetchSingleActivity();
  }, []);

  useEffect(() => {
    fetchCandidateJobApplications();
  }, [formData]);

  useEffect(() => {
    fetchRecruitmentProcessMenusDetails();
  }, []);

  useEffect(() => {
    setDataSource(jobApplicationPerJob);
  }, [jobApplicationPerJob]);

  useEffect(() => {
    setreceivedId(
      Object.keys(singleActivity)?.length > 0
        ? singleActivity?.jaaId
        : activityEditId?.jaaId
    );
  }, [singleActivity, activityEditId]);

  return (
    <>
      <InsideHeader
        title="Activities"
        subtitle="Manage your activities such as meetings and interviews"
        back={false}
      />

      <div className="mt-[51px] px-10">
        <div className="text-black2 text-[24px] font-bold dash-title leading-[32.4px]">
          Applicants
        </div>
        <div className="mt-[20px] text-black2 text-[20px] font-[500]   leading-[24px]">
          {editMode
            ? "Edit applicants details"
            : "Choose applicants to be interviewed"}
        </div>

        <div className="mt-[24px] mb-[24px]">
          <Select
            showSearch
            style={{
              width: 361,
              height: "50px",
            }}
            name="selector"
            placeholder="Choose from applicant group"
            optionFilterProp="children"
            onSearch={onSearch}
            filterOption={filterOption}
            options={[
              { value: "All", label: "All Applicants" },
              ...(recruitmentProcess && recruitmentProcess.length > 0
                ? recruitmentProcess.map((item) => ({
                    value: item?.rpmStageName,
                    label: item?.stageName
                      ? transformToPastTense(item?.stageName)
                      : null,
                  }))
                : []),
              { value: "Drop", label: "Dropped" },
            ]}
            onChange={(value, label) => {
              if (value == "SCHEDULE INTERVIEW") {
                setFormData("Interview");
              } else {
                setFormData(value);
              }
            }}
            value={formData}
            defaultValue={formData}
            onDropdownVisibleChange={() => {
              fetchRecruitmentProcessMenusDetails();
            }}
            className="candidate-select-activity candidate-arrow-activity candidate-select-placeholder-activity candidate-select-placeholder-activity"
          />
        </div>
        <Table
          rowSelection={rowSelection}
          rowKey={(record) => record?.japId}
          components={components}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource}
          columns={columns}
          scroll={{
            x: 1300,
          }}
        />

        <div className="justify-center items-center flex flex-col gap-y-2 mt-[64px]">
          <buttom
            // key="submit"
            // type="submit"
            className="w-[300px] h-[50px] px-2 py-3 bg-blueDark rounded-[28px] justify-center items-center text-white text-[18px]
                        font-dmSans flex cursor-pointer"
            onClick={handleOpenConfirmation}
            // disabled={activityLoading}
          >
            {activityLoading ? <Spin /> : "Send invites"}
          </buttom>
          <button
            onClick={handlePrevious}
            className="w-[300px] h-[50px] px-2 py-3 justify-center items-center rounded-[28px] border border-solid border-blueDark flex 
                       !text-blueDark text-[18px] font-dmSans bg-white cursor-pointer"
          >
            Previous
          </button>
        </div>
      </div>

      <ConfirmationModal
        isModalOpen={isModalOpenConfirmation}
        setIsModalOpen={setIsModalOpenConfirmation}
        handleSubmit={
          interviewBlockTimes === "Block"
            ? handleSaveBlock
            : interviewBlockTimes === "Auto"
            ? handleSaveAuto
            : interviewBlockTimes === "Manual"
            ? handleSaveManual
            : ""
        }
        loading={interviewLoading}
        content={content}
        icon={confirmationIcon}
        subContent={
          interviewBlockTimes === "Auto"
            ? subContentAuto
            : subContentBlockManual
        }
        btnTitle="Send invites"
      />
    </>
  );
};
export default JapTable;
