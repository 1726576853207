import React, { useRef, useState } from "react";
import { Button, Form, Modal, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import { fetchNotes, saveNote } from "../../../features/notes/noteSlice";

var modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
  ],
};

var formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];

const NotesAddModal = ({ isModalOpen, setIsModalOpen }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const { clientEditId } = useSelector((state) => state.client);

  const { noteLoading } = useSelector((state) => state.notes);
  const [data, setdata] = useState({});
  const dispatch = useDispatch();

  const onFinish = async (data) => {
    const res = await dispatch(
      saveNote({
        notDesc: data?.notDesc,
        notCntId: clientEditId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await dispatch(
        fetchNotes({
          notCntId: clientEditId,
        })
      );
      await form.resetFields();
      await handleOk();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title="Add a note"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className="px-[15px]"
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <Form.Item
            className="w-full"
            name="notDesc"
            rules={[
              {
                required: true,
                message: "Please add a note",
              },
            ]}
          >
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              placeholder="Add your note here"
              style={{ height: "300px", marginBottom: "5%" }}
            />
          </Form.Item>

          <div className="flex justify-between mt-[68px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center">
              <button
                type="button"
                key="back"
                onClick={handleCancel}
                className="w-[200px] cancel-btn"
              >
                Cancel
              </button>
              ,
              <button
                key="submit"
                type="submit"
                className="w-[200px] save-btn"
                disabled={noteLoading}
              >
                {noteLoading ? <Spin /> : "Save"}
              </button>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default NotesAddModal;
