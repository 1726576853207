import { Table } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAttachments } from '../../../../../features/doc/docSlice';

export default function DocsForm({ userId }) {
	const dispatch = useDispatch();

	const { docs, docLoading } = useSelector((state) => state.doc);

	const usrObj = {
		usrId: userId,
	};

	const defaultColumnsViewOnly = [
		{
			title: 'Document name',
			key: 'attCertName',
			dataIndex: 'attCertName',
			editable: true,
		},
		{
			title: 'File',
			key: 'attFile',
			dataIndex: 'attFile',
			render: (item) => <span>{item ? item?.split('_').pop() : ''}</span>,
			editable: true,
		},
	];

	const columns = defaultColumnsViewOnly


	async function handleFetchData() {
		await dispatch(fetchAttachments(usrObj));
	}

	useEffect(() => {
	}, [docs]);

	useEffect(() => {
		handleFetchData();
	}, [userId]);

	return (
		<>
			<div className='dash-inner-page mt-3 max-w-full w-full overflow-x-auto'>
				<section>
					<Table
						className="no-header-bg-table no-hover"
						loading={docLoading}
						size={'middle'}
						bordered={false}
						columns={columns}
						dataSource={docs}
						scroll={{
							x: 700,
						}}
						pagination={{
							defaultPageSize: 5,
							hideOnSinglePage: true,
							pageSizeOptions: [10, 20, 50, 100],
						}}
					/>
				</section>
			</div>
		</>
	);
}
