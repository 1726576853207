import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  paneloading: false,
  panelActionLoading: false,
  panelDetails: [],
  memberName: "",
  memberEmail: "",
  memberPhone: "",
  memberRole: "",
  memberText: "",
  panelInfoRedux: "",
  panelTitle:[],
};

export const savePanel = createAsyncThunk(
  "panelSlice/savePanel",
  async (data) => {
    const res = await axiosInstance.post(`${url}/emp/savePanel.action`, data);
    return res.data;
  }
);

export const fetchPanels = createAsyncThunk(
  "panelSlice/fetchPanels",
  async (data) => {
    const res = await axiosInstance.get(`${url}/emp/fetchPanels.action?paInstId=${data?.paInstId}`);
    return res.data.data.result;
  }
);

export const fetchPanelById = createAsyncThunk(
  "panelSlice/fetchPanelById",
  async (data) => {
    const res = await axiosInstance.get(`${url}/emp/fetchPanels.action?paId=${data?.paId}`);
    return res.data.data.result;
  }
);

export const savePanelMember = createAsyncThunk(
  "panelSlice/savePanelMember",
  async (data) => {
    const res = await axiosInstance.post(`${url}/emp/savePanelMember.action`, data);
    return res.data;
  }
);

export const savePanelist = createAsyncThunk(
  "panelSlice/savePanelist",
  async (data) => {
    const res = await axiosInstance.post(`${url}/emp/savePanelist.action`, data);
    return res.data;
  }
);

export const deletePanel = createAsyncThunk(
  "panelSlice/deletePanel",
  async (data) => {
    const res = await axiosInstance.get(`${url}/emp/deletePanel.action?paId=${data?.paId}`);
    return res.data;
  }
);

export const deletePanelist = createAsyncThunk(
  "panelSlice/deletePanelist",
  async (data) => {
    const res = await axiosInstance.get(`${url}/emp/deletePanelist.action?panId=${data?.panId}`);
    return res.data;
  }
);


export const fetchPanelistTitles = createAsyncThunk(
  "panelSlice/fetchPanelistTitles",
  async (data) => {
    const res = await axiosInstance.get(`${url}/setup/fetchPanelistTitles.action`);
    return res.data.data.result;
  }
);


export const panelSlice = createSlice({
  name: "panel",
  initialState,
  reducers: {
    reset: (state) => {
      state.panelDetails = [];
    },

    setPanelDetails: (state, action) => {
      state.panelDetails = action.payload;
    },
    setMemberName: (state, action) => {
      state.memberName = action.payload;
    },
    setMemberEmail: (state, action) => {
      state.memberEmail = action.payload;
    }, 
    setMemberPhone: (state, action) => {
      state.memberPhone = action.payload;
    },
    setMemberRole: (state, action) => {
      state.memberRole = action.payload;
    },
    setMemberText: (state, action) => {
      state.memberText = action.payload;
    },
    
   
    setPanelInfoRedux: (state, action) => {
      state.panelInfoRedux = action.payload;
    },

    cleanMemberDetails: (state, action) => {
      state.memberName = initialState.memberName;
      state.memberEmail = initialState.memberEmail; 
      state.memberPhone = initialState.memberPhone;
      state.memberRole = initialState.memberRole;
      state.memberText = initialState.memberText;
    },
    cleanPanelInfoRedux: (state, action) => {
      state.panelInfoRedux = initialState.panelInfoRedux;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(savePanel.pending, (state) => {
        state.panelActionLoading = true;
      })
      .addCase(savePanel.fulfilled, (state, action) => {
        state.panelActionLoading = false;
      })
      .addCase(savePanel.rejected, (state) => {
        state.panelActionLoading = false;
      })

      .addCase(fetchPanels.pending, (state) => {
        state.paneloading = true;
      })
      .addCase(fetchPanels.fulfilled, (state, action) => {
        state.paneloading = false;
        state.panelDetails = action.payload;
      })
      .addCase(fetchPanels.rejected, (state) => {
        state.paneloading = false;
      })

      .addCase(fetchPanelById.pending, (state) => {
        state.paneloading = true;
      })
      .addCase(fetchPanelById.fulfilled, (state, action) => {
        state.paneloading = false;
        state.panelDetails = action.payload;
      })
      .addCase(fetchPanelById.rejected, (state) => {
        state.paneloading = false;
      })

      

      .addCase(savePanelMember.pending, (state) => {
        state.panelActionLoading = true;
      })
      .addCase(savePanelMember.fulfilled, (state, action) => {
        state.panelActionLoading = false;
      })
      .addCase(savePanelMember.rejected, (state) => {
        state.panelActionLoading = false;
      })

      .addCase(savePanelist.pending, (state) => {
        state.panelActionLoading = true;
      })
      .addCase(savePanelist.fulfilled, (state, action) => {
        state.panelActionLoading = false;
      })
      .addCase(savePanelist.rejected, (state) => {
        state.panelActionLoading = false;
      })

      .addCase(deletePanel.pending, (state) => {
        state.panelActionLoading = true;
      })
      .addCase(deletePanel.fulfilled, (state, action) => {
        state.panelActionLoading = false;
      })
      .addCase(deletePanel.rejected, (state) => {
        state.panelActionLoading = false;
      })

      .addCase(deletePanelist.pending, (state) => {
        state.panelActionLoading = true;
      })
      .addCase(deletePanelist.fulfilled, (state, action) => {
        state.panelActionLoading = false;
      })
      .addCase(deletePanelist.rejected, (state) => {
        state.panelActionLoading = false;
      })

      .addCase(fetchPanelistTitles.pending, (state) => {
        state.panelActionLoading = true;
      })
      .addCase(fetchPanelistTitles.fulfilled, (state, action) => {
        state.panelActionLoading = false;
        state.panelTitle = action.payload;
      })
      .addCase(fetchPanelistTitles.rejected, (state) => {
        state.panelActionLoading = false;
        state.panelTitle = [];
      })
      
  },
});

export default panelSlice.reducer;
export const {
  reset,
  setPanelDetails,
  setMemberName,
  cleanMemberDetails,
  setPanelInfoRedux,
  cleanPanelInfoRedux,
  setMemberEmail,
  setMemberPhone,
  setMemberRole, 
  setMemberText,
} = panelSlice.actions;
