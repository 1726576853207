import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  payLoading: false,
  invoices: [],
  newInvoice: {},
  payMethods: [],
};

export const mpesaPush = createAsyncThunk(
  "paySlice/mpesaPush",
  async (payObj) => {
    const res = await axiosInstance.get(
      `${url}/nea/mpesaPush.action?phone=${payObj?.phone}&amount=${payObj?.amount}&accountref=${payObj?.accountref}`
    );
    return res.data;
  }
);

export const fetchHacInvoices = createAsyncThunk(
  "paySlice/fetchHacInvoices",
  async (invoInstId) => {
    const res = await axiosInstance.get(
      `${url}/pay/fetchInvoices.action?invoInstId=${invoInstId}`
    );
    return res.data.data.result;
  }
);

export const fetchPaymentMethods = createAsyncThunk(
  "globalSlice/fetchPaymentMethods",
  async () => {
    const res = await axiosInstance.get(`${url}/setup/fetchPaymentMethods.action`);
    return res.data.data.result;
  }
);

export const savePaymentOption = createAsyncThunk(
	"globalSlice/savePaymentOption",
	async (payObj) => {
	  const res = await axiosInstance.post(`${url}/pay/saveReceipt.action`, payObj);
	  return res.data;
	}
  )

export const paySlice = createSlice({
  name: "pay",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchPaymentMethods.pending, (state) => {
        state.payLoading = true;
      })
      .addCase(fetchPaymentMethods.fulfilled, (state, action) => {
        state.payLoading = false;
        state.payMethods = action.payload;
      })
      .addCase(fetchPaymentMethods.rejected, (state) => {
        state.payLoading = false;
        state.payMethods = [];
      })

      .addCase(fetchHacInvoices.pending, (state) => {
        state.payLoading = true;
      })
      .addCase(fetchHacInvoices.fulfilled, (state, action) => {
        state.payLoading = false;
        state.invoices = action.payload;
        state.newInvoice = action.payload[0] || {};
      })
      .addCase(fetchHacInvoices.rejected, (state) => {
        state.payLoading = false;
        state.invoices = [];
        state.newInvoice = {};
      })

      .addCase(mpesaPush.pending, (state) => {
        state.payLoading = true;
      })
      .addCase(mpesaPush.fulfilled, (state) => {
        state.payLoading = false;
      })
      .addCase(mpesaPush.rejected, (state) => {
        state.payLoading = false;
      });
  },
});

export default paySlice.reducer;
export const {} = paySlice.actions;
