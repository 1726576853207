import { Button, Form, Input, Select, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  fetchClients,
  saveClient,
  setEditLocation,
  setViewLocation,
} from "../../features/client/clientSlice";
import citiesData from "../../CountriesCities.json";

function Location() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { agencyObj } = useSelector((state) => state.agency);
 
  const { clientLoading, clients, clientData, clientEditId } = useSelector(
    (state) => state.client
  );
  const [data, setdata] = useState({});

  const [receivedEditId, setReceivedEditId] = useState(
    Object?.keys(clients)?.length > 0 ? clients?.cntId : clientEditId
  );

  useEffect(() => {
    setReceivedEditId(
      Object?.keys(clients)?.length > 0 ? clients?.cntId : clientEditId
    );
  }, [clients, clientEditId]);

  const singleClient = clientData?.find(
    (item) => item?.cntId === receivedEditId
  );

  const [formData, setFormData] = useState({ ...singleClient });

  useEffect(() => {
    setFormData({ ...singleClient });
  }, [singleClient]);

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCancel = async () => {
    await dispatch(setEditLocation(false));
    await dispatch(setViewLocation(true));
  };

  const onSearchCountry = (value) => {};
  const filterOptionCountry = (input, option) =>
    (option?.children?.toLowerCase() ?? "").includes(input.toLowerCase());

 
    
  const country = citiesData?.find((city) => city.name === "Kenya");
  const [city, setcity] = useState(agencyObj?.licenseName == 'LOCAL LICENSE' ? country?.cities?.map((city) => city.name) : []);
  const [defaultValue, setdefaultValue] = useState(
    formData?.cntLocation ? formData?.cntLocation : ""
  );

  function selectedCountry(value) {
    const country = citiesData?.find((city) => city.name === value);
    if (country && country?.cities && country?.cities?.length > 0) { 
      const defaultCity ='';
      const cityNames = country?.cities?.map((city) => city.name);
      setcity(cityNames);
      setdefaultValue(defaultCity);
      setFormData((prevData) => ({
        ...prevData,
        cntCountry: value,
      }));
    }
  }

  function selectedCity(value) {
    setdefaultValue(value);
    setFormData((prevData) => ({
      ...prevData,
      cntLocation: value,
    }));
  }

  const onFinish = async (data) => {
    if(!formData?.cntCountry || !formData?.cntLocation){
      toast.error("Please fill all location details")
      return;
    }
    const res = await dispatch(
      saveClient({
        cntId: formData?.cntId,
        cntCreatedBy: user?.usrId,
        cntInstId: user?.usrInstId,
        cntCountry:formData?.cntCountry,
        cntLocation: formData?.cntLocation,
        cntAddress: formData?.cntAddress,
      })
    );
    if (res?.payload?.success) {
      toast.success("Location info saved successfull");
      form.resetFields();
      await dispatch(setViewLocation(true));
      await dispatch(setEditLocation(false));
    } else {
      toast.error("Check your details");
    }
  };

  async function fetchClientDetails() {
    const res = await dispatch(
      fetchClients({
        cntInstId: user?.usrInstId,
      })
    );
  }

  useEffect(() => {
    fetchClientDetails();
  }, []);

  return (
    <div className="mt-[38px]">
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
        }}
        form={form}
      >
        <div className="grid grid-cols-2 lg:gap-4">
          <Form.Item
          label="Country"
            rules={[
              {
                required: true,
                message: "Please select a country",
              },
            ]}
          >
            <Select
              required
              name="cntCountry" 
              value={formData?.cntCountry} 
              showSearch
              style={{
                height: "3.25rem",
              }}
              className="reg-input"
              placeholder="Country"
              onChange={selectedCountry}
              size="large"
              optionFilterProp="children"
              onSearch={onSearchCountry}
              filterOption={filterOptionCountry}
            >
              {agencyObj?.licenseName == 'LOCAL LICENSE' ? (
                <Select.Option key="Kenya" value="Kenya">
                  Kenya
                </Select.Option>
              ) : (
                citiesData?.map((city) => (
                  <Select.Option key={city.name} value={city.name}>
                    {city.name}
                  </Select.Option>
                ))
              )}
            </Select>
          </Form.Item>

          <Form.Item
            label="City"
            rules={[
              {
                required: true,
                message: "Please select a city",
              },
            ]}
          >
            <Select
              required
              name="cntLocation"
              showSearch
              style={{
                height: "3.25rem",
              }}
              onChange={selectedCity}
              value={defaultValue}
              disabled={city?.length ? false : true}
              placeholder={"City"}
              size="large"
              className="reg-input"
              optionFilterProp="children"
              onSearch={onSearchCountry}
              filterOption={filterOptionCountry}
            >
              {city?.map((city) => {
                return (
                  <Select.Option required key={city} value={city}>
                    {city}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>

        <div className="grid grid-cols-1">
          <Form.Item
            label="Address"
            rules={[
              {
                required: true,
                message: "Please add address",
              },
            ]}
          >
            <Input
              required
              name="cntAddress"
              value={formData.cntAddress}
              onChange={onChange}
              className="input"
            />
          </Form.Item>
        </div>

        <div className="flex items-center justify-between mt-[35px]">
          <div className="flex items-start"></div>
          <div className="flex gap-5 items-center">
            <button
              type="button"
              className="cancel-btn  w-[168px]"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
             className="save-btn  w-[168px]"
              type="submit"
              disabled={clientLoading ? true : false}
            >
              {clientLoading ? <Spin /> : "Save"}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default Location;
