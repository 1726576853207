import {
	Table,
} from 'antd';
import moment from 'moment';
import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExperiences } from '../../../../../features/exp/expSlice';

const url = process.env.REACT_APP_API_BASE_URL;

export default function ExperienceForm({ userId }) {
	const dispatch = useDispatch();

	const { experiences, expLoading } = useSelector((state) => state.exp);

	const usrObj = {
		usrId: userId,
	};

	const defaultColumnsViewOnly = [
		{
			title: 'Employer Name',
			dataIndex: 'expEmployer',
			sorter: (a, b) => a.expEmployer - b.expEmployer,
			editable: true,
		},
		{
			title: 'Job Title',
			dataIndex: 'expJobTitle',
			key: 'expJobTitle',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Experience Level',
			dataIndex: 'expLevel',
			key: 'expLevel',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Work Style',
			dataIndex: 'expWorkType',
			key: 'expWorkType',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Industry',
			dataIndex: 'expType',
			key: 'expType',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Description',
			dataIndex: 'expDesc',
			key: 'expDesc',
			editable: true,
		},
		{
			title: 'Start Date',
			dataIndex: 'expStartDate',
			key: 'expStartDate',
			sorter: (a, b) => a - b,
			render: (item) => (
				<span>{item ? moment(item).format('MMMM YYYY') : ''}</span>
			),
			editable: true,
		},
		{
			title: 'End Date',
			dataIndex: 'endEndDate',
			key: 'endEndDate',
			sorter: (a, b) => a - b,
			render: (item) => (
				<span>{item ? moment(item).format('MMMM YYYY') : ''}</span>
			),
			editable: true,
		},
	];

	const columns = defaultColumnsViewOnly

	async function handleFetchData() {
		await dispatch(fetchExperiences(usrObj));
	}

	useEffect(() => {
		handleFetchData();
	}, [userId]);


	useEffect(() => {
	}, [experiences]);

	return (
		<>
			<div className='dash-inner-page mt-3 max-w-full w-full overflow-x-auto'>
				<section>
					<Table
						loading={expLoading}
						className="no-header-bg-table no-hover"
						size={'middle'}
						bordered={false}
						columns={columns}
						dataSource={experiences}
						scroll={{
							x: 1500,
						}}
						pagination={{
							defaultPageSize: 5,
							hideOnSinglePage: true,
							pageSizeOptions: [10, 20, 50, 100],
						}}
					/>
				</section>
			</div>
		</>
	);
}
