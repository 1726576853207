 
export const currencyData = 
    [
      { "Ccy": "AFN", "CcyNm": "Afghan Afghani" },
      { "Ccy": "EUR", "CcyNm": "Euro" },
      { "Ccy": "ALL", "CcyNm": "Albanian Lek" },
      { "Ccy": "DZD", "CcyNm": "Algerian Dinar" },
      { "Ccy": "USD", "CcyNm": "United States Dollar" },
      { "Ccy": "AOA", "CcyNm": "Angolan Kwanza" },
      { "Ccy": "XCD", "CcyNm": "East Caribbean Dollar" },
      { "Ccy": "ARS", "CcyNm": "Argentine Peso" },
      { "Ccy": "AMD", "CcyNm": "Armenian Dram" },
      { "Ccy": "AWG", "CcyNm": "Aruban Florin" },
      { "Ccy": "AUD", "CcyNm": "Australian Dollar" },
      { "Ccy": "AZN", "CcyNm": "Azerbaijani Manat" },
      { "Ccy": "BSD", "CcyNm": "Bahamian Dollar" },
      { "Ccy": "BHD", "CcyNm": "Bahraini Dinar" },
      { "Ccy": "BDT", "CcyNm": "Bangladeshi Taka" },
      { "Ccy": "BBD", "CcyNm": "Barbadian Dollar" },
      { "Ccy": "BYN", "CcyNm": "Belarusian Ruble" },
      { "Ccy": "BZD", "CcyNm": "Belize Dollar" },
      { "Ccy": "XOF", "CcyNm": "West African CFA Franc" },
      { "Ccy": "BMD", "CcyNm": "Bermudian Dollar" },
      { "Ccy": "BTN", "CcyNm": "Bhutanese Ngultrum" },
      { "Ccy": "BOB", "CcyNm": "Bolivian Boliviano" },
      { "Ccy": "BAM", "CcyNm": "Bosnia and Herzegovina Convertible Mark" },
      { "Ccy": "BWP", "CcyNm": "Botswana Pula" },
      { "Ccy": "BRL", "CcyNm": "Brazilian Real" },
      { "Ccy": "BND", "CcyNm": "Brunei Dollar" },
      { "Ccy": "BGN", "CcyNm": "Bulgarian Lev" },
      { "Ccy": "BIF", "CcyNm": "Burundian Franc" },
      { "Ccy": "KHR", "CcyNm": "Cambodian Riel" },
      { "Ccy": "XAF", "CcyNm": "Central African CFA Franc" },
      { "Ccy": "CAD", "CcyNm": "Canadian Dollar" },
      { "Ccy": "CVE", "CcyNm": "Cape Verdean Escudo" },
      { "Ccy": "KYD", "CcyNm": "Cayman Islands Dollar" },
      { "Ccy": "CLP", "CcyNm": "Chilean Peso" },
      { "Ccy": "CNY", "CcyNm": "Chinese Yuan" },
      { "Ccy": "COP", "CcyNm": "Colombian Peso" },
      { "Ccy": "KMF", "CcyNm": "Comorian Franc" },
      { "Ccy": "CDF", "CcyNm": "Congolese Franc" },
      { "Ccy": "NZD", "CcyNm": "New Zealand Dollar" },
      { "Ccy": "CRC", "CcyNm": "Costa Rican Colón" },
      { "Ccy": "HRK", "CcyNm": "Croatian Kuna" },
      { "Ccy": "CUP", "CcyNm": "Cuban Peso" },
      { "Ccy": "CZK", "CcyNm": "Czech Koruna" },
      { "Ccy": "DKK", "CcyNm": "Danish Krone" },
      { "Ccy": "DJF", "CcyNm": "Djiboutian Franc" },
      { "Ccy": "DOP", "CcyNm": "Dominican Peso" },
      { "Ccy": "EGP", "CcyNm": "Egyptian Pound" },
      { "Ccy": "ERN", "CcyNm": "Eritrean Nakfa" },
      { "Ccy": "ETB", "CcyNm": "Ethiopian Birr" },
      { "Ccy": "FKP", "CcyNm": "Falkland Islands Pound" },
      { "Ccy": "FJD", "CcyNm": "Fijian Dollar" },
      { "Ccy": "GMD", "CcyNm": "Gambian Dalasi" },
      { "Ccy": "GEL", "CcyNm": "Georgian Lari" },
      { "Ccy": "GHS", "CcyNm": "Ghanaian Cedi" },
      { "Ccy": "GIP", "CcyNm": "Gibraltar Pound" },
      { "Ccy": "GTQ", "CcyNm": "Guatemalan Quetzal" },
      { "Ccy": "GNF", "CcyNm": "Guinean Franc" },
      { "Ccy": "GYD", "CcyNm": "Guyanese Dollar" },
      { "Ccy": "HTG", "CcyNm": "Haitian Gourde" },
      { "Ccy": "HNL", "CcyNm": "Honduran Lempira" },
      { "Ccy": "HKD", "CcyNm": "Hong Kong Dollar" },
      { "Ccy": "HUF", "CcyNm": "Hungarian Forint" },
      { "Ccy": "ISK", "CcyNm": "Icelandic Króna" },
      { "Ccy": "INR", "CcyNm": "Indian Rupee" },
      { "Ccy": "IDR", "CcyNm": "Indonesian Rupiah" },
      { "Ccy": "IRR", "CcyNm": "Iranian Rial" },
      { "Ccy": "IQD", "CcyNm": "Iraqi Dinar" },
      { "Ccy": "ILS", "CcyNm": "Israeli New Shekel" },
      { "Ccy": "JMD", "CcyNm": "Jamaican Dollar" },
      { "Ccy": "JPY", "CcyNm": "Japanese Yen" },
      { "Ccy": "JOD", "CcyNm": "Jordanian Dinar" },
      { "Ccy": "KZT", "CcyNm": "Kazakhstani Tenge" },
      { "Ccy": "KES", "CcyNm": "Kenyan Shilling" },
      { "Ccy": "KWD", "CcyNm": "Kuwaiti Dinar" },
      { "Ccy": "KGS", "CcyNm": "Kyrgyzstani Som" },
      { "Ccy": "LAK", "CcyNm": "Lao Kip" },
      { "Ccy": "LBP", "CcyNm": "Lebanese Pound" },
      { "Ccy": "LSL", "CcyNm": "Lesotho Loti" },
      { "Ccy": "LRD", "CcyNm": "Liberian Dollar" },
      { "Ccy": "LYD", "CcyNm": "Libyan Dinar" },
      { "Ccy": "MOP", "CcyNm": "Macanese Pataca" },
      { "Ccy": "MKD", "CcyNm": "Macedonian Denar" },
      { "Ccy": "MGA", "CcyNm": "Malagasy Ariary" },
      { "Ccy": "MWK", "CcyNm": "Malawian Kwacha" },
      { "Ccy": "MYR", "CcyNm": "Malaysian Ringgit" },
      { "Ccy": "MVR", "CcyNm": "Maldivian Rufiyaa" },
      { "Ccy": "MRU", "CcyNm": "Mauritanian Ouguiya" },
      { "Ccy": "MUR", "CcyNm": "Mauritian Rupee" },
      { "Ccy": "MXN", "CcyNm": "Mexican Peso" },
      { "Ccy": "MDL", "CcyNm": "Moldovan Leu" },
      { "Ccy": "MNT", "CcyNm": "Mongolian Tögrög" },
      { "Ccy": "MAD", "CcyNm": "Moroccan Dirham" },
      { "Ccy": "MZN", "CcyNm": "Mozambican Metical" },
      { "Ccy": "MMK", "CcyNm": "Myanmar Kyat" },
      { "Ccy": "NAD", "CcyNm": "Namibian Dollar" },
      { "Ccy": "NPR", "CcyNm": "Nepalese Rupee" },
      { "Ccy": "ANG", "CcyNm": "Netherlands Antillean Guilder" },
      { "Ccy": "TWD", "CcyNm": "New Taiwan Dollar" },
      { "Ccy": "NZD", "CcyNm": "New Zealand Dollar" },
      { "Ccy": "NIO", "CcyNm": "Nicaraguan Córdoba" },
      { "Ccy": "NGN", "CcyNm": "Nigerian Naira" },
      { "Ccy": "KPW", "CcyNm": "North Korean Won" },
      { "Ccy": "NOK", "CcyNm": "Norwegian Krone" },
      { "Ccy": "OMR", "CcyNm": "Omani Rial" },
      { "Ccy": "PKR", "CcyNm": "Pakistani Rupee" },
      { "Ccy": "PAB", "CcyNm": "Panamanian Balboa" },
      { "Ccy": "PGK", "CcyNm": "Papua New Guinean Kina" },
      { "Ccy": "PYG", "CcyNm": "Paraguayan Guaraní" },
      { "Ccy": "PEN", "CcyNm": "Peruvian Sol" },
      { "Ccy": "PHP", "CcyNm": "Philippine Peso" },
      { "Ccy": "PLN", "CcyNm": "Polish Złoty" },
      { "Ccy": "QAR", "CcyNm": "Qatari Riyal" },
      { "Ccy": "RON", "CcyNm": "Romanian Leu" },
      { "Ccy": "RUB", "CcyNm": "Russian Ruble" },
      { "Ccy": "RWF", "CcyNm": "Rwandan Franc" },
      { "Ccy": "SHP", "CcyNm": "Saint Helena Pound" },
      { "Ccy": "WST", "CcyNm": "Samoan Tala" },
      { "Ccy": "STN", "CcyNm": "São Tomé and Príncipe Dobra" },
      { "Ccy": "SAR", "CcyNm": "Saudi Riyal" },
      { "Ccy": "RSD", "CcyNm": "Serbian Dinar" },
      { "Ccy": "SCR", "CcyNm": "Seychellois Rupee" },
      { "Ccy": "SLL", "CcyNm": "Sierra Leonean Leone" },
      { "Ccy": "SGD", "CcyNm": "Singapore Dollar" },
      { "Ccy": "SBD", "CcyNm": "Solomon Islands Dollar" },
      { "Ccy": "SOS", "CcyNm": "Somali Shilling" },
      { "Ccy": "ZAR", "CcyNm": "South African Rand" },
      { "Ccy": "KRW", "CcyNm": "South Korean Won" },
      { "Ccy": "SSP", "CcyNm": "South Sudanese Pound" },
      { "Ccy": "LKR", "CcyNm": "Sri Lankan Rupee" },
      { "Ccy": "SDG", "CcyNm": "Sudanese Pound" },
      { "Ccy": "SRD", "CcyNm": "Surinamese Dollar" },
      { "Ccy": "SZL", "CcyNm": "Eswatini Lilangeni" },
      { "Ccy": "SEK", "CcyNm": "Swedish Krona" },
      { "Ccy": "CHF", "CcyNm": "Swiss Franc" },
      { "Ccy": "SYP", "CcyNm": "Syrian Pound" },
      { "Ccy": "TJS", "CcyNm": "Tajikistani Somoni" },
      { "Ccy": "TZS", "CcyNm": "Tanzanian Shilling" },
      { "Ccy": "THB", "CcyNm": "Thai Baht" },
      { "Ccy": "TOP", "CcyNm": "Tongan Paʻanga" },
      { "Ccy": "TTD", "CcyNm": "Trinidad and Tobago Dollar" },
      { "Ccy": "TND", "CcyNm": "Tunisian Dinar" },
      { "Ccy": "TRY", "CcyNm": "Turkish Lira" },
      { "Ccy": "TMT", "CcyNm": "Turkmenistani Manat" },
      { "Ccy": "UGX", "CcyNm": "Ugandan Shilling" },
      { "Ccy": "UAH", "CcyNm": "Ukrainian Hryvnia" },
      { "Ccy": "AED", "CcyNm": "United Arab Emirates Dirham" },
      { "Ccy": "UYU", "CcyNm": "Uruguayan Peso" },
      { "Ccy": "UZS", "CcyNm": "Uzbekistani Som" },
      { "Ccy": "VUV", "CcyNm": "Vanuatu Vatu" },
      { "Ccy": "VES", "CcyNm": "Venezuelan Bolívar Soberano" },
      { "Ccy": "VND", "CcyNm": "Vietnamese Đồng" },
      { "Ccy": "YER", "CcyNm": "Yemeni Rial" },
      { "Ccy": "ZMW", "CcyNm": "Zambian Kwacha" },
      { "Ccy": "ZWL", "CcyNm": "Zimbabwean Dollar" }
    ]
    
 