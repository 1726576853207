import {
	Table,
} from 'antd';
import moment from 'moment';
import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfessionalQualifications } from '../../../../../features/edu/eduSlice';
import { previewPaper } from '../../../../../utils';

export default function ProfessionalTableForm({ userId }) {
	const dispatch = useDispatch();

	const { professionalQualifications, eduLoading } = useSelector(
		(state) => state.edu
	);

	const usrObj = {
		usrId: userId,
	};

	async function handleFetchData() {
		await dispatch(fetchProfessionalQualifications(usrObj));
	}

	const defaultColumnsViewOnly = [
		{
			title: 'Institution Type',
			dataIndex: 'pqlInstType',
			sorter: (a, b) => a.pqlInstType - b.pqlInstType,
			editable: true,
		},
		{
			title: 'Institution Name',
			dataIndex: 'pqlInstName',
			key: 'pqlInstname',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Membership Body',
			dataIndex: 'pqlMembershipBody',
			key: 'pqlMembershipBody',
			sorter: (a, b) => a - b,
			render: (item) => <span>{item ? item : ''}</span>,
			editable: true,
		},
		{
			title: 'Area of Study',
			dataIndex: 'pqlAreaOfStudy',
			key: 'pqlAreaOfStudy',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Award',
			dataIndex: 'pqlAward',
			key: 'pqlAward',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Course',
			dataIndex: 'pqlCourse',
			key: 'pqlCourse',
			sorter: (a, b) => a - b,
			editable: true,
		},

		{
			title: 'Certificate No',
			key: 'pqlCertNo',
			dataIndex: 'pqlCertNo',
			sorter: (a, b) => a.pqlCertNo - b.pqlCertNo,
			editable: true,
		},
		{
			title: 'Start Date',
			dataIndex: 'pqlStartDate',
			key: 'pqlStartDate',
			sorter: (a, b) => a - b,
			render: (item) => (
				<span>{item ? moment(item).format('MMMM YYYY') : ''}</span>
			),
			editable: true,
		},
		{
			title: 'Graduation Date',
			dataIndex: 'endEndDate',
			key: 'endEndDate',
			sorter: (a, b) => a - b,
			render: (item) => (
				<span>{item ? moment(item).format('MMMM YYYY') : ''}</span>
			),
			editable: true,
		},
		{
			title: 'Cerificate',
			dataIndex: 'pqlFileUrl',
			key: 'pqlFileUrl',
			render: (item) => <span className='cursor-pointer' onClick={()=>previewPaper(item)}>{item ? item?.split('_').pop() : ''}</span>,
			editable: true,
		},
	];

	const columns = defaultColumnsViewOnly
	

	useEffect(() => {
		handleFetchData();
	}, [userId]);

	useEffect(() => {
	}, [professionalQualifications]);

	return (
		<>
			<div className='dash-inner-page mt-3 max-w-full w-full overflow-x-auto'>
				<section>
					<Table
						loading={eduLoading}
						className="no-header-bg-table no-hover"
						size={'middle'}
						bordered={false}
						columns={columns}
						dataSource={professionalQualifications}
						scroll={{
							x: 1500,
						}}
						pagination={{
							defaultPageSize: 5,
							hideOnSinglePage: true,
							pageSizeOptions: [10, 20, 50, 100],
						}}
					/>
				</section>
			</div>
		</>
	);
}
