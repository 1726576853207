import { Button, Card, Table, Skeleton, Dropdown, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchDemandLetters } from "../../features/demand/demandLetterSlice";
import { useDispatch, useSelector } from "react-redux";
import { checkEditPermission, dateForHumans, formatPath } from "../../utils";
import "./demandletter.css";
import { cleanUpEditId } from "../../features/client/clientSlice";
import ConfirmSubmitDemandModal from "./modal/ConfirmSubmitDemandModal";
import morevert from "../../assets/morevert.svg";
import emptyContainer from "../../assets/Container.png";
import toast from "react-hot-toast";

function DemandTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { demandLetters, demandLoading, demandLetterCount } = useSelector(
    (state) => state.letter
  );
  const { menuSidebarData } = useSelector((state) => state.admin);
  const { clientEditId, refetchKey } = useSelector((state) => state.client);
  const [receivedId, setReceivedId] = useState(clientEditId);

  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [prodd, setProdd] = useState("");

  const [proddClient, setProddClient] = useState(""); 
 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    if (checkEditPermission(menuSidebarData,"DEMAND_LETTER")) {
      setIsModalOpen(true);
    } else {
      toast.error("You dont have right to perform this action");
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const addDemand = () => {
    navigate("/add-demand");
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchLetters(page, size) {
    const res = await dispatch(
      fetchDemandLetters({
        dmlUsrId: user?.usrInstId,
        dmlCntId: receivedId,
        limit: size ?? pageSize,
        start: page ?? pageIndex,
      })
    );
  }

  async function cleanClientEditId(){
    await dispatch(cleanUpEditId());
  }

 
  useEffect(() => { 
    if (clientEditId && refetchKey == 2) {
      fetchLetters();
    } else if (!clientEditId) {
      fetchLetters();
    }
  }, [clientEditId,refetchKey]);



   

  useEffect(() => {
    setReceivedId(clientEditId);
  }, [clientEditId]);

  const handleViewDemandLetter = (id) => {
    navigate(`/demandletter-job-view/${id}`);
  };

  const hasRemarksData = demandLetters?.some(
    (item) =>
      item?.dmlDesc !== null &&
      item?.dmlDesc !== undefined &&
      item?.dmlDesc !== ""
  );

  const settingItems = [
    {
      key: "1",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={() => handleViewDemandLetter(prodd?.dmlId)}
        >
          View
        </div>
      ),
    },

    {
      key: "1",
      label: (
        <div
          className={`font-dmSans text-black font-[400] text-[19px] 
           ${
             !(prodd?.dmlStatus === "ACTIVE" || prodd?.dmlStatus === "REJECTED")
               ? "cursor-not-allowed"
               : "cursor-pointer"
           }`}
          onClick={
            !(prodd?.dmlStatus === "ACTIVE" || prodd?.dmlStatus === "REJECTED")
              ? null
              : showModal
          }
        >
          Submit for approval
        </div>
      ),
    },
  ];

  const stripHtml = (html) => {
    const tmp = document?.createElement("DIV");
    tmp.innerHTML = html;
    return tmp?.textContent || tmp?.innerText || "";
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text?.substring(0, maxLength - 3) + "...";
    }
    return text;
  };

  const columns = [
    {
      title: "Demand Letter",
      render: (item) => (
        <div className="flex items-center justify-between">
          <span className="">{formatPath(item?.dmlName)}</span>
          <button
            className="p-0 mx-4 flex items-end"
            type="button"
            onClick={() => setProdd(item)}
          >
            <Dropdown
              overlayStyle={{
                width: "350px",
              }}
              trigger={"click"}
              menu={{ items: settingItems }}
              placement="bottom"
            >
              <img src={morevert} alt="morevert" />
            </Dropdown>
          </button>
        </div>
      ),
    },

    {
      title: "Client",
      dataIndex: "cntName",
    },
    {
      title: "Status",
      render: (item) => (
        <div>
          {item?.dmlStatus === "APPROVED" ? (
            <span className="profile-status py-2 px-4 mt-2 w-auto">
              {item?.dmlStatus}
            </span>
          ) : item?.dmlStatus == "REJECTED" ? (
            <span className="profile-status-rejected p-1 mt-2 w-auto">
              {item?.dmlStatus}
            </span>
          ) : (
            <span className="profile-unstatus py-2 px-4 mt-2 w-auto">
              {item?.dmlStatus}
            </span>
          )}
        </div>
      ),
    },

    hasRemarksData
      ? {
          title: "Remarks",
          render: (item) => {
            const fullHtml = item?.dmlDesc || "";
            const plainText = stripHtml(fullHtml);
            const truncatedText = truncateText(plainText, 100);

            return (
              <Tooltip
                title={<div dangerouslySetInnerHTML={{ __html: fullHtml }} />}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: truncatedText,
                  }}
                />
              </Tooltip>
            );
          },
          width: "30%",
        }
      : null,
    {
      title: "Uploaded by",
      dataIndex: "uploadedBy",
    },
    {
      title: "Date Uploaded",
      dataIndex: "dmlCreatedDate",
      render: (item) => <div>{dateForHumans(item)}</div>,
    },
  ];

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));
  return (
    <>
      {demandLetters && demandLetters?.length > 0 ? (
        <div>
          {demandLoading ? (
            <Skeleton />
          ) : (
            <div className="px-10">
              <div className="flex justify-between items-center w-full mt-[51px] mb-8">
                <h3 className="font-[700] text-[24px] text-black1 dash-title ">
                  Demand letters
                </h3>

                {!receivedId && checkEditPermission(menuSidebarData,"DEMAND_LETTER") && (
                  <div onClick={() => dispatch(cleanUpEditId())}>
                    <button onClick={addDemand} className="w-[300px] save-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M19.5 11H13.5V5C13.5 4.73478 13.3946 4.48043 13.2071 4.29289C13.0196 4.10536 12.7652 4 12.5 4C12.2348 4 11.9804 4.10536 11.7929 4.29289C11.6054 4.48043 11.5 4.73478 11.5 5V11H5.5C5.23478 11 4.98043 11.1054 4.79289 11.2929C4.60536 11.4804 4.5 11.7348 4.5 12C4.5 12.2652 4.60536 12.5196 4.79289 12.7071C4.98043 12.8946 5.23478 13 5.5 13H11.5V19C11.5 19.2652 11.6054 19.5196 11.7929 19.7071C11.9804 19.8946 12.2348 20 12.5 20C12.7652 20 13.0196 19.8946 13.2071 19.7071C13.3946 19.5196 13.5 19.2652 13.5 19V13H19.5C19.7652 13 20.0196 12.8946 20.2071 12.7071C20.3946 12.5196 20.5 12.2652 20.5 12C20.5 11.7348 20.3946 11.4804 20.2071 11.2929C20.0196 11.1054 19.7652 11 19.5 11Z"
                          fill="#EDF8FF"
                        />
                      </svg>
                      Add demand letter
                    </button>
                  </div>
                )}
              </div>

              <Table
                rowSelection={true}
                size={"middle"}
                bordered={true}
                pagination={{
                  position: ["bottomCenter"],
                  current: pageIndex + 1,
                  total: demandLetterCount,
                  pageSize: pageSize,
                  onChange: (page, size) => {
                    setPageIndex(page - 1);
                    setPageSize(size);
                    fetchLetters(page - 1, size);
                  },
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                }}
                rowKey={(record) => record?.dmlId}
                columns={tableColumns}
                dataSource={demandLetters?.length && demandLetters}
                scroll={{
                  x: 800,
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="mr-3 h-[885px] bg-white flex  mt-[3.19rem] flex-col px-10">
          <h3 className="font-[700] text-[24px] text-black1 dash-title ">
            Let’s start by creating demand letter
          </h3>

          <div
            className="mt-[31px] bg-white full h-[471.33px] rounded-[15px] border border-solid
				 border-[#F5F7FF] py-[60px] flex flex-col justify-center items-center cursor-pointer"
            onClick={addDemand}
          >
            <div>
              <img
                src={emptyContainer}
                alt="group-pic"
                className="object-contain h-[291.33px] w-[292px]"
              />
              <div className="empty-desc flex items-center justify-center">
                Add demand letter to add your jobs
              </div>
            </div>

            {checkEditPermission(menuSidebarData,"DEMAND_LETTER") && (
              <div className="mt-[2.63rem]">
                <button
                  className="w-[270px] h-[50px] px-[24px] py-3 bg-blueDark rounded-[28px] justify-center items-center gap-1 inline-flex
  text-white text-[18px] leading-normal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M19.5 11H13.5V5C13.5 4.73478 13.3946 4.48043 13.2071 4.29289C13.0196 4.10536 12.7652 4 12.5 4C12.2348 4 11.9804 4.10536 11.7929 4.29289C11.6054 4.48043 11.5 4.73478 11.5 5V11H5.5C5.23478 11 4.98043 11.1054 4.79289 11.2929C4.60536 11.4804 4.5 11.7348 4.5 12C4.5 12.2652 4.60536 12.5196 4.79289 12.7071C4.98043 12.8946 5.23478 13 5.5 13H11.5V19C11.5 19.2652 11.6054 19.5196 11.7929 19.7071C11.9804 19.8946 12.2348 20 12.5 20C12.7652 20 13.0196 19.8946 13.2071 19.7071C13.3946 19.5196 13.5 19.2652 13.5 19V13H19.5C19.7652 13 20.0196 12.8946 20.2071 12.7071C20.3946 12.5196 20.5 12.2652 20.5 12C20.5 11.7348 20.3946 11.4804 20.2071 11.2929C20.0196 11.1054 19.7652 11 19.5 11Z"
                      fill="#EDF8FF"
                    />
                  </svg>
                  Add demand letter
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      <ConfirmSubmitDemandModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        prodd={prodd}
      />
    </>
  );
}

export default DemandTable;
