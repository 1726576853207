import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	inbLoading: false,
	receivedInbox: [],
	receivedInboxThreads: [],
	inboxObj: {},
};

export const saveInboxHeader = createAsyncThunk(
	'inboxSlice/saveInboxHeader',
	async (data) => {
		const res = await axiosInstance.post(`${url}/emp/saveInboxHeader.action`, data);
		return res.data;
	},
);

export const saveInbox = createAsyncThunk(
	'inboxSlice/saveInbox',
	async (data) => {
		const res = await axiosInstance.post(`${url}/nea/saveInbox.action`, data);
		return res.data;
	},
);

export const fetchReceivedInboxs = createAsyncThunk(
	'inboxSlice/fetchReceivedInboxs',
	async (inbhReceiverId) => {
		const res = await axiosInstance.get(
			`${url}/emp/fetchCustomInboxHeaders.action?userInbox=${inbhReceiverId}`,
		);
		return res.data.data.result;
	},
);

export const fetchReceivedInboxThreads = createAsyncThunk(
	'inboxSlice/fetchReceivedInboxThreads',
	async (inbInbhId) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchInboxs.action?inbInbhId=${inbInbhId}`,
		);
		return res.data.data.result;
	},
);

export const inboxSlice = createSlice({
	name: 'inbox',
	initialState,
	reducers: {
		setInboxObj: (state, action) => {
			state.inboxObj = action.payload;
		},

		inboxCleanUp: (state) => {
			state.inboxObj = {};
			state.receivedInboxThreads = [];
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(saveInboxHeader.pending, (state) => {
				state.inbLoading = true;
			})
			.addCase(saveInboxHeader.fulfilled, (state) => {
				state.inbLoading = false;
			})
			.addCase(saveInboxHeader.rejected, (state) => {
				state.inbLoading = false;
			})

			.addCase(saveInbox.pending, (state) => {
				state.inbLoading = true;
			})
			.addCase(saveInbox.fulfilled, (state) => {
				state.inbLoading = false;
			})
			.addCase(saveInbox.rejected, (state) => {
				state.inbLoading = false;
			})

			.addCase(fetchReceivedInboxs.pending, (state) => {
				state.inbLoading = true;
			})
			.addCase(fetchReceivedInboxs.fulfilled, (state, action) => {
				state.inbLoading = false;
				state.receivedInbox = action?.payload;
			})
			.addCase(fetchReceivedInboxs.rejected, (state) => {
				state.inbLoading = false;
				state.receivedInbox = [];
			})

			.addCase(fetchReceivedInboxThreads.pending, (state) => {
				state.inbLoading = true;
			})
			.addCase(fetchReceivedInboxThreads.fulfilled, (state, action) => {
				state.inbLoading = false;
				state.receivedInboxThreads = action?.payload;
			})
			.addCase(fetchReceivedInboxThreads.rejected, (state) => {
				state.inbLoading = false;
				state.receivedInboxThreads = [];
			});
	},
});

export default inboxSlice.reducer;
export const { setInboxObj, inboxCleanUp } = inboxSlice.actions;
