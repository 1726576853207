import { useSelector } from "react-redux";
import IncompleteDash from "./IncompleteDash";
import ActiveDashBoard from "./ActiveDashboard";
import { useEffect, useState } from "react";

export default function DashBoard() {
  const { agencyObj } = useSelector((state) => state.agency);

  const [processStep, setprocessStep] = useState(false);

  let st0 = "AWAITING_BACKGROUND_CHECK_RESULTS";
  let st1 = "SEND_TO_BACKGROUND_CHECK";
  let st2 = "SEND_TO_ACCOUNT_INSPECTION";
  let st3 = "SEND_TO_ACCOUNT_VETTING";
  let st4 = "APPROVE_ACCOUNT_APPLICATION";
  let st5 = "ACCOUNT_VERIFICATION";
  let st6 = "APPROVE_ACCOUNT_CREATION";
  let st7 = "UPDATED_ACCOUNT_CREATION";
  let st8 = "REVOKE";
  let st9 = "DECLINE_ACCOUNT_APPLICATION";
  let st10 = "DEFERMENT";
  let st11 = "PAYMENT_PENDING_CONFIRMATION";
  let st12 = "REJECT_RECEIPT";
  let st13 = 'PARTIAl_APPROVE_ACCOUNT_VERIFICATION'
  let st14 = 'ACCOUNT_VERIFICATION_ASSIGNED'

  let agnSt = agencyObj?.instStatus;

  useEffect(() => {
    if (
      agnSt === st0 ||
      agnSt === st1 ||
      agnSt === st2 ||
      agnSt === st3 ||
      agnSt === st4 ||
      agnSt === st5 ||
      agnSt === st6 ||
      agnSt === st7 ||
      agnSt === st8 ||
      agnSt === st9 ||
      agnSt === st10 ||
      agnSt === st11 ||
      agnSt === st12 ||
      agnSt === st13 ||
      agnSt === st14
    ) {
      setprocessStep(true);
    } else {
      setprocessStep(false);
    }
  }, [processStep, agencyObj]);

  useEffect(() => {
    if (
      agnSt === st0 ||
      agnSt === st1 ||
      agnSt === st2 ||
      agnSt === st3 ||
      agnSt === st4 ||
      agnSt === st5 ||
      agnSt === st6 ||
      agnSt === st7 ||
      agnSt === st8 ||
      agnSt === st9 ||
      agnSt === st10 ||
      agnSt === st11 ||
      agnSt === st12 ||
      agnSt === st13 ||
      agnSt === st14
    ) {
      setprocessStep(true);
    } else {
      setprocessStep(false);
    }
  }, []);

  if (processStep) {
    return <IncompleteDash />;
  } else {
    return <ActiveDashBoard />;
  }
}
