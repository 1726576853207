import React, { useRef, useState } from "react";
import { Button, Form, Input, Modal, Spin } from "antd";
import "../../../clients/client.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import uplodSvg from "../../../../assets/uploadPic.png";
import toast from "react-hot-toast";
import { saveFile } from "../../../../features/application/applicationSlice";
import { saveEmployee } from "../../../../features/myEmployees/myEmployeesSlice";
import { formatImgPath } from "../../../../utils";

const AddEmployeeNameModal = ({ open, handleGetNames }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { employeeLoading, employeeReturnsPage } = useSelector(
    (state) => state.employee
  );

  const [loadingAttach, setLoadingAttach] = useState(false);

  const [profile, setProfile] = useState(null);

  const handleImageSelect = async (event) => {
    setLoadingAttach(true);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    const res = await dispatch(saveFile(formData));
    if (res?.payload?.success) {
      setLoadingAttach(false);
      // setProfile(formatImgPath(res?.payload?.jsonData ?? ""));
      setProfile(res?.payload?.jsonData ?? "");
    } else {
      setLoadingAttach(false);
      toast.error("Please try upload your image again");
    }
  };

  function handleCancel() {
    if (employeeReturnsPage) {
      navigate("/employment-returns");
    } else {
      navigate("/employees");
    }
  }

  const onFinish = async (data) => { 
		handleGetNames({ 
			emplInstId: user?.usrInstId,
			...data,
		}); 
  }
  // const onFinish = async (data) => {
  //   if (!profile) {
  //     toast.error("Attach employee photo");
  //   } else {
  //     handleGetNames({
  //       emplIcon: profile,
  //       emplInstId: user?.usrInstId,
  //       ...data,
  //     });
  //   }
  // };

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title="Add employee"
        open={open}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <div className="w-full text-black1 text-[21px] font-medium font-dmSans leading-9 mb-[35px]">
          Creating an employee will allow you to fill in their details, and file
          their Employment Returns
        </div>

        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="items-center flex mb-[23px]">
            {/* {profile === null ? (
              <label className="flex flex-row items-center justify-start w-[20%]">
                <input
                  name="secIcon"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleImageSelect(e)}
                />
                <img
                  className="z-10 w-[100px] h-[100px] rounded-full"
                  alt="tab1"
                  src={uplodSvg}
                  style={{ cursor: "pointer" }}
                />
              </label>
            ) : loadingAttach ? (
              <Spin className="spin-modal" />
            ) : (
              <label className="flex flex-row items-center justify-start gap-2  w-[20%]">
                <input
                  name="secIcon"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageSelect}
                />
                <div className="rounded-full  overflow-hidden border-4 border-blueDark">
                  <img
                    src={formatImgPath(profile)}
                    alt="employee-avatar"
                    className="w-28 h-28 cursor-pointer object-contain"
                  />
                </div>
              </label>
            )} */}

            <div className="w-full grid grid-cols-3 gap-5">
              <Form.Item
                name="emplFirstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Please add employee name",
                  },
                ]}
              >
                <Input className="input" />
              </Form.Item>

              <Form.Item
                name="emplMiddleName"
                label="Middle Name"
                rules={[
                  {
                    required: true,
                    message: "Please add employee name",
                  },
                ]}
              >
                <Input className="input" />
              </Form.Item>

              <Form.Item
                name="emplLastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please add employee name",
                  },
                ]}
              >
                <Input className="input" />
              </Form.Item>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center">
              <button
                type="button"
                key="back"
                onClick={handleCancel}
                className="w-[200px] cancel-btn"
              >
                Cancel
              </button>

              <button
                key="submit"
                type="submit"
                className="w-[200px] save-btn"
                disabled={loadingAttach || employeeLoading}
              >
                {employeeLoading || loadingAttach ? <Spin /> : "Continue"}
              </button>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default AddEmployeeNameModal;
