import { Table, Skeleton, Input } from "antd";
import React, { useEffect, useState } from "react";
import MaterialIcon from "material-icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dateForHumans, formatImgPath } from "../../../utils";   
import { fetchJobApplicantAttestation } from "../../../features/attestation/attestationSlice";
import emptyContainer from "../../../assets/Container.png";

function FailedAttestationTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  const { user } = useSelector((state) => state.auth);
  const { refetch, refetchKey,jobApplicationAttestation,attestationCandidatesLoading,stage, refetchKeyParent,refetchParent, } = useSelector((state) => state.attestation);

  const [searchText, setSearchText] = useState("");

  const handleView = (id)=>{
    navigate(`/attestation-view-list/${id}`) 
  }
 
 
  const columns = [
    {
      title: "Candidate name",
      render: (item) => (
        <>
          <div className="flex justify-between">
            <div className="flex items-center">
              <img
                className="w-9 h-9 rounded-full object-cover"
                src={
                  item?.usrProfileImage
                    ? formatImgPath(item?.usrProfileImage)
                    : "https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png"
                }
                alt="Avatar"
              />
              <span className="table-name mx-3">{item?.usrFirstName} {item?.usrLastName}</span>
            </div>
            <div className="flex items-center justify-end">
              <button className="p-0 mx-4 flex items-end" type="button">
                <MaterialIcon
                  color="#000"
                  icon="visibility"
                  onClick={()=>handleView(item?.japId)}
                />
              </button> 
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Job Position",
      dataIndex: "jobTitle",
    },
    {
      title: "Employer Name",
      dataIndex: "employerName",
    },
    {
      title: "Employer Country",
      dataIndex: "employerCountry",
    },
    {
      title: "Employer Address",
      dataIndex: "employerAddress",
    },
    {
      title: "Contract Start Date",
      render:(item)=>{
        return( 
           <div>{dateForHumans(item?.japValidityStartDate)}</div>
        )
      }, 
    },
    {
      title: "Contract End Date",
      render:(item)=>{
        return( 
          <div>{dateForHumans(item?.japValidityEndDate)}</div>
        )
      }, 
    },
   
  ];


  const filteredData = searchText
    ? jobApplicationAttestation?.filter((item) =>
        item?.japFullName?.toLowerCase().includes(searchText.toLowerCase())
      )
    : jobApplicationAttestation;

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  async function fetchCandidateAttestation() {
    const res = await dispatch(
      fetchJobApplicantAttestation({ 
        japProgressStatus:"FAILED_ATTESTATION",
        japInstId:user?.usrInstId
      })
    );
  }
 
  useEffect(() => {
    if(refetchKey == 2 && stage== "past" && refetchKeyParent == 2)
    fetchCandidateAttestation();
  }, [refetch,refetchKey,refetchParent,refetchKeyParent]);

  
  return (
    <>
     <div className="min-h-[895px] bg-white border-none p-[39px]">
        {jobApplicationAttestation && jobApplicationAttestation?.length > 0 ? (
          <>
            {" "}
            <h3 className="font-[700] text-[24px] text-black1 dash-title">
            Applicant who have failed attestation
            </h3>

            <div className="flex justify-between items-center w-full mt-[31px]">
              <div className="flex items-center w-full ">
                <Input
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Search Candidate"
                  className="text-[16px] font-[400] flex-row-reverse"
                  prefix={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.2508 3.75C7.50609 3.75 4.47041 6.93997 4.47041 10.875C4.47041 14.81 7.50609 18 11.2508 18C14.9955 18 18.0312 14.81 18.0312 10.875C18.0312 6.93997 14.9955 3.75 11.2508 3.75ZM3.04297 10.875C3.04297 6.11154 6.71773 2.25 11.2508 2.25C15.7838 2.25 19.4586 6.11154 19.4586 10.875C19.4586 15.6385 15.7838 19.5 11.2508 19.5C6.71773 19.5 3.04297 15.6385 3.04297 10.875Z"
                        fill="#333333"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.045 15.913C16.3237 15.6201 16.7756 15.6201 17.0543 15.913L21.3902 20.4693C21.6689 20.7622 21.6689 21.237 21.3902 21.5299C21.1115 21.8228 20.6596 21.8228 20.3809 21.5299L16.045 16.9737C15.7663 16.6808 15.7663 16.2059 16.045 15.913Z"
                        fill="#333333"
                      />
                    </svg>
                  }
                />
              </div> 
            </div> 
            <div className="mt-[20px]">
              {attestationCandidatesLoading ? (
                <Skeleton />
              ) : (
                <Table
                  rowSelection={true}
                  size={"middle"}
                  bordered={true}
                  pagination={{
                    position: ["bottomCenter"],
                  }}
                  rowKey={(record) => record?.japId}
                  columns={tableColumns}
                  dataSource={filteredData}
                  scroll={"scroll"}
                  className="custom-table"
                  loading={attestationCandidatesLoading}
                />
              )}
            </div>
          </>
        ) : (
          <div className=" h-[885px] bg-white flex   flex-col">
            <h3 className="font-[700] text-[24px] text-black1 dash-title">
            Applicant who have failed attestation
            </h3>

            <div
              className="bg-white full h-[471.33px] rounded-[15px] border border-solid
                 border-[#F5F7FF]   flex flex-col justify-center items-center cursor-pointer"
            >
              <div>
                <img
                  src={emptyContainer}
                  alt="group-pic"
                  className="object-contain h-[291.33px] w-[292px]"
                />
                <div className="empty-desc flex items-center justify-center">
                Applicant who fail attestation will be displayed here
                </div>
              </div>
            </div>
          </div> 
        )}
      </div>
    </>
  );
}

export default FailedAttestationTable;
