import React, { useEffect } from "react";
import frame from "../../assets/Frame.svg";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cleanUpJobEditId } from "../../features/jobs/jobSlice";
import InsideHeader from "../../components/InsideHeader";

function AdminLanding() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUsers = () => {
    navigate("/users-list");
  };
  const handleLogo = () => {
    navigate("/admin-profile");
  };
 
  const handlePanel = () => {
    navigate("/panel-list");
  };

  const handleBranches = () => {
    navigate("/branches");
  };

  async function cleanJobEditId(){
    await dispatch(cleanUpJobEditId())
  }
  useEffect(()=>{
    cleanJobEditId()
  },[])

  return (
    <>
      <InsideHeader
        title="Administrator"
        subtitle="Perform all administrative operations"
        back={false}
      />

      <div className="bg-white mr-3 mb-[10px]">
        <div className="ml-[54px] flex flex-col gap-y-[23px] text-center h-[1000px] py-[76px]">
          <div className="flex gap-x-5 ">
           
            <div
              className="card !w-[524px] h-[173px] !rounded-md border border-[#DBEBF7] p-[30px] cursor-pointer"
              onClick={handleLogo}
            >
              <div className="flex items-center justify-start gap-x-5">
                <img src={frame} alt="frame" />
                <div className="flex flex-col">
                  <div className="text-black1 text-[22px] font-medium font-dmSans leading-9 flex justify-start">
                    Agency Logo
                  </div>
                  <div className="text-black1 text-[20px] font-normal font-dmSans leading-[30px] text-left">
                    Add or edit your displayed logo
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card !w-[524px] h-[173px] !rounded-md border border-[#DBEBF7] p-[30px] cursor-pointer"
              onClick={handleUsers}
            >
              <div className="flex items-center justify-start gap-x-5">
                <img src={frame} alt="frame" />
                <div className="flex flex-col ">
                  <div className="text-black1 text-[22px] font-medium font-dmSans leading-9 flex justify-start">
                    Users
                  </div>
                  <div className="text-black1 text-[20px] font-normal font-dmSans leading-[30px] text-left">
                    Manage users under your account
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-x-5">
            {/* <div className="card !w-[524px] h-[173px] !rounded-md border border-[#DBEBF7] p-[30px] cursor-pointer">
              <div className="flex items-center justify-start gap-x-5">
                <img src={frame} alt="frame" />
                <div className="flex flex-col ">
                  <div className="text-black1 text-[22px] font-medium font-dmSans leading-9 flex justify-start">
                    Business Representative
                  </div>
                  <div className="text-black1 text-[20px] font-normal font-dmSans leading-[30px]">
                    Edit your business rep details
                  </div>
                </div>
              </div>
            </div> */}

            <div
              className="card !w-[524px] h-[173px] !rounded-md border border-[#DBEBF7] p-[30px] cursor-pointer"
              onClick={handlePanel}
            >
              <div className="flex items-center justify-start gap-x-5">
                <img src={frame} alt="frame" />
                <div className="flex flex-col ">
                  <div className="text-black1 text-[22px] font-medium font-dmSans leading-9 flex justify-start">
                    Interview Panel
                  </div>
                  <div className="text-black1 text-[20px] font-normal font-dmSans leading-[30px] text-left">
                    Edit your panel details
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card !w-[524px] h-[173px] !rounded-md border border-[#DBEBF7] p-[30px] cursor-pointer"
              onClick={handleBranches}
            >
              <div className="flex items-center justify-start gap-x-5">
                <img src={frame} alt="frame" />
                <div className="flex flex-col ">
                  <div className="text-black1 text-[22px] font-medium font-dmSans leading-9 flex justify-start">
                  Branches
                  </div>
                  <div className="text-black1 text-[20px] font-normal font-dmSans leading-[30px] text-left">
                  Manage organization branches under your agency
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLanding;
