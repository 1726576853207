import { Button, Form, Input, Select, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  fetchClients,
  saveClient,
  setEditContact,
  setViewContact,
} from "../../features/client/clientSlice";
import PhoneInput from "react-phone-input-2";

function Contact() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { agencyObj } = useSelector((state) => state.agency);

  const { clientLoading, clients, clientData, clientEditId } = useSelector(
    (state) => state.client
  );
  const [data, setdata] = useState({});

  const [receivedEditId, setReceivedEditId] = useState(
    Object?.keys(clients)?.length > 0 ? clients?.cntId : clientEditId
  );

  useEffect(() => {
    setReceivedEditId(
      Object?.keys(clients)?.length > 0 ? clients?.cntId : clientEditId
    );
  }, [clients, clientEditId]);

  const singleClient = clientData?.find(
    (item) => item?.cntId === receivedEditId
  );

  const [formData, setFormData] = useState({ ...singleClient });

  useEffect(() => {
    setFormData({ ...singleClient });
  }, [singleClient]);

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCancel = async () => {
    await dispatch(setEditContact(false));
    await dispatch(setViewContact(true));
  };

  const handleNumberChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      cntOfficeMobile: String(e),
    }));
  };

  const onFinish = async (data) => {
    if (!formData?.cntOfficeMobile) {
      toast.error("Provide office mobile");
      return;
    }
    const res = await dispatch(
      saveClient({
        cntId: formData?.cntId,
        cntCreatedBy: user?.usrId,
        cntInstId: user?.usrInstId,
        cntOfficeMobile: formData?.cntOfficeMobile,
        cntEmail: formData?.cntEmail,
        cntContactName: formData?.cntContactName,
        cntContactPostition: formData?.cntContactPostition,
      })
    );
    if (res?.payload?.success) {
      toast.success("Contact info saved successfully");
      form.resetFields();
      await dispatch(setViewContact(true));
      await dispatch(setEditContact(false));
    } else {
      toast.error("Check your details");
    }
  };

  async function fetchClientDetails() {
    const res = await dispatch(
      fetchClients({
        cntInstId: user?.usrInstId,
      })
    );
  }

  useEffect(() => {
    fetchClientDetails();
  }, []);

  return (
    <div className="mt-[38px]">
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
        }}
        form={form}
      >
        <div className="grid grid-cols-2 lg:gap-4">
          <Form.Item
            label="Name"
            rules={[
              {
                required: true,
                message: "Please add name",
              },
            ]}
          >
            <Input
              required
              name="cntContactName"
              onChange={onChange}
              value={formData?.cntContactName}
              className="input"
            />
          </Form.Item>
          <Form.Item
            label="Position"
            rules={[
              {
                required: true,
                message: "Please add position",
              },
            ]}
          >
            <Input
              required
              name="cntContactPostition"
              onChange={onChange}
              value={formData?.cntContactPostition}
              className="input"
            />
          </Form.Item>
          <Form.Item
            label="Email address"
            rules={[
              {
                required: true,
                message: "Please add email address",
              },
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input
              required
              name="cntEmail"
              onChange={onChange}
              value={formData?.cntEmail}
              type="email"
              className="input"
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Phone No.<span className="text-[#FF0000]">*</span>
              </span>
            }
            rules={[
              {
                required: true,
                message: "Please add phone no",
              },
            ]}
          >
            <PhoneInput
              required
              name="cntOfficeMobile"
              value={formData?.cntOfficeMobile}
              onChange={handleNumberChange}
              countryCodeEditable={true}
              country="ke"
              className="input rounded-[6px] border !border-[#cacaca] !h-[42px]"
            />
          </Form.Item>
        </div>

        <div className="flex items-center justify-between mt-[35px]">
          <div className="flex items-start"></div>
          <div className="flex gap-5 items-center">
            <button
              type="button"
              className="cancel-btn  w-[168px]"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              className="save-btn  w-[168px]"
              type="submit"
              disabled={clientLoading ? true : false}
            >
              {clientLoading ? <Spin /> : "Save"}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default Contact;
