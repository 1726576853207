import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	instLoading: false,
	instObj: {},
};

export const fetchAgencyIntitution = createAsyncThunk(
    "authSlice/fetchAgencyIntitution",
    async (instId) => {
      const res = await axiosInstance.get(
        `${url}/nea/fetchInstitutions.action?instId=${instId}`
      );
      return res.data.data.result;
    }
  );

export const institutionSlice = createSlice({
	name: 'inst',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(fetchAgencyIntitution.pending, (state) => {
				state.licenseLoading = true;
			})
			.addCase(fetchAgencyIntitution.fulfilled, (state, action) => {
				state.licenseLoading = false;
				state.instObj = action?.payload[0]
			})
			.addCase(fetchAgencyIntitution.rejected, (state) => {
				state.licenseLoading = false;
				state.instObj = {};
			});
	},
});

export default institutionSlice.reducer;
export const {} = institutionSlice.actions;
