import {
	Table,
} from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEducations } from '../../../../../features/edu/eduSlice';
import { previewPaper } from '../../../../../utils';


export default function AcademicTableForm({userId }) {
	const dispatch = useDispatch();

	const { educations, eduLoading } = useSelector((state) => state.edu);

	const usrObj = {
		usrId: userId,
	};

	async function handleFetch(){
		await dispatch(fetchEducations(usrObj))
	}

	const defaultColumnsViewOnly = [
		{
			title: 'Institution Type',
			dataIndex: 'eduInstType',
			sorter: (a, b) => a.eduInstType - b.eduInstType,
			editable: true,
		},
		{
			title: 'Institution Name',
			dataIndex: 'eduInstName',
			key: 'eduInstname',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Area of Study',
			dataIndex: 'eduAreaOfStudy',
			key: 'eduAreaOfStudy',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Award',
			dataIndex: 'eduAward',
			key: 'eduAward',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Course',
			dataIndex: 'eduCourse',
			key: 'eduCourse',
			sorter: (a, b) => a - b,
			editable: true,
		},

		{
			title: 'Certificate No',
			key: 'eduCertNo',
			dataIndex: 'eduCertNo',
			sorter: (a, b) => a.eduCertNo - b.eduCertNo,
			editable: true,
		},
		{
			title: 'Start Date',
			dataIndex: 'eduStartDate',
			key: 'eduStartDate',
			sorter: (a, b) => a - b,
			render: (item) => (
				<span>{item ? moment(item).format('MMMM YYYY') : ''}</span>
			),
			editable: true,
		},
		{
			title: 'Graduation Date',
			dataIndex: 'endEndDate',
			key: 'endEndDate',
			sorter: (a, b) => a - b,
			render: (item) => (
				<span>{item ? moment(item).format('MMMM YYYY') : ''}</span>
			),
			editable: true,
		},
		{
			title: 'Cerificate',
			dataIndex: 'eduFileUrl',
			key: 'eduFileUrl',
			render: (item) => <span className='cursor-pointer' onClick={()=>previewPaper(item)}>{item ? item?.split('_').pop() : ''}</span>,
			editable: true,
		},
	];

	const columns = defaultColumnsViewOnly


	useEffect(() => {
	}, [educations]);

	useEffect(() => {
		handleFetch()
	}, [userId]);

	return (
		<>
			<div className='dash-inner-page mt-3 max-w-full w-full overflow-x-auto'>
				<section>
					<Table
						loading={eduLoading}
						className="no-header-bg-table no-hover"
						size={'middle'}
						bordered={false}
						columns={columns}
						dataSource={educations}
						scroll={{
							x: 1500,
						}}
						pagination={{
							defaultPageSize: 5,
							hideOnSinglePage: true,
							pageSizeOptions: [10, 20, 50, 100],
						}}
					/>
				</section>
			</div>
		</>
	);
}
