import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	dropDownLoading: false,
	qualifications: [],
	jobTypes: [],
	jobStyles: [],
	jobExperienceLevels: [],
	ethnicity: [],
	genders: [],
	maritalStatus: [],
	unEmpDuration: [],
	jobSeekingOptions: [],
	disTypeOptions: [],
	unEmpReason: [],
	instTypes: [],
	studyAreas: [],
	awards: [],
	courses: [],
	professionalBodies: [],
	profSpecializations: [],
	relationships: [],
	counsellingCategories: [],
	nationalityOptions: [],
	languageOptions: [],
	meansOfMaintenance: [],
	statutoryDocs: [],
	grades: [],
	workTypeCategory:[],
	agencyDetailsSetup: [],
	economicActivity:[],
	// grades: [],
};

export const fetchJobExperienceLevels = createAsyncThunk(
	'dropDownSlice/fetchJobExperienceLevels',
	async () => {
		const res = await axiosInstance.get(`${url}/emp/fetchJobExperienceLevels.action`);
		return res.data.data.result;
	}
);

export const fetchJobTypes = createAsyncThunk(
	'dropDownSlice/fetchJobTypes',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchJobTypes.action`);
		return res.data.data.result;
	}
);

export const fetchJobWorkStyles = createAsyncThunk(
	'dropDownSlice/fetchJobWorkStyles',
	async () => {
		const res = await axiosInstance.get(`${url}/emp/fetchJobWorkStyles.action`);
		return res.data.data.result;
	}
);

export const fetchQualifications = createAsyncThunk(
	'dropDownSlice/fetchQualifications',
	async () => {
		const res = await axiosInstance.get(`${url}/emp/fetchQualifications.action`);
		return res.data.data.result;
	}
);

export const fetchEthnicitys = createAsyncThunk(
	'dropDownSlice/fetchEthnicitys',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchEthnicitys.action`);
		return res.data.data.result;
	}
);

export const fetchMaritalStatus = createAsyncThunk(
	'dropDownSlice/fetchMaritalStatus',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchMaritalStatuss.action`);
		return res.data.data.result;
	}
);

export const fetchGender = createAsyncThunk(
	'dropDownSlice/fetchGender',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchGenders.action`);
		return res.data.data.result;
	}
);

export const fetchUnemploymentDurations = createAsyncThunk(
	'dropDownSlice/fetchUnemploymentDurations',
	async () => {
		const res = await axiosInstance.get(
			`${url}/setup/fetchUnemploymentDurations.action`
		);
		return res.data.data.result;
	}
);

export const fetchDisabilityTypes = createAsyncThunk(
	'dropDownSlice/fetchDisabilityTypes',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchDisabilityTypes.action`);
		return res.data.data.result;
	}
);

export const fetchInstitutionTypes = createAsyncThunk(
	'dropDownSlice/fetchInstitutionTypes',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchInstitutionTypes.action`);
		return res.data.data.result;
	}
);

export const fetchStudyAreas = createAsyncThunk(
	'dropDownSlice/fetchStudyAreas',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchStudyAreas.action`);
		return res.data.data.result;
	}
);

export const fetchAwards = createAsyncThunk(
	'dropDownSlice/fetchAwards',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchAwards.action`);
		return res.data.data.result;
	}
);

export const fetchCourses = createAsyncThunk(
	'dropDownSlice/fetchCourses',
	async (corsAwdId) => {
		const res = await axiosInstance.get(
			`${url}/setup/fetchCourses.action?corsAwdId=${corsAwdId}`
		);
		return res.data.data.result;
	}
);

export const fetchProfessionalBodies = createAsyncThunk(
	'dropDownSlice/fetchProfessionalBodies',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchProfessionalBodys.action`);
		return res.data.data.result;
	}
);

export const fetchProfessionalBodySpecializations = createAsyncThunk(
	'dropDownSlice/fetchProfessionalBodySpecializations',
	async (bodyId) => {
		const res = await axiosInstance.get(
			`${url}/setup/fetchProfessionalBodySpecializations.action?pbsPrbId=${bodyId}`
		);
		return res.data.data.result;
	}
);

export const fetchRelationships = createAsyncThunk(
	'dropDownSlice/fetchRelationships',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchRelationships.action`);
		return res.data.data.result;
	}
);

export const fetchCounsellingCategorys = createAsyncThunk(
	'dropDownSlice/fetchCounsellingCategorys',
	async () => {
		const res = await axiosInstance.get(
			`${url}/setup/fetchCounsellingCategorys.action`
		);
		return res.data.data.result;
	}
);

export const fetchLanguages = createAsyncThunk(
	'dropDownSlice/fetchLanguages',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchLanguages.action`);
		return res.data.data.result;
	}
);

export const fetchNationalitys = createAsyncThunk(
	'dropDownSlice/fetchNationalitys',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchNationalitys.action`);
		return res.data.data.result;
	}
);

export const fetchJobSeekingMethods = createAsyncThunk(
	'dropDownSlice/fetchJobSeekingMethods',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchJobSeekingMethods.action`);
		return res.data.data.result;
	}
);

export const fetchMeansOfMaintenances = createAsyncThunk(
	'dropDownSlice/fetchMeansOfMaintenances',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchMeansOfMaintenances.action`);
		return res.data.data.result;
	}
);

export const fetchUnemploymentReasons = createAsyncThunk(
	'dropDownSlice/fetchUnemploymentReasons',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchUnemploymentReasons.action`);
		return res.data.data.result;
	}
);

export const fetchStatutoryDocuments = createAsyncThunk(
	'dropDownSlice/fetchStatutoryDocuments',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchStatutoryDocuments.action`);
		return res.data.data.result;
	}
);

export const fetchGrades = createAsyncThunk(
	'dropDownSlice/fetchGrades',
	async () => {
		const res = await axiosInstance.get(`${url}/setup/fetchGrades.action`);
		return res.data.data.result;
	}
);

export const fetchWorkCategorys = createAsyncThunk(
	'dropDownSlice/fetchWorkCategorys',
	async () => {
		const res = await axiosInstance.get(`${url}/work/fetchWorkCategorys.action`);
		return res.data.data.result;
	}
);

export const fetchAgencyDetailSetups = createAsyncThunk(
	'agencySlice/fetchAgencyDetailSetups',
	async () => {
		const res = await axios.get(
			`${url}/setup/fetchAgencyDetailSetups.action`
		);
		return res.data.data.result;
	}
);
export const fetchEconomicActivitys = createAsyncThunk(
	'dropDownSlice/fetchEconomicActivitys',
	async () => {
		const res = await axios.get(`${url}/setup/fetchEconomicActivitys.action`);
		return res.data.data.result;
	}
);
 

export const dropDownSlice = createSlice({
	name: 'dropDown',
	initialState,
	reducers: {
		dropDownCleanUp: (state) => {
			state.courses = [];
			state.profSpecializations = [];
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(fetchAgencyDetailSetups.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchAgencyDetailSetups.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.agencyDetailsSetup = action?.payload;
			})
			.addCase(fetchAgencyDetailSetups.rejected, (state) => {
				state.dropDownLoading = false;
				state.grades = [];
			})

			.addCase(fetchGrades.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchGrades.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.grades = action?.payload;
			})
			.addCase(fetchGrades.rejected, (state) => {
				state.dropDownLoading = false;
				state.grades = [];
			})

			.addCase(fetchStatutoryDocuments.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchStatutoryDocuments.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.statutoryDocs = action?.payload;
			})
			.addCase(fetchStatutoryDocuments.rejected, (state) => {
				state.dropDownLoading = false;
				state.statutoryDocs = [];
			})

			.addCase(fetchUnemploymentReasons.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchUnemploymentReasons.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.unEmpReason = action?.payload;
			})
			.addCase(fetchUnemploymentReasons.rejected, (state) => {
				state.dropDownLoading = false;
				state.unEmpReason = [];
			})

			.addCase(fetchMeansOfMaintenances.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchMeansOfMaintenances.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.meansOfMaintenance = action?.payload;
			})
			.addCase(fetchMeansOfMaintenances.rejected, (state) => {
				state.dropDownLoading = false;
				state.meansOfMaintenance = [];
			})

			.addCase(fetchJobSeekingMethods.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchJobSeekingMethods.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.jobSeekingOptions = action?.payload;
			})
			.addCase(fetchJobSeekingMethods.rejected, (state) => {
				state.dropDownLoading = false;
				state.jobSeekingOptions = [];
			})

			.addCase(fetchNationalitys.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchNationalitys.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.nationalityOptions = action?.payload;
			})
			.addCase(fetchNationalitys.rejected, (state) => {
				state.dropDownLoading = false;
				state.nationalityOptions = [];
			})

			.addCase(fetchLanguages.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchLanguages.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.languageOptions = action?.payload;
			})
			.addCase(fetchLanguages.rejected, (state) => {
				state.dropDownLoading = false;
				state.languageOptions = [];
			})

			.addCase(fetchCounsellingCategorys.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchCounsellingCategorys.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.counsellingCategories = action?.payload;
			})
			.addCase(fetchCounsellingCategorys.rejected, (state) => {
				state.dropDownLoading = false;
				state.counsellingCategories = [];
			})

			.addCase(fetchRelationships.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchRelationships.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.relationships = action?.payload;
			})
			.addCase(fetchRelationships.rejected, (state) => {
				state.dropDownLoading = false;
				state.relationships = [];
			})

			.addCase(fetchProfessionalBodySpecializations.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(
				fetchProfessionalBodySpecializations.fulfilled,
				(state, action) => {
					state.dropDownLoading = false;
					state.profSpecializations = action?.payload;
				}
			)
			.addCase(fetchProfessionalBodySpecializations.rejected, (state) => {
				state.dropDownLoading = false;
				state.profSpecializations = [];
			})

			.addCase(fetchProfessionalBodies.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchProfessionalBodies.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.professionalBodies = action?.payload;
			})
			.addCase(fetchProfessionalBodies.rejected, (state) => {
				state.dropDownLoading = false;
				state.professionalBodies = [];
			})

			.addCase(fetchCourses.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchCourses.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.courses = action?.payload;
			})
			.addCase(fetchCourses.rejected, (state) => {
				state.dropDownLoading = false;
				state.courses = [];
			})

			.addCase(fetchAwards.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchAwards.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.awards = action?.payload;
			})
			.addCase(fetchAwards.rejected, (state) => {
				state.dropDownLoading = false;
				state.awards = [];
			})

			.addCase(fetchStudyAreas.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchStudyAreas.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.studyAreas = action?.payload;
			})
			.addCase(fetchStudyAreas.rejected, (state) => {
				state.dropDownLoading = false;
				state.studyAreas = [];
			})

			.addCase(fetchInstitutionTypes.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchInstitutionTypes.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.instTypes = action?.payload;
			})
			.addCase(fetchInstitutionTypes.rejected, (state) => {
				state.dropDownLoading = false;
				state.instTypes = [];
			})

			.addCase(fetchDisabilityTypes.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchDisabilityTypes.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.disTypeOptions = action?.payload;
			})
			.addCase(fetchDisabilityTypes.rejected, (state) => {
				state.dropDownLoading = false;
				state.disTypeOptions = [];
			})

			.addCase(fetchUnemploymentDurations.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchUnemploymentDurations.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.unEmpDuration = action?.payload;
			})
			.addCase(fetchUnemploymentDurations.rejected, (state) => {
				state.dropDownLoading = false;
				state.unEmpDuration = [];
			})

			.addCase(fetchGender.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchGender.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.genders = action?.payload;
			})
			.addCase(fetchGender.rejected, (state) => {
				state.dropDownLoading = false;
				state.genders = [];
			})

			.addCase(fetchMaritalStatus.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchMaritalStatus.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.maritalStatus = action?.payload;
			})
			.addCase(fetchMaritalStatus.rejected, (state) => {
				state.dropDownLoading = false;
				state.maritalStatus = [];
			})

			.addCase(fetchEthnicitys.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchEthnicitys.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.ethnicity = action?.payload;
			})
			.addCase(fetchEthnicitys.rejected, (state) => {
				state.dropDownLoading = false;
				state.ethnicity = [];
			})

			.addCase(fetchJobExperienceLevels.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchJobExperienceLevels.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.jobExperienceLevels = action?.payload;
			})
			.addCase(fetchJobExperienceLevels.rejected, (state) => {
				state.dropDownLoading = false;
				state.jobExperienceLevels = [];
			})

			.addCase(fetchJobTypes.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchJobTypes.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.jobTypes = action?.payload;
			})
			.addCase(fetchJobTypes.rejected, (state) => {
				state.dropDownLoading = false;
				state.jobTypes = [];
			})

			.addCase(fetchJobWorkStyles.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchJobWorkStyles.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.jobStyles = action?.payload;
			})
			.addCase(fetchJobWorkStyles.rejected, (state) => {
				state.dropDownLoading = false;
				state.jobStyles = [];
			})

			.addCase(fetchQualifications.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchQualifications.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.qualifications = action?.payload;
			})
			.addCase(fetchQualifications.rejected, (state) => {
				state.dropDownLoading = false;
				state.qualifications = [];
			})

			.addCase(fetchWorkCategorys.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchWorkCategorys.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.workTypeCategory = action?.payload;
			})
			.addCase(fetchWorkCategorys.rejected, (state) => {
				state.dropDownLoading = false;
				state.workTypeCategory = [];
			})

			.addCase(fetchEconomicActivitys.pending, (state) => {
				state.dropDownLoading = true;
			})
			.addCase(fetchEconomicActivitys.fulfilled, (state, action) => {
				state.dropDownLoading = false;
				state.economicActivity = action?.payload;
			})
			.addCase(fetchEconomicActivitys.rejected, (state) => {
				state.dropDownLoading = false;
				state.economicActivity = [];
			})

			
	},
});

export default dropDownSlice.reducer;
export const { dropDownCleanUp } = dropDownSlice.actions;
