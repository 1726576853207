import MaterialIcon from 'material-icons-react';
import { Divider } from 'antd';
import ScrollTo from '../../../ScrollTo';
import CompanyRegistration from './agency_details_files/CompanyRegistration';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchAgencyDetailSetups } from '../../../features/dropDowns/dropDownSlice';

const AgencyDetails = () => {
	const dispatch = useDispatch();

	const { agencyDetailsSetup } = useSelector((state) => state.dropDown);

	async function handleAgencyData() {
		await dispatch(fetchAgencyDetailSetups());
	}

	useEffect(() => {
		handleAgencyData();
	}, []);

	useEffect(() => {}, [agencyDetailsSetup]);

	return (
		<div className='flex flex-col w-full mt-10'>
			<ScrollTo />

			{agencyDetailsSetup?.map((item) => {
				return (
					<>
						<div>
							<div className='flex flex-row w-full mb-10'>
								<MaterialIcon color='#147CBC' icon='drag_indicator' />
								<div className='font-bold'>{item?.adsType}</div>
							</div>
							<CompanyRegistration item={item} />

							<Divider className='mt-10' />
						</div>
					</>
				);
			})}

			{/* <div>
				<div className='flex flex-row w-full mb-10'>
					<MaterialIcon color='#147CBC' icon='drag_indicator' />
					<div className='font-bold'>CR 12 Issued within the past 3 months</div>
				</div>
				<CRTwelve handleAgencyData={handleAgencyData} current={current} />

				<Divider className='mt-10' />
			</div>
			<div>
				<div className='flex flex-row w-full my-10'>
					<MaterialIcon color='#147CBC' icon='drag_indicator' />
					<div className='font-bold'>
						Company Valid Tax Compliance Certificate
					</div>
				</div>
				<TaxCompliance handleAgencyData={handleAgencyData} current={current} />

				<Divider className='mt-10' />
			</div>
			<div>
				<div className='flex flex-row w-full my-10'>
					<MaterialIcon color='#147CBC' icon='drag_indicator' />
					<div className='font-bold'>
						Valid Business Registration (County Government)
					</div>
				</div>
				<BusinessRegistration
					handleAgencyData={handleAgencyData}
					current={current}
				/>

				<Divider className='mt-10' />
			</div>
			<div>
				<div className='flex flex-row w-full my-10'>
					<MaterialIcon color='#147CBC' icon='drag_indicator' />
					<div className='font-bold'>Valid Lease Agreement</div>
				</div>
				<LeaseAgreement handleAgencyData={handleAgencyData} current={current} />
			</div> */}
		</div>
	);
};

export default AgencyDetails;
