import { Button, Form, Input, InputNumber, Select, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GenderData } from "../../../../Gender";
import {
  fetchEmployees,
  saveEmployee, 
  setEditBAsic, 
  setViewBAsic,
} from "../../../../features/myEmployees/myEmployeesSlice";
import toast from "react-hot-toast";

function EmployeeDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { user } = useSelector((state) => state.auth);
  const { employeesData, employeeLoading,singleEmployee,employeeEditId ,editBasic} = useSelector(
    (state) => state.employee
  );

  const [receivedEditId, setReceivedEditId] = useState(
    Object?.keys(singleEmployee)?.length > 0  ? singleEmployee?.emplId : employeeEditId
  );
  const singleEmployeeInfo = employeesData?.find(
    (item) => item?.emplId === receivedEditId
  );
  useEffect(() => {
    setReceivedEditId(Object?.keys(singleEmployee)?.length > 0  ? singleEmployee?.emplId : employeeEditId);
  }, [singleEmployee , employeeEditId]);



  const [formData, setFormData] = useState({ ...singleEmployeeInfo });
 
  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const [data, setdata] = useState({});

  function handleSelectChange(value) { 
    setFormData((prevData) => ({
      ...prevData,
      emplGender: value,
    }));
  }

  const handleNumberChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      emplNextOfKinContact: String(e),
    }));
  };

  const handleCancel = (e) => {
    if(employeeEditId && editBasic){
      dispatch(setEditBAsic(false)); 
      dispatch(setViewBAsic(true)); 
    }
  }

  async function fetchEmployeeDetails() {
    await dispatch(fetchEmployees({
      emplInstId:user?.usrInstId
    }));
  }

  const onFinish = async (data) => {
    if(!formData?.emplGender){
      toast.error("Please fill all fields");
      return;
    }
    const res = await dispatch(saveEmployee({
        emplPassportNumber:formData?.emplPassportNumber,
        emplKraPin:formData?.emplKraPin,
        emplGender:formData?.emplGender,
        emplAge:formData?.emplAge,
        emplNextOfKin:formData?.emplNextOfKin,
        emplNextOfKinContact:formData?.emplNextOfKinContact,
        emplId:receivedEditId
    }));
    if (res?.payload?.emplId) {
      toast.success("Employees details saved successfully");
      dispatch(setViewBAsic(true)); 
      form.resetFields();
    } else {
      toast.error("Error saving employee, please try again");
    }
  };

  useEffect(() => {
    fetchEmployeeDetails();
  }, []);

  return (
    <div>
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
          marginTop: 20,
        }}
        form={form}
      >
        <div className="grid grid-cols-2 gap-x-6">
          <Form.Item
            label="Passport No."
            rules={[
              {
                required: true,
                message: "Please add passport No.",
              },
            ]}
          >
            <Input
              required
              name="emplPassportNumber"
              onChange={onChange}
              value={formData?.emplPassportNumber}
              className="input"
            />
          </Form.Item>
          <Form.Item
            label="Kra Pin"
            rules={[
              {
                required: true,
                message: "Please add kra pin",
              },
              {
                pattern: /^[A-Z0-9]{11}$/,
                message:
                  "Invalid Kra Pin format. A valid Kra Pin should be 11 alphanumeric characters.",
              },
            ]}
          >
            <Input
              required
              name="emplKraPin"
              onChange={onChange}
              value={formData?.emplKraPin}
              className="input"
            />
          </Form.Item>

          <Form.Item
            label="Gender"
            rules={[
              {
                required: true,
                message: "Please add gender",
              },
            ]}
          >
            <Select
              required
              style={{
                width: "100%",
                height: "44px",
              }}
              options={GenderData}
              onChange={(value) => {
                handleSelectChange(value);
              }}
              onDropdownVisibleChange={() => {
                //trigger something
              }}
              name="emplGender"
              value={formData?.emplGender}
            />
          </Form.Item>

          <Form.Item
            label="Age"
            rules={[
              {
                required: true,
                message: "Please add age",
              },
            ]}
          >
            <Input
              type="number"
              name="emplAge"
              onChange={onChange}
              value={formData?.emplAge}
              className="input"
            />
          </Form.Item>

          <Form.Item
            label="Next of Kin in Kenya"
            rules={[
              {
                required: true,
                message: "Please add next of kin in kenya",
              },
            ]}
          >
            <Input
              required
              name="emplNextOfKin"
              onChange={onChange}
              value={formData?.emplNextOfKin}
              className="input"
            />
          </Form.Item>

          <Form.Item
            label="Next of Kin Contact"
            rules={[
              {
                required: true,
                message: "Please add next of kin contact",
              },
            ]}
          >
            <InputNumber
              required
              maxLength={10}
              minLength={9}
              onChange={handleNumberChange}
              name="emplNextOfKinContact"
              value={formData?.emplNextOfKinContact}
              className="input"
            />
          </Form.Item>
        </div>

        <div className="flex items-center justify-between mt-[150px] mb-[79px]">
          <div className="flex items-start"></div>
          <div className="flex gap-5 items-center">
            {
            editBasic && (
              <Button
              className="text-blueDark text-[1rem] font-medium font-dmSans leading-normal border-none
              w-[168px] h-12 px-[18px] py-3   justify-center items-center gap-2 inline-flex"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            )
          }
           

            <button
              className="text-white text-[1rem] font-medium font-dmSans leading-normal bg-blueDark
            w-[168px] h-[46px] px-[18px] py-3 rounded-[46px] border justify-center items-center gap-2 inline-flex"
              type="submit"
            >
              {employeeLoading ? <Spin /> : "Save"}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default EmployeeDetails;
