import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Modal, Button, Switch, Spin } from "antd";
import "../../clients/client.css";
import { useDispatch, useSelector } from "react-redux"; 
import toast from "react-hot-toast";
import {
  fetchDemandLetters,
  saveDemandLetter,
} from "../../../features/demand/demandLetterSlice";
import { useNavigate } from "react-router-dom";

const ConfirmSubmitDemandModal = ({ isModalOpen, setIsModalOpen, prodd,navigateBack }) => {
  const dispatch = useDispatch();
  const navigate =  useNavigate()

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { demandLoading } = useSelector((state) => state.letter);
  const { user } = useSelector((state) => state.auth);
  const { clientEditId } = useSelector((state) => state.client);
  const [receivedId, setReceivedId] = useState(clientEditId);


  const handleCancel = () => {
    if(clientEditId) {
      navigate('/client-tabs')
    }else{
      setIsModalOpen(false);
    } 
  };


  useEffect(() => {
    setReceivedId(clientEditId);
  }, [clientEditId]);

  const handleSubmitApproval = async () => {
    const res = await dispatch(
      saveDemandLetter({
        dmlStatus: "PENDING APPROVAL",
        dmlUsrId: user.usrInstId,
        dmlCntId: prodd?.dmlCntId,
        dmlId: prodd?.dmlId,
      })
    );
    if (res?.payload?.success) {
      toast.success("Demand letter sent for approval");
      await dispatch(
        fetchDemandLetters({
          dmlUsrId: user.usrInstId,
          dmlCntId: receivedId,
        })
      );
      if(navigateBack && clientEditId){
        navigate('/client-tabs')
      }else if(navigateBack ){
        navigate('/letter-list') 
      }else{
        handleOk();
      }
     
    } else {
      toast.error("Not sent for approval, please try again");
    }
  };

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer activity activity-header activity-close"
        title="Submit for approval"
        open={isModalOpen}
        onCancel={handleCancel}
        width={898}
        maskClosable={false}
      >
        <div className="text-zinc-800 text-2xl font-bold font-dmSans leading-9">
          Confirm submission
        </div>
        <div className="text-zinc-800 text-2xl font-normal font-dmSans leading-9">
          Submit {prodd?.dmlName} for approval?
        </div>

        <div className="flex flex-col items-center mt-[193px] gap-y-5 mb-[66px]">
          <Button
            className="text-white text-[1rem] font-medium font-dmSansleading-normal bg-blueDark
          w-[239px] h-[46px] px-[18px] py-3  rounded-[56px] justify-center items-center gap-2.5 inline-flex"
            type="submit"
            onClick={handleSubmitApproval}
          >
            {demandLoading ? <Spin /> : "Submit"}
          </Button>

          <button
            className="text-blueDark text-[1rem] font-medium font-dmSansleading-normal
          w-[239px] h-[46px] px-[18px] py-3  rounded-[56px] border border-blueDark justify-center items-center gap-2.5 inline-flex"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
export default ConfirmSubmitDemandModal;
