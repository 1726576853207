import { Button, Form, Input, InputNumber, Select, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GenderData, eduLevelOptions } from "../../../../Gender";
import {
  fetchEmployees,
  saveEmployee,
  setEditJob,
  setViewBAsic,
  setViewJob,
} from "../../../../features/myEmployees/myEmployeesSlice";
import toast from "react-hot-toast";
import { fetchClients } from "../../../../features/client/clientSlice";
import { fetchQualifications } from "../../../../features/jobFilters/jobFilterSlice";

function JobDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { user } = useSelector((state) => state.auth);
  const {
    employeesData,
    employeeLoading,
    employeeEditId,
    singleEmployee,
    editJob,
  } = useSelector((state) => state.employee);
  const { clientData, clientLoading } = useSelector((state) => state.client);

  const [receivedEditId, setReceivedEditId] = useState(
    Object?.keys(singleEmployee)?.length > 0  ? singleEmployee?.emplId : employeeEditId
  );
  const singleEmployeeInfo = employeesData?.find(
    (item) => item?.emplId === receivedEditId
  );
  useEffect(() => {
    setReceivedEditId(Object?.keys(singleEmployee)?.length > 0  ? singleEmployee?.emplId : employeeEditId);
  }, [singleEmployee ,employeeEditId]);

  const [formData, setFormData] = useState({ ...singleEmployeeInfo });
 
  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const [data, setdata] = useState({});

  function handleEducationChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      emplHigherEducation: value,
    }));
  }
  function handleAwardChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      emplHighestTrainingAward: value,
    }));
  }
  function handleSkillChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      emplSpecificSkillArea: value,
    }));
  }
  function handleEmployerChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      emplEmployerId: value,
    }));
  }

  

  const handleNumberChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      emplEmployerPhone: String(e),
    }));
  };

  const handleCancel = (e) => {
    if (employeeEditId && editJob) {
      dispatch(setEditJob(false));
      dispatch(setViewJob(true));
    }
  };

  async function fetchEmployeeDetails() {
    await dispatch(fetchEmployees({
      emplInstId:user?.usrInstId
    }));
  }
  async function fetchClientDetails() {
    const res = await dispatch(
      fetchClients({
        cntInstId: user?.usrInstId,
      })
    );
  }
 
  const { qualifications } = useSelector(
    (state) => state.jobFilter
  );

  async function fetcQualificationData(){
    await dispatch(fetchQualifications())
  }
  useEffect(()=>{
    fetcQualificationData()
  },[])

  

  const onFinish = async (data) => {
    if(!formData?.emplHigherEducation || !formData.emplHighestTrainingAward || !formData.emplSpecificSkillArea){
      toast.error("Please fill all details")
      return;
    }
    const res = await dispatch(
      saveEmployee({
        emplHigherEducation: formData?.emplHigherEducation,
        emplHighestTrainingAward: formData?.emplHighestTrainingAward,
        emplSpecificSkillArea: formData?.emplSpecificSkillArea,
        emplJobTitle: formData?.emplJobTitle,
        emplInstId: user?.usrInstId,
        emplEmployerAddress: formData?.emplEmployerAddress,
        emplEmployerPhone: formData?.emplEmployerPhone,
        emplId:receivedEditId
      })
    ); 
    if (res?.payload?.emplId) {
      toast.success("Employees details saved successfully"); 
      dispatch(setViewJob(true)); 
      form.resetFields();
    } else {
      toast.error("Error saving employee, please try again");
    }
  };

  useEffect(() => {
    fetchEmployeeDetails();
  }, []);
 
  return (
    <div>
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
          marginTop: 20,
        }}
        form={form}
      >
        <div className="grid grid-cols-2 gap-x-6">
      

          <Form.Item
            label="Highest Education Level."
            rules={[
              {
                required: true,
                message: "Please add highest education level.",
              },
            ]}
          >
            <Select
              required
              style={{
                width: "100%",
                height: "44px",
              }} 
              options={
                qualifications?.length > 0 &&
                qualifications?.map((edu) => ({
                  value: edu.qualName,
                  label: edu.qualName,
                }))
              }
              onChange={handleEducationChange} 
              onDropdownVisibleChange={() => {
                fetcQualificationData();
              }}
              name="emplHigherEducation"
              value={formData?.emplHigherEducation}
            /> 
          </Form.Item>

          <Form.Item
            label="Highest Training Award"
            rules={[
              {
                required: true,
                message: "Please add highest training award",
              },
            ]}
          >
             <Select
              required
              style={{
                width: "100%",
                height: "44px",
              }} 
              options={
                eduLevelOptions?.length > 0 &&
                eduLevelOptions?.map((edu) => ({
                  value: edu.value,
                  label: edu.label,
                }))
              }
              onChange={handleAwardChange} 
              onDropdownVisibleChange={() => {
                //trigger something
              }}
              name="emplHighestTrainingAward"
              value={formData?.emplHighestTrainingAward}
            /> 
          
          </Form.Item>

          <Form.Item
            label="Specific Skill Area"
            rules={[
              {
                required: true,
                message: "Please add specific skill area",
              },
            ]}
          >
             <Select
              required
              style={{
                width: "100%",
                height: "44px",
              }} 
              options={
                eduLevelOptions?.length > 0 &&
                eduLevelOptions?.map((edu) => ({
                  value: edu.value,
                  label: edu.label,
                }))
              }
              onChange={handleSkillChange} 
              onDropdownVisibleChange={() => {
                //trigger something
              }}
              name="emplSpecificSkillArea"
              value={formData?.emplSpecificSkillArea}
            />  
          </Form.Item>

          <Form.Item
            label="Job Title"
            rules={[
              {
                required: true,
                message: "Please add job title",
              },
            ]}
          >
            <Input
              required
              name="emplJobTitle"
              onChange={onChange}
              value={formData?.emplJobTitle}
              className="input"
            />
          </Form.Item>

          {/* <Form.Item
            label="Name of Employer"
            rules={[
              {
                required: true,
                message: "Please add name of employer",
              },
            ]}
          >
            <Select
              required
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              style={{
                width: "100%",
                height: "44px",
              }} 
              options={
                clientData?.length > 0 &&
                clientData?.map((edu) => ({
                  value: edu.cntId,
                  label: edu.cntName,
                }))
              }
              onChange={handleEmployerChange} 
              onDropdownVisibleChange={() => {
                fetchClientDetails()
              }}
              name="emplEmployerId"
              value={formData?.emplEmployerId}
            />   
          </Form.Item> */}

          <Form.Item
            label="Employer Address"
            rules={[
              {
                required: true,
                message: "Please add employer address",
              },
            ]}
          >
            <Input
              required
              name="emplEmployerAddress"
              onChange={onChange}
              value={formData?.emplEmployerAddress}
              className="input"
            />
          </Form.Item>

          <Form.Item
            label="Next of Kin Contact"
            rules={[
              {
                required: true,
                message: "Please add next of kin contact",
              },
            ]}
          >
            <InputNumber
              required
              maxLength={10}
              minLength={9}
              onChange={handleNumberChange}
              name="emplEmployerPhone"
              value={formData?.emplEmployerPhone}
              className="input"
            />
          </Form.Item>
        </div>

        <div className="flex items-center justify-between mt-[150px] mb-[79px]">
          <div className="flex items-start"></div>
          <div className="flex gap-5 items-center">
            {editJob && (
              <Button
                className="text-blueDark text-[1rem] font-medium font-dmSans leading-normal border-none
                w-[168px] h-12 px-[18px] py-3   justify-center items-center gap-2 inline-flex"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            )}

            <button
              className="text-white text-[1rem] font-medium font-dmSans leading-normal bg-blueDark
            w-[168px] h-[46px] px-[18px] py-3 rounded-[46px] border justify-center items-center gap-2 inline-flex"
              type="submit"
            >
              {employeeLoading ? <Spin /> : "Save"}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default JobDetails;
