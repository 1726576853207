import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  employeesData: [],
  singleEmployee:{},
  employeeLoading: false,
  employeeReturnLoading: false,

  editBasic: false,
  viewBAsic: false,
  editJob: false,
  viewJob: false,
  editContract: false,
  viewContract: false,

  employeeEditId:'',
  employeeEditObj: {},
  employeePlacementObj:{},
  employeesLocalPlacementData: [],
  employeesForeignPlacementData: [],
  refetch:false,
  refetchKey:'',

  employeeReturnsPage: false,
  singlePlacement:{},

  isLocal:true,
  employeeCount:0,

  localEmployeeCount:0,
  foreignEmployeeCount:0,

  redundantEmployees : [],
  redundantEmployeeCount:0,
};

export const saveEmployee = createAsyncThunk(
  "employee/saveEmployee",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveEmployee.action`, data);
    return res.data;
  }
);

export const saveEmployeeNew = createAsyncThunk(
	'employee/saveEmployeeNew',
	async (data) => {
		const res = await axiosInstance.post(`${url}/nea/saveEmployee.action`, data);
		return res.data;
	},
);


export const fetchEmployees = createAsyncThunk(
  "employee/fetchEmployees",
  async (data) => {
    const res = await axiosInstance.get(`${url}/nea/fetchEmployees.action?emplInstId=${data?.emplInstId}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}`);
    return res.data?.data;
  }
);



export const saveEmploymentReturn = createAsyncThunk(
  "employee/saveEmploymentReturn",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveEmploymentReturn.action`, data);
    return res.data;
  }
);

export const fetchLocalPlacements = createAsyncThunk(
  "employee/fetchLocalPlacements",
  async (data) => {
    const res = await axiosInstance.get(`${url}/nea/fetchJobPlacements.action?japStatus=LOCAL_WORKER&jabIsReturned=FALSE&japInstId=${data?.japInstId}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}`);
    return res.data?.data;
  }
);

export const fetchForeignPlacements = createAsyncThunk(
  "employee/fetchForeignPlacements",
  async (data) => {
    const res = await axiosInstance.get(`${url}/nea/fetchJobPlacements.action?japStatus=MIGRANT_WORKER&jabIsReturned=FALSE&japInstId=${data?.japInstId}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}`);
    return res.data?.data;
  }
);
 

export const savePlacementReturn = createAsyncThunk(
  "employee/savePlacementReturn",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/savePlacementReturn.action`, data);
    return res.data;
  }
);

export const fetchSinglePlacements = createAsyncThunk(
  "employee/fetchSinglePlacements",
  async (data) => {
    const res = await axiosInstance.
    get(`${url}/nea/fetchJobPlacements.action?japStatus=${data?.japStatus}&jabIsReturned=FALSE&japInstId=${data?.japInstId}&japUsrId=${data?.japUsrId}`);
    return res.data?.data?.result;
  }
);

 
export const fetchRedundantEmployees = createAsyncThunk(
  "employee/fetchRedundantEmployees",
  async (data) => {
    const res = await axiosInstance.get(`${url}/nea/fetchEmployees.action?emplInstId=${data?.emplInstId}&emplStatus=${data?.emplStatus}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}`);
    return res.data?.data;
  }
);
 
 

export const myEmployeesSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },

    setEditBAsic: (state, action) => {
      state.editBasic = action.payload;
    },
    setViewBAsic: (state, action) => {
      state.viewBAsic = action.payload;
    },
    setEditJob: (state, action) => {
      state.editJob = action.payload;
    },
    setViewJob: (state, action) => {
      state.viewJob = action.payload;
    },
    setEditContract: (state, action) => {
      state.editContract = action.payload;
    },
    setViewContract: (state, action) => {
      state.viewContract = action.payload;
    },
    setEmployeeEditId: (state, action) => {
      state.employeeEditId = action.payload;
    },
    setEmployeeEditObj: (state, action) => {
			state.employeeEditObj = action.payload;
		},
    setEmployeePlacementObj: (state, action) => {
			state.employeePlacementObj = action.payload;
		},
    
    setRefetch:(state)=>{
      state.refetch = !state.refetch;
    },
    setRefetchKey: (state, action) => {
      state.refetchKey = action.payload;
    },
    setEmployeeReturnsPage: (state, action) => {
			state.employeeReturnsPage = action.payload;
		},
    setIsLocal: (state, action) => {
      state.isLocal = action.payload;
    },
    


    cleanMyEmployees: (state) => {
      state.editBasic = initialState.editBasic;
      state.viewBAsic = initialState.viewBAsic;
      state.editJob = initialState.editJob;
      state.viewJob = initialState.viewJob;
      state.editContract = initialState.editContract;
      state.viewContract = initialState.viewContract;
      state.singleEmployee = initialState.singleEmployee;
      state.employeeEditId = initialState.employeeEditId;
      state.employeeEditObj = {};
      state.refetchKey = initialState.refetchKey;
      state.employeeReturnsPage = initialState.employeeReturnsPage;
      state.isLocal = initialState.isLocal;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(saveEmployee.pending, (state) => {
        state.employeeLoading = true;
      })
      .addCase(saveEmployee.fulfilled, (state, action) => {
        state.employeeLoading = false;
        state.singleEmployee= action.payload?.jsonData;
      })
      .addCase(saveEmployee.rejected, (state) => {
        state.employeeLoading = false;
        state.singleEmployee={}
      })
      .addCase(saveEmployeeNew.pending, (state) => {
				state.employeeLoading = true;
			})
			.addCase(saveEmployeeNew.fulfilled, (state) => {
				state.employeeLoading = false;
			})
			.addCase(saveEmployeeNew.rejected, (state) => {
				state.employeeLoading = false;
			})

      .addCase(fetchEmployees.pending, (state) => {
        state.employeeLoading = true;
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.employeeLoading = false;
        state.employeesData = action.payload?.result;
        state.employeeCount=  action.payload?.count;
      })
      .addCase(fetchEmployees.rejected, (state) => {
        state.employeeLoading = false;
        state.employeeCount =  0;
      })

      .addCase(saveEmploymentReturn.pending, (state) => {
        state.employeeReturnLoading = true;
      })
      .addCase(saveEmploymentReturn.fulfilled, (state, action) => {
        state.employeeReturnLoading = false;
      })
      .addCase(saveEmploymentReturn.rejected, (state) => {
        state.employeeReturnLoading = false; 
      })

      .addCase(fetchLocalPlacements.pending, (state) => {
        state.employeeLoading = true;
      })
      .addCase(fetchLocalPlacements.fulfilled, (state, action) => {
        state.employeeLoading = false;
        state.employeesLocalPlacementData = action.payload?.result;
        state.localEmployeeCount = action.payload?.count;
      })
      .addCase(fetchLocalPlacements.rejected, (state) => {
        state.employeeLoading = false;
        state.employeesLocalPlacementData = [];
        state.localEmployeeCount = 0;
      })

      .addCase(fetchForeignPlacements.pending, (state) => {
        state.employeeLoading = true;
      })
      .addCase(fetchForeignPlacements.fulfilled, (state, action) => {
        state.employeeLoading = false;
        state.employeesForeignPlacementData = action.payload?.result;
        state.foreignEmployeeCount = action.payload?.count;
      })
      .addCase(fetchForeignPlacements.rejected, (state) => {
        state.employeeLoading = false;
        state.employeesForeignPlacementData= [];
        state.foreignEmployeeCount = 0;
      })

      .addCase(savePlacementReturn.pending, (state) => {
        state.employeeReturnLoading = true;
      })
      .addCase(savePlacementReturn.fulfilled, (state, action) => {
        state.employeeReturnLoading = false;
      })
      .addCase(savePlacementReturn.rejected, (state) => {
        state.employeeReturnLoading = false; 
      })

      .addCase(fetchSinglePlacements.pending, (state) => {
        state.employeeReturnLoading = true;
      })
      .addCase(fetchSinglePlacements.fulfilled, (state, action) => {
        state.employeeReturnLoading = false;
        state.singlePlacement= action?.payload[0]
      })
      .addCase(fetchSinglePlacements.rejected, (state) => {
        state.employeeReturnLoading = false; 
        state.singlePlacement= {}
      })


      .addCase(fetchRedundantEmployees.pending, (state) => {
        state.employeeLoading = true;
      })
      .addCase(fetchRedundantEmployees.fulfilled, (state, action) => {
        state.employeeLoading = false;
        state.redundantEmployees = action.payload?.result;
        state.redundantEmployeeCount=  action.payload?.count;
      })
      .addCase(fetchRedundantEmployees.rejected, (state) => {
        state.employeeLoading = false;
        state.redundantEmployees = [];
        state.redundantEmployeeCount =  0;
      })

      
  },
});

export default myEmployeesSlice.reducer;
export const {
  handleSidenavFullWidth,
  setEditBAsic,
  setViewBAsic,
  setEditJob,
  setViewJob,
  setEditContract,
  setViewContract,
  setEmployeeEditId,
  setEmployeeEditObj,
  setEmployeePlacementObj,
  setEmployeeReturnsPage,
  cleanMyEmployees,
  setRefetch,
  setRefetchKey,
  setIsLocal
} = myEmployeesSlice.actions;
