import React, { useEffect, useState } from "react";
import upload from "../../assets/upload csv.svg";
import { Button, Spin } from "antd";
import { saveFile } from "../../features/application/applicationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveDemandLetter } from "../../features/demand/demandLetterSlice";
import toast from "react-hot-toast";
import { showToastError, showToastSuccess } from "../../utils";

function UploadLetter() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { demandLoading } = useSelector((state) => state.letter);

  const [letterFile, setLetterFile] = useState("");
  // const [inputKey, setInputKey] = useState(Date.now());
  const [updating, setUpdating] = useState(false);
  const [loadingAttach,setLoadingAttach] = useState(false);

  const handleChange = async (e) => {
    // setLoadingAttach(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const res = await dispatch(saveFile(formData));

    if (res?.payload?.success) {
      setLetterFile(res?.payload?.jsonData);
      setUpdating(true);
      setLoadingAttach(false)
    } else {
      setLoadingAttach(false)
    }
  };

  function closeModal() {
    toast.dismiss();
  }

  const handleUploadLetter = async () => {
    const res = await dispatch(
      saveDemandLetter({
        dmlFile: letterFile,
       dmlUsrId:user?.usrInstId,
        // dmlCntId:item?.dmlCntId, 
      })
    );
    if (res?.payload?.dmlId) {
      showToastSuccess("Demand letter uploaded successfull", closeModal);
      navigate("/letter-list");
      setUpdating(false);
      setLetterFile("");
    } else {
      showToastError("Error occured, try again later", closeModal);
      setUpdating(false);
      setLetterFile("");
    }

    // setInputKey(Date.now());
  };
  useEffect(() => {
    if (updating && letterFile.length > 0) {
      handleUploadLetter();
    }
  }, [updating, letterFile]);
  // useEffect(() => {
  //   let isMounted = true;
  
  //   if (updating && letterFile) {
  //     handleUploadLetter();
  //   }
  
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [updating, letterFile]);
  

  return (
    <>
      <div className="text-black text-2xl font-medium font-dmSans leading-9 mt-[37px] ml-[54px]">
        Upload demand letter
      </div>
      <div className="text-black text-[21px] font-normal font-dmSans leading-loose ml-[54px]">
        Upload a demand letter in order to advertise job vacancies.
      </div>

      <div className="w-full text-center flex items-center justify-center mt-[104px]">
        <img src={upload} alt="upload" />
      </div>

      <div className="text-center text-black text-[21px] font-medium font-dmSans leading-loose">
        No demand letters yet. Upload a demand letter.
      </div>
      <div className="text-center mt-[40.53px]">
        <label
          htmlFor="file-upload"
          className="px-7 py-4 bg-blueDark rounded-[56px] shadow justify-center items-center gap-2.5 inline-flex w-[187px] h-14 relative !text-blue-50 text-[1rem] font-medium font-dmSans leading-normal cursor-pointer"
        >
          <input
            type="file"
            id="file-upload"
            className="hidden"
            accept=".pdf"
            onChange={(e) => handleChange(e)}
            // key={inputKey}
            disabled={demandLoading || loadingAttach ? true : false}
          />
          {demandLoading || loadingAttach ? <Spin /> : "Upload"}
        </label>
      </div>
    </>
  );
}

export default UploadLetter;
