import React, { useEffect, useState } from 'react';
import { Button, Collapse, Descriptions, theme } from 'antd';
import MaterialIcon from 'material-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import FileButton from '../../../components/FileButton';
import { formatImgPath } from '../../../utils';
import LicenseCard from './LicenseCard';
import { fetchAgencyDetails } from '../../../features/application/applicationSlice';

const Summary = ({ handleFirstStep }) => {
	const { token } = theme.useToken();
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { agencyObj } = useSelector((state) => state.agency);
	const { agentLicence } = useSelector((state) => state.license);
	const {
		agencyCompanyReg,
		crTwelve,
		taxCompliance,
		businessReg,
		leaseAgreement,
	} = useSelector((state) => state.application);

	const [agencyDetailList, setagencyDetailList] = useState([]);

	const bgColorClass = (bgColor) => {
		return {
			backgroundColor: bgColor,
		};
	};

	const license = agentLicence?.find(
		(item) => item?.licId === agencyObj?.instLicId
	);

	const panelStyle = {
		marginBottom: 24,
		background: token.colorFillAlter,
		borderRadius: token.borderRadiusLG,
		border: '1px solid #147CBC',
	};

	const items = [
		{
			key: '1',
			label: (
				<span className='profile-basic-info font-[500] w-[200px] my-3'>
					{' '}
					Name
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] w-full truncate my-3'>
					{agencyObj?.instName}
				</span>
			),
		},
		{
			key: '2',
			label: (
				<span className='profile-basic-info font-[500] w-[200px] my-3'>
					Economic Activity
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] text-start w-full truncate '>
					{agencyObj?.instEconomic}
				</span>
			),
		},
		{
			key: '3',
			label: (
				<span className='profile-basic-info font-[500] w-[200px] mb-3'>
					KRA PIN
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] text-start w-full truncate '>
					{agencyObj?.instKraPin}
				</span>
			),
		},
		{
			key: '4',
			label: (
				<span className='profile-basic-info font-[500] w-[200px] mb-3'>
					Postal Address
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] text-start w-full truncate'>
					{agencyObj?.instPostalAddress}
				</span>
			),
		},
		{
			key: '5',
			label: (
				<span className='profile-basic-info font-[500] w-[200px] mb-3'>
					Postal Code
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] text-start w-full truncate '>
					{agencyObj?.instPostalCode}
				</span>
			),
		},
		{
			key: '6',
			label: (
				<span className='profile-basic-info font-[500]  w-[200px] mb-3'>
					Official Location
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] text-start w-full truncate'>
					{agencyObj?.instPhysicalAddress}
				</span>
			),
		},
		{
			key: '7',
			label: (
				<span className='profile-basic-info font-[500] w-[200px] mb-3'>
					Nearest NEA Office
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] text-start w-[300px] truncate '>
					{agencyObj?.instNearNeaOffice}
				</span>
			),
		},
		{
			key: '8',
			label: (
				<span className='profile-basic-info font-[500] w-[200px] mb-3'>
					Official Office No.
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] text-start w-full truncate '>
					{agencyObj?.instWorkTel}
				</span>
			),
		},
		{
			key: '9',
			label: (
				<span className='profile-basic-info font-[500] w-[200px]'>
					Official Email Address
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] w-[200px] mb-3 '>
					{agencyObj?.instEmail}
				</span>
			),
		},
		{
			key: '10',
			label: (
				<span className='profile-basic-info font-[500] w-[200px] mb-3'>
					Official Website
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] w-[200px] mb-3  '>
					{agencyObj?.instWebsite}
				</span>
			),
		},
	];

	const docItems = agencyDetailList?.map((item, index) => {
		return {
			key: String(index),
			label: (
				<span className='profile-basic-info font-[500] w-[300px] mt-3'>
					{' '}
					{item?.agcType}
				</span>
			),
			children: (
				<div className='mt-3'>
					<FileButton
						show={true}
						title={formatImgPath(item?.agcFile)}
						titlePreview={item?.agcFile}
						viable='true'
					/>
				</div>
			),
		};
	});

	const LicenView = (
		<LicenseCard
			hide={true}
			style={bgColorClass(license?.licDesc)}
			licenseType={license?.licType}
			price={license?.licAmount}
			selectedButton={license?.licId}
		/>
	);
	const getItems = (panelStyle) => [
		{
			key: '1',
			label: (
				<div className='flex items-center w-[100%] gap-2'>
					<MaterialIcon color='#147CBC' icon='drag_indicator' />
					<span className='font-[500] text-black2 text-[18px]'>
						Account Details
					</span>
				</div>
			),
			children: (
				<Descriptions
					colon={false}
					size={'large'}
					column={2}
					layout='horizontal'
					items={items}
					style={{
						marginLeft: '20px',
						marginTop: '10px',
					}}
				/>
			),
			style: panelStyle,
		},
		{
			key: '2',
			label: (
				<div className='flex items-center w-[100%] gap-2'>
					<MaterialIcon color='#147CBC' icon='drag_indicator' />
					<span className='font-[500] text-black2 text-[18px]'>Documents</span>
				</div>
			),
			children: (
				<Descriptions
					colon={false}
					size={'large'}
					column={1}
					layout='horizontal'
					items={docItems}
					style={{
						marginLeft: '20px',
						marginTop: '10px',
					}}
				/>
			),
			style: panelStyle,
		},
		{
			key: '3',
			label: (
				<div className='flex items-center w-[100%] gap-2'>
					<MaterialIcon color='#147CBC' icon='drag_indicator' />
					<span className='font-[500] text-black2 text-[18px]'>License</span>
				</div>
			),
			children: (
				<div
					style={{
						marginLeft: '20px',
						marginTop: '20px',
					}}
					className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-10'>
					<div>{LicenView}</div>
				</div>
			),
			style: panelStyle,
		},
	];

	async function handleAgencyData() {
		const obj = {
			agcInstId: user?.usrInstId,
		};

		const res = await dispatch(fetchAgencyDetails(obj));

		if (res?.payload?.success) {
			setagencyDetailList(res?.payload?.data?.result);
		}
	}

	useEffect(() => {}, [agencyDetailList]);

	useEffect(() => {
		handleAgencyData();
	}, []);

	return (
		<>
			{agencyObj?.instStatus === 'PARTIAl_APPROVE_ACCOUNT_VERIFICATION' ||
			agencyObj?.instStatus === 'PARTIAL_APPROVE_ACCOUNT_APPLICATION' ? (
				<>
					<div
						style={{
							boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
						}}
						className='border-l-8 border-[#c8b100] w-full h-auto rounded-[14px] p-10 mb-24 mt-10'>
						<div className='flex justify-between items-center w-full'>
							<span className='font-bold text-[26px] text-[#141414]'>
								Action required!
							</span>
						</div>
						<div className='mt-5 text-blackest font-medium text-[16px]'>
							Please fix the below stated issues to continue with your
							application
						</div>
						<div
							className='mt-5'
							dangerouslySetInnerHTML={{
								__html: agencyObj?.instRemarks?.replace(/<li>/g, '<li> • '),
							}}
						/>

						{/* {agencyObj?.instStatus === 'UPDATED_ACCOUNT_CREATION' ? null : (
							<button
								onClick={handleUpdateInstProfile}
								type='button'
								className='bg-blueDark text-white w-[200px] p-2 my-5 rounded-[8px]'
							>
								Update account details
							</button>
						)} */}
					</div>
				</>
			) : agencyObj?.instStatus === 'REVOKE' ||
			  agencyObj?.instStatus === 'DECLINE_ACCOUNT_APPLICATION' ? (
				<>
					<div
						style={{
							boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
						}}
						className='border-l-8 border-[#d20000] w-full h-auto rounded-[14px] p-10 mb-24 mt-10'>
						<div className='flex justify-between items-center w-full'>
							<span className='font-bold text-[26px] text-[#141414]'>
								Application Rejected
							</span>
						</div>
						<div className='mt-5 text-blackest font-medium text-[16px]'>
							Your application as a licensed agency has been rejected.
						</div>
						<div
							className='mt-5'
							dangerouslySetInnerHTML={{
								__html: agencyObj?.instRemarks?.replace(/<li>/g, '<li> • '),
							}}
						/>

						<Button
							className='flex justify-center items-center border-2 border-blueDark rounded-[35px] text-blueDark w-[200px] p-5 mt-5'
							onClick={() => handleFirstStep()}>
							Review your application
						</Button>

						{/* {agencyObj?.instStatus === 'UPDATED_ACCOUNT_CREATION' ? null : (
							<button
								onClick={handleUpdateInstProfile}
								type='button'
								className='bg-blueDark text-white w-[200px] p-2 my-5 rounded-[8px]'
							>
								Update account details
							</button>
						)} */}
					</div>
				</>
			) : (
				<>
					<div className='flex flex-col w-[100%] my-14'>
						<div className='appl-head'>Application summary</div>
						<div className='flex flex-row justify-between mt-2 mb-10'>
							<div className=''>Here’s a summary of your application</div>
						</div>

						<Collapse
							bordered={false}
							expandIconPosition='end'
							defaultActiveKey={['1']}
							style={{
								background: token.colorBgContainer,
							}}
							items={getItems(panelStyle)}
						/>
					</div>
				</>
			)}
		</>
	);
};

export default Summary;
