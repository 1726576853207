import { Table, Skeleton, Button, Dropdown, Input } from "antd";
import React, { useEffect, useState } from "react";
import MaterialIcon from "material-icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { calculateAge, dateForHumans, formatImgPath } from "../../utils";
import {
  cleanMyEmployees, 
} from "../../features/myEmployees/myEmployeesSlice";
import ConfirmationModal from "../../components/ConfirmationModal";
import toast from "react-hot-toast";
import emptyContainer from "../../assets/Container.png";
import morevert from "../../assets/morevert.svg";
import { saveJobApplication } from "../../features/applicantJob/applicantJobSlice";
import { fetchRunAways } from "../../features/runaway/runawaySlice";

function Runaway() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const { user } = useSelector((state) => state.auth);
  const { refetchKey, refetch, runawayData, runawayCount, loading } =
    useSelector((state) => state.runaway);

  const [searchText, setSearchText] = useState("");
  const [prodd, setProdd] = useState();

  const handleDeclareRunaway = async () => {
    const res = await dispatch(
      saveJobApplication({
        japId: prodd?.japId,
        japProgressStatus: "RUNAWAY",
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await setIsModalOpenConfirmation(false);
      await fetchRunawaysDetails();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const handleView = async (item) => { 
    navigate(`/runaway-view/${prodd?.rnaId}`);
  };

 

  const [isModalOpenConfirmation, setIsModalOpenConfirmation] = useState(false);
  const showModalConfirmation = async () => {
    setIsModalOpenConfirmation(true);
  };

  const handleRunaway = async (item) => {
    showModalConfirmation();
  };

  async function cleanEmployeeDetails() {
    dispatch(cleanMyEmployees());
  }

  useEffect(() => {
    cleanEmployeeDetails();
  }, []);

  const columns = [
    {
      title: "Name",
      render: (item) => (
        <>
          <div className="flex justify-between">
            <div className="flex items-center">
              <img
                className="w-9 h-9 rounded-full object-cover"
                src={
                  item?.rnaIcon
                    ? formatImgPath(item?.rnaIcon)
                    : "https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png"
                }
                alt="Avatar"
              />
              <span className="table-name mx-3">
                {item?.rnaFullName}  
              </span>
            </div>
            <div className="flex items-center" onClick={() => setProdd(item)}>
              <button className="p-0 m-0 flex items-end" type="button">
                <Dropdown
                  overlayStyle={{
                    width: "250px",
                  }}
                  trigger={"click"}
                  menu={{ items: settingItems }}
                  placement="bottom"
                >
                  <img src={morevert} alt="morevert" />
                </Dropdown>
              </button>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Phone",
      dataIndex: "rnaPhone",
    },
     
    {
      title: "Job Title",
      dataIndex: "jobPosition",
    },
    {
      title: "Job Qualification",
      dataIndex: "jobQualification",
    },
    {
      title: "Job Country",
      dataIndex: "jobCountry",
    },
    
    {
      title: "Job City",
      dataIndex: "jobCity",
    },
    
    
    {
      title: "Name of Employer",
      dataIndex: "cntName",
    },
    
  ];

  const settingItems = [
    // {
    //   key: "0",
    //   label: (
    //     <div
    //       className="font-dmSans text-black font-[400] text-[19px]"
    //       onClick={handleRunaway}
    //     >
    //       Declare active
    //     </div>
    //   ),
    // },
    {
      key: "1",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={handleView}
        >
          View
        </div>
      ),
    },
  ];

  const filteredData = searchText
    ? runawayData?.filter((item) =>
        item?.emplFullNames?.toLowerCase().includes(searchText.toLowerCase())
      )
    : runawayData;

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchRunawaysDetails(page, size) {
    await dispatch(
      fetchRunAways({
        rnaInstId: user?.usrInstId,
        rnaStatus: "agencyRunaway",
        limit: size ?? pageSize,
        start: page ?? pageIndex,
      })
    );
  }

  useEffect(() => {
    if (refetchKey == 2) {
      fetchRunawaysDetails();
    }
  }, [refetchKey, refetch]);
console.log("runawayData",runawayData)
  return (
    <>
      <div className="mt-[0px]">
        {runawayData && runawayData?.length > 0 ? (
          <>
            <h3 className="font-[700] text-[24px] text-black1 dash-title">
              List of runaway employees
            </h3>
            <div className="flex items-center justify-between w-full mt-[1.94rem]">
              <Input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search runaway name or keyword"
                className="text-[16px] font-[400] flex-row-reverse"
                prefix={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.2508 3.75C7.50609 3.75 4.47041 6.93997 4.47041 10.875C4.47041 14.81 7.50609 18 11.2508 18C14.9955 18 18.0312 14.81 18.0312 10.875C18.0312 6.93997 14.9955 3.75 11.2508 3.75ZM3.04297 10.875C3.04297 6.11154 6.71773 2.25 11.2508 2.25C15.7838 2.25 19.4586 6.11154 19.4586 10.875C19.4586 15.6385 15.7838 19.5 11.2508 19.5C6.71773 19.5 3.04297 15.6385 3.04297 10.875Z"
                      fill="#333333"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.045 15.913C16.3237 15.6201 16.7756 15.6201 17.0543 15.913L21.3902 20.4693C21.6689 20.7622 21.6689 21.237 21.3902 21.5299C21.1115 21.8228 20.6596 21.8228 20.3809 21.5299L16.045 16.9737C15.7663 16.6808 15.7663 16.2059 16.045 15.913Z"
                      fill="#333333"
                    />
                  </svg>
                }
              />
            </div>

            <div className="mt-[20px]">
              {loading ? (
                <Skeleton />
              ) : (
                <Table
                  rowSelection={false}
                  size={"middle"}
                  bordered={true}
                  pagination={{
                    position: ["bottomCenter"],
                    current: pageIndex + 1,
                    total: runawayCount,
                    pageSize: pageSize,
                    onChange: (page, size) => {
                      setPageIndex(page - 1);
                      setPageSize(size);
                      fetchRunawaysDetails(page - 1, size);
                    },
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                  }}
                  rowKey={(record) => record?.rnaId}
                  columns={tableColumns}
                  dataSource={filteredData}
                  scroll={{
                    x: "max-content",
                    // x:800
                  }}
                />
              )}
            </div>
          </>
        ) : (
          <div className=" h-[885px] bg-white flex  flex-col">
            <div
              className=" bg-white full h-[471.33px] rounded-[15px] border border-solid
             border-[#F5F7FF] py-[60px] flex flex-col justify-center items-center cursor-pointer"
            >
              <div>
                <img
                  src={emptyContainer}
                  alt="group-pic"
                  className="object-contain h-[291.33px] w-[292px]"
                />
                <div className="empty-desc flex items-center justify-center">
                  No runaway
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <ConfirmationModal
        isModalOpen={isModalOpenConfirmation}
        setIsModalOpen={setIsModalOpenConfirmation}
        handleSubmit={handleDeclareRunaway}
        loading={loading}
        content={`Are you sure you want to return ${prodd?.usrFirstName}  ${prodd?.usrLastName} as active`}
      />
    </>
  );
}

export default Runaway;
