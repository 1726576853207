import { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Spin } from 'antd';
import { fetchSkills } from '../../../../../features/skills/skillSlice';


export default function SkillsForm({ userId }) {
	const dispatch = useDispatch();


	const { mySkills, skillLoading} = useSelector(
		(state) => state.skill
	);

	const usrObj = {
		usrId: userId,
		usrType: "PERSONAL"
	};

	async function handleFetchSkills() {
		await dispatch(fetchSkills(usrObj));
	}


	useEffect(() => {}, [mySkills]);

	useEffect(() => {
		handleFetchSkills();
	}, [userId]);

	return (
		<>
			<div className='mt-[4.5rem] w-full'>

					<div className='mt-[4.13rem]'>
						<p className='heading_heading_skills'>Chosen skills:</p>
						{skillLoading ? (
							<Spin className='spin-white' size='large' />
						) : (
							<div className='flex items-center flex-wrap mt-[1.5rem]'>
								{mySkills?.map((item) => {
									return (
										<>
											<div className='bg-[#EBE9FE] rounded-[63px] text-center p-[0.75rem] mr-[1.5rem] flex items-center justify-between mb-3'>
												<span className='heading_heading_5 !text-[#1B47B4]'>
													{item?.skillName}
												</span>
											</div>
										</>
									);
								})}
							</div>
						)}
					</div>
			</div>
		</>
	);
}
