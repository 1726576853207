import { Form } from "antd";
import React, { useEffect, useRef, useState } from "react"; 
import { useDispatch, useSelector } from "react-redux";

function JobDetailsView() {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const { singlePlacement } =
    useSelector((state) => state.employee);
 

  return (
    <>
      <div className="mt-[38px]">
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="grid grid-cols-2">
            <div className="grid grid-cols-2">
              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Highest Education Level
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singlePlacement?.educationLevel ?? "N/A"}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Highest Training Award
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singlePlacement?.achName ??"N/A" }
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Specific Skill Area
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singlePlacement?.achName ?? "N/A"}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Job Title
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singlePlacement?.jobTitle ?? "N/A"}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Name of Employer
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singlePlacement?.employerName ?? "N/A"}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Employer Address
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singlePlacement?.employerAddress ?? "N/A"}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Employer Telephone
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singlePlacement?.emplEmployerPhone ?? "N/A"}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default JobDetailsView;
