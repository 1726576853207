import { Button, Form, Input, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function JobDetailsView() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setdata] = useState({});
  const onFinish = async (data) => {};

  const { user } = useSelector((state) => state.auth);

  const {singleJob,singleCreateJob,jobEditId, refetch,refetchKey } = useSelector(
    (state) => state.jobs
  );
  
 

  return (
    <div className="mt-[38px]">
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
        }}
        form={form}
      >
        <div className="grid grid-cols-2">
          <div className="grid grid-cols-2">
            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Position Name 
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobPosition}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              No. of vacancies
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobVacancyCount}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Occupation
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobOccupation}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Country
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobCountry}
            </div> 
          </div>

          <div className="grid grid-cols-2">
            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Area of Study
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobStudyArea}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Qualification Required
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobQualification}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Location
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobCity}
            </div>

            
          </div>
        </div>
      </Form>
    </div>
  );
}

export default JobDetailsView;
