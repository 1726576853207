import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  jobData: [],
  jobByStatusData: [],
  singleCreateJob:{},
  jobLoading: false,
  jobLoadingLetter:false,
  viewJobDetails: false,
  editJobDetails: false,
  viewTermsEmployment: false,
  editTermsEmployment: false,
  viewJobDescription: false,
  editJobDescription: false,

  viewSkills: false,
  editSkills: false,

  jobEditId:'',
  skillsData: [],

  attachmentLoading: false, 
  eShot:'',
  viewModeTable: false,
  refetch:false,
  refetchKey:'',

  statCountLoading: false, 
  jobStatCount:{},

  jobCountRefetch:false,
  hideTableDuringJobRecommedationApplicant: false,

  jobDemandLetterData:[],
  cancelledJobs:[],

  refetchJobTables:false,
  refetchKeyJobTables:'',

  singleJob:{},
  jobsCount:0,
  recentJobs:[],
  archivedJobs:[],
  readvertisedJobs:[],
  jobMatchingData:[],
  recommedationCount:0,
};

export const saveJob = createAsyncThunk(
  "jobs/saveJob",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveJob.action`, data);
    return res.data;
  }
);

 
export const fetchJobs = createAsyncThunk(
  "jobs/fetchJobs",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobs.action?jobInstId=${data?.jobInstId}&jobCntId=${data?.jobCntId}`
    );
    return res.data?.data?.result;
  }
);


export const fetchJobsByUser = createAsyncThunk(
  "jobs/fetchJobsByUser",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobs.action?jobInstId=${data?.jobInstId}&jobCntId=${data?.jobCntId}&jobCreatedBy=${data?.jobCreatedBy}`
    );
    return res.data?.data?.result;
  }
);

export const fetchSkillCategorys = createAsyncThunk(
  "jobs/fetchSkillCategorys",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchSkillCategorys.action`
    );
    return res.data?.data?.result;
  }
);

export const saveAttachment = createAsyncThunk(
  "jobs/saveAttachment",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveAttachment.action`, data);
    return res.data;
  }
);

export const fetchAttachments = createAsyncThunk(
  "jobs/fetchAttachments",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchAttachments.action?attInstId=${data?.attInstId}&attType=${data?.attType}`
    );
    return res.data?.data?.result;
  }
);

export const fetchJobStatsCount = createAsyncThunk(
  "jobs/jobStatsCount",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/jobStatsCount.action?jobId=${data?.jobId}`
    );
    return res.data?.data;
  }
);

export const fetchJobsByDemandLetter = createAsyncThunk(
  "jobs/fetchJobsByDemandLetter",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobs.action?jobDmlId=${data?.jobDmlId}&jobStatus=${data?.jobStatus}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}`
    );
    return res.data?.data;
  }
);

export const fetchJobsByStatus = createAsyncThunk(
  "jobs/fetchJobsByStatus",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobs.action?jobInstId=${data?.jobInstId}&jobCntId=${data?.jobCntId}&jobStatus=${data?.jobStatus}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}`
    );
    return res.data?.data;
  }
);


export const fetchCancelledJobs = createAsyncThunk(
  "jobs/fetchCancelledJobs",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobs.action?jobInstId=${data?.jobInstId}&jobCntId=${data?.jobCntId}&jobAdvertStatus=${data?.jobAdvertStatus}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}`
    );
    return res.data?.data;
  }
);

export const fetchSingleJob = createAsyncThunk(
  "jobs/fetchSingleJob",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobs.action?jobId=${data?.jobId}`
    );
    return res.data?.data?.result;
  }
);

export const fetchRecentJobs = createAsyncThunk(
  "jobs/fetchRecentJobs",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobs.action?jobInstId=${data?.jobInstId}&jobCntId=${data?.jobCntId}&jobStatus=${data?.jobStatus}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}&recent=${data?.recent}`
    );
    return res.data?.data;
  }
);

export const fetchExpiredJobs = createAsyncThunk(
  "jobs/fetchExpiredJobs",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobs.action?jobInstId=${data?.jobInstId}&jobCntId=${data?.jobCntId}&jobStatus=${data?.jobStatus}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}&expDate=${data?.expDate}`
    );
    return res.data?.data;
  }
);

export const fetchReadvertisedJobs = createAsyncThunk(
  "jobs/fetchReadvertisedJobs",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobs.action?jobInstId=${data?.jobInstId}&jobCntId=${data?.jobCntId}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}&readvertised=${data?.readvertised}`
    );
    return res.data?.data;
  }
);

export const fetchJobMatching = createAsyncThunk(
	'jobSlice/fetchJobMatching',
	async (data) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchJobRecommendations.action?start=${
				data?.start ?? ''
			}&limit=${data?.limit ?? ''}&jbrJobId=${data?.jbrJobId}&jbrPercentageGreater=0.9`
		);
		return res.data?.data;
	}
);


export const saveReferal = createAsyncThunk(
  "jobs/saveReferal",
  async (data) => {
    const res = await axiosInstance.post(`${url}/emp/saveJobReferral.action`, data);
    return res.data;
  }
);


export const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },
    setViewJobDetails:(state,action)=>{
      state.viewJobDetails = action.payload;
    },
    setEditJobDetails:(state,action)=>{
      state.editJobDetails = action.payload;
    },
    setViewTermsEmployment:(state,action)=>{
      state.viewTermsEmployment = action.payload;
    },
    setEditTermsEmployment:(state,action)=>{
      state.editTermsEmployment = action.payload;
    },
    setJobEditId:(state,action)=>{
      state.jobEditId = action.payload;
    },
    setHideTableDuringJobRecommedationApplicant:(state,action)=>{
      state.hideTableDuringJobRecommedationApplicant = action.payload;
    },
    

    setViewJobDescription:(state,action)=>{
      state.viewJobDescription = action.payload;
    },
    setEditJobDescription:(state,action)=>{
      state.editJobDescription = action.payload;
    },
    setViewModeTable:(state,action)=>{
      state.viewModeTable = action.payload;
    },
    setRefetch:(state)=>{
      state.refetch = !state.refetch;
    },
    setViewSkills:(state,action)=>{
      state.viewSkills = action.payload;
    },
    setEditSkills:(state,action)=>{
      state.editSkills = action.payload;
    },
    setJobCountRefetch:(state,action)=>{
      state.jobCountRefetch = !state.jobCountRefetch;
    },
    setRefetchKey: (state, action) => {
      state.refetchKey = action.payload;
    },
    setSingleCreateJob: (state, action) => {
      state.singleCreateJob = action.payload;
    },

    setRefetchKeyJobTables: (state, action) => {
      state.refetchKeyJobTables = action.payload;
    },
    setRefetchJobTables:(state)=>{
      state.refetchJobTables = !state.refetchJobTables;
    },
    
    
    
    cleanupJob:(state)=>{
      state.viewJobDetails =  initialState.viewJobDetails
      state.viewTermsEmployment =  initialState.viewTermsEmployment
      state.editJobDetails =  initialState.editJobDetails
      state.editTermsEmployment =  initialState.editTermsEmployment 
      state.viewJobDescription =  initialState.viewJobDescription
      state.editJobDescription =  initialState.editJobDescription
      state.viewModeTable =  initialState.viewModeTable
      state.singleCreateJob = initialState.singleCreateJob
      state.viewSkills = initialState.viewSkills
      state.editSkills = initialState.editSkills
      state.jobCountRefetch = initialState.jobCountRefetch
      state.refetchKey = initialState.refetchKey;
      state.refetchKeyJobTables = initialState.refetchKeyJobTables;
      
    },
    cleanUpJobEditId:(state)=>{
      state.jobEditId = initialState.jobEditId
    },

    cleanUpHideTableDuringJobRecommedationApplicant:(state)=>{
      state.hideTableDuringJobRecommedationApplicant = initialState.hideTableDuringJobRecommedationApplicant
    }
    
 
    
  },
  extraReducers: (builder) => {
    builder

      .addCase(saveJob.pending, (state) => {
        state.jobLoading = true;
      }) 
      .addCase(saveJob.fulfilled, (state, action) => {
        state.jobLoading = false; 
        state.singleCreateJob = action.payload?.jsonData;
      })
      .addCase(saveJob.rejected, (state,action) => {
        state.jobLoading = false;
        state.singleCreateJob = {};
      })

      .addCase(fetchJobs.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.jobLoading = false;
        state.jobData = action.payload;
      })
      .addCase(fetchJobs.rejected, (state) => {
        state.jobLoading = false;
        state.jobData =  []
      })

      .addCase(fetchJobsByUser.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchJobsByUser.fulfilled, (state, action) => {
        state.jobLoading = false;
        state.jobData = action.payload;
      })
      .addCase(fetchJobsByUser.rejected, (state) => {
        state.jobLoading = false;
        state.jobData =  []
      })

      

      .addCase(fetchSkillCategorys.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchSkillCategorys.fulfilled, (state, action) => {
        state.jobLoading = false;
        state.skillsData = action.payload;
      })
      .addCase(fetchSkillCategorys.rejected, (state) => {
        state.jobLoading = false;
        state.skillsData = {};
      })


      .addCase(saveAttachment.pending, (state) => {
        state.attachmentLoading = true;
      }) 
      .addCase(saveAttachment.fulfilled, (state, action) => {
        state.attachmentLoading = false;  
      })
      .addCase(saveAttachment.rejected, (state,action) => {
        state.attachmentLoading = false; 
      })

      .addCase(fetchAttachments.pending, (state) => {
        state.attachmentLoading = true;
      })
      .addCase(fetchAttachments.fulfilled, (state, action) => {
        state.attachmentLoading = false;
        state.eShot = action.payload[0];
      })
      .addCase(fetchAttachments.rejected, (state) => {
        state.attachmentLoading = false;
      })

      .addCase(fetchJobStatsCount.pending, (state) => {
        state.statCountLoading = true;
      })
      .addCase(fetchJobStatsCount.fulfilled, (state, action) => {
        state.statCountLoading = false;
        state.jobStatCount = action.payload;
      })
      .addCase(fetchJobStatsCount.rejected, (state) => {
        state.statCountLoading = false;
        state.jobStatCount=[]
      })

      .addCase(fetchJobsByDemandLetter.pending, (state) => {
        state.jobLoadingLetter = true;
      })
      .addCase(fetchJobsByDemandLetter.fulfilled, (state, action) => {
        state.jobLoadingLetter = false;
        state.jobDemandLetterData = action.payload?.result;
        state.jobsCount = action.payload?.count;
      })
      .addCase(fetchJobsByDemandLetter.rejected, (state) => {
        state.jobLoadingLetter = false;
        state.jobDemandLetterData=[];
        state.jobsCount=[]
      })

      .addCase(fetchJobsByStatus.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchJobsByStatus.fulfilled, (state, action) => {
        state.jobLoading = false;
        state.jobByStatusData = action?.payload?.result;
        state.jobsCount = action?.payload?.count
      })
      .addCase(fetchJobsByStatus.rejected, (state) => {
        state.jobLoading = false;
        state.jobByStatusData=[]
        state.jobsCount = 0;
      })

      .addCase(fetchCancelledJobs.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchCancelledJobs.fulfilled, (state, action) => {
        state.jobLoading = false;
        state.cancelledJobs = action.payload?.result;
        state.jobsCount = action?.payload?.count;
      })
      .addCase(fetchCancelledJobs.rejected, (state) => {
        state.jobLoading = false;
        state.cancelledJobs=[];
        state.jobsCount = 0;
      })

      .addCase(fetchSingleJob.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchSingleJob.fulfilled, (state, action) => {
        state.jobLoading = false;
        state.singleJob = action.payload?.[0];
      })
      .addCase(fetchSingleJob.rejected, (state) => {
        state.jobLoading = false;
        state.singleJob={}
      })


      .addCase(fetchRecentJobs.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchRecentJobs.fulfilled, (state, action) => {
        state.jobLoading = false;
        state.recentJobs = action?.payload?.result;
        state.jobsCount = action?.payload?.count
      })
      .addCase(fetchRecentJobs.rejected, (state) => {
        state.jobLoading = false;
        state.recentJobs=[]
        state.jobsCount = 0;
      })

      
      .addCase(fetchExpiredJobs.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchExpiredJobs.fulfilled, (state, action) => {
        state.jobLoading = false;
        state.archivedJobs = action?.payload?.result;
        state.jobsCount = action?.payload?.count
      })
      .addCase(fetchExpiredJobs.rejected, (state) => {
        state.jobLoading = false;
        state.archivedJobs=[]
        state.jobsCount = 0;
      })

      .addCase(fetchReadvertisedJobs.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchReadvertisedJobs.fulfilled, (state, action) => {
        state.jobLoading = false;
        state.readvertisedJobs = action?.payload?.result;
        state.jobsCount = action?.payload?.count
      })
      .addCase(fetchReadvertisedJobs.rejected, (state) => {
        state.jobLoading = false;
        state.readvertisedJobs=[]
        state.jobsCount = 0;
      })

      .addCase(fetchJobMatching.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchJobMatching.fulfilled, (state, action) => {
        state.jobLoading = false;
        state.jobMatchingData = action?.payload?.result; 
        state.recommedationCount = action?.payload?.count;
      })
      .addCase(fetchJobMatching.rejected, (state) => {
        state.jobLoading = false;
        state.jobMatchingData=[] 
        state.recommedationCount = 0
      })


      .addCase(saveReferal.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(saveReferal.fulfilled, (state, action) => {
        state.jobLoading = false; 
      })
      .addCase(saveReferal.rejected, (state) => {
        state.jobLoading = false; 
      })
      

      
      
  },
});

export default jobSlice.reducer;
export const {
  handleSidenavFullWidth, 
  setViewJobDetails,
  setEditJobDetails,
  setViewTermsEmployment,
  setEditTermsEmployment,
  setJobEditId,
  setViewJobDescription,
  setEditJobDescription,
  setViewModeTable,
  setRefetch,
  setViewSkills,
  setEditSkills,
  cleanupJob,
  cleanUpJobEditId,
  setJobCountRefetch,
  setRefetchKey,
  cleanUpHideTableDuringJobRecommedationApplicant,
  setHideTableDuringJobRecommedationApplicant,
  setSingleCreateJob,
  setRefetchKeyJobTables,
  setRefetchJobTables 
} = jobSlice.actions;
